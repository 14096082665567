import axios from "axios";
import { useNavigate } from "react-router-dom";
import config from "config";
import { toast } from "react-toastify";

function clear() {
  const nav = useNavigate();
  localStorage.clear();
  nav("/login");
}
function ErrorHandle(code, message, errorStatus) {
  switch (code) {
    case 400:
      return toast.error(message);
    case 410:
      return toast.error(errorStatus);
    case 401:
      return clear();
    case 403:
      return toast.error(
        `Bu foydalanuvchni ruhsati yoq bu apida   ${message}`
      );
    // case 404: return clear();
    // case 405: return clear();
    case 500:
      return toast.error(`Seraverda Xatolik   ${message}`);
  }
}
export const httpClient = axios.create({
  baseURL: config.baseUrl,
  // params: {},
  // paramsSerializer: (params) => stringify(params, { encodeValuesOnly: true })
});

httpClient.interceptors.request.use(
  (config) => {
    const isPublicApi = localStorage.getItem("token");
    if (isPublicApi) {
      config.headers["Authorization"] = `Bearer ${localStorage.getItem(
        "token"
      )}`;
    }
    return config;
  },

  (error) => {
    // console.log(error)
    return Promise.reject(error);
  }
);
httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const {
      response: { status, data },
      message,
    } = error;
    const errorStatus = error?.response?.data?.error?.message;
    ErrorHandle(status, message, errorStatus);
    return data;
  }
);

// request.defaults.params = {};
// request.defaults.params["_f"] = "json";
// request.defaults.headers.common["Accept"] = "application/json";
// request.defaults.headers.common["Cache-Control"] = "no-cache";
// request.defaults.headers.common["Content-Type"] = "application/json; charset=utf-8";
