import React, { useEffect, useState } from "react";

import { Typography, Button, DropDown } from "components";

import { ReactComponent as MessageIcon } from "assets/icons/message.svg";
import { ReactComponent as MessageWarningIcon } from "assets/icons/menu-message-warning.svg";
import MessageSuccesIcon from "assets/icons/succes-warning.png";
import MessageSuccesPlusIcon from "assets/icons/plus-icon.png";
import { ReactComponent as MessageDangerIcon } from "assets/icons/menu-message-danger.svg";
import { ReactComponent as NotificationIcon } from "assets/icons/notification.svg";
import { useTranslation } from "react-i18next";
import { useFetchList } from "../../hooks";
import { get } from "lodash";
import { useNavigate } from "react-router-dom";
import url from "../../assets/mp3/audio.mp3";
export const Messages = ({ notification }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  let filter = {};
  if (userData?.vsx) {
    filter = {
      vsx: {
        id: userData?.vsx?.id,
      },
    };
  }
  if (userData?.region) {
    filter = {
      ...filter,
      vsx: {
        region: {
          id: userData?.region?.id,
        },
      },
    };
  }
  const prisoner = useFetchList({
    url: "/prisoner/get-notifications",
    urlSearchParams: {
      sort: {
        updatedAt: "desc",
      },

      filters: {
        vsx: userData?.vsx?.id ?? undefined,
        region: userData?.region?.id ?? undefined,
      },
      // populate: 'person, room, search, roomLeave, roomLeave.reason,roomLeave.document,roomLeave.investigatingOrganization,roomLeave.helperOfficer,roomLeave.illnesses,basisDocument,basisDocumentPart, medExam'
    },
  });
  const navLink = useNavigate();
  const { t } = useTranslation();
  const [request, setRequest] = useState(0);
  const audio = () => {
    const aud = document.getElementById("audio");
    aud?.play();
  };
  useEffect(() => {
    const interval = setInterval(() => {
      prisoner.refetch();
    }, 300000);
    return () => clearInterval(interval);
  }, []);
  return (
    <>
      {(Boolean(get(prisoner, "data.data.total")) || false) && (
        <audio id="audio" src={url} autoPlay={true} played></audio>
      )}
      <DropDown
        outerClass="mr_20"
        innerClass="message__menu"
        offset="20px"
        hasPointer={true}
        sentd={request}
        renderTrigger={(handleMenuToggle) => (
          <>
            <Button design="circled" onClick={handleMenuToggle}>
              {notification ? <NotificationIcon /> : <MessageIcon />}
              <span className="badge header__badge">
                {get(prisoner, "data.data.total")}
              </span>
            </Button>
          </>
        )}>
        <div className="menu-message__inner">
          <div className="menu-message__heading">
            <Typography
              className="menu-message__title"
              text={t("message-title")}
            />
          </div>

          <div
            className="menu-message__item relative"
            onClick={() => navLink("/specialnotice-list")}>
            <div
              className="menu-message__icon"
              style={{ background: "var(--light-yellow)" }}>
              <MessageWarningIcon />
            </div>

            <div className="relative">
              <Typography
                Type="h6"
                className="menu-message__name"
                text={t("emergency")}
              />
            </div>
            <span
              className="badge header__badge"
              style={{ top: "10px", right: "100px" }}>
              {get(prisoner, "data.data.emergencySituation") ?? 0}
            </span>
          </div>

          <div
            className="menu-message__item relative"
            onClick={() => navLink(`/camera-insede/${9}/Tergov harakatlariga`)}>
            <div
              className="menu-message__icon"
              style={{ background: "var(--light-red)" }}>
              <MessageDangerIcon />
            </div>

            <div className="relative">
              <Typography
                Type="h5"
                className="menu-message__name"
                text={t("inquest-out-employees-count")}
              />
            </div>
            <span
              className="badge header__badge"
              style={{ top: "-10x", right: "15px" }}>
              {get(prisoner, "data.data.investigation") ?? 0}
            </span>
          </div>
          <div
            className="menu-message__item relative"
            onClick={() => navLink(`/camera-insedes/Hujjati-yuklatilmaganlar`)}>
            <div
              className="menu-message__icon"
              style={{ background: "var(--light-red)" }}>
              <img src={MessageSuccesIcon} alt="" style={{ width: 25 }} />
            </div>

            <div className="relative">
              <Typography
                Type="h5"
                className="menu-message__name"
                text="Hujjati to'liq yuklatilmaganlar"
              />
            </div>
            <span
              className="badge header__badge"
              style={{ top: "-10x", right: "15px" }}>
              {get(prisoner, "data.data.uncompletedPrisoners") ?? 0}
            </span>
          </div>
          <div
            className="menu-message__item relative"
            onClick={() => navLink(`/camera-insede/Maxsus-palata`)}>
            <div
              className="menu-message__icon"
              style={{ background: "var(--light-red)" }}>
              <img src={MessageSuccesPlusIcon} alt="" style={{ width: 25 }} />
            </div>
            <div className="relative">
              <Typography
                Type="h5"
                className="menu-message__name"
                text="Maxsus palatada"
              />
            </div>
            <span
              className="badge header__badge"
              style={{ top: "-10x", right: "15px" }}>
              {get(prisoner, "data.data.inSpecialRoom") ?? 0}
            </span>
          </div>
        </div>
      </DropDown>
    </>
  );
};
