export const months = [
	"Декабрь",
	"Январь",
	"Февраль",
	"Март",
	"Апрель",
	"Май",
	"Июнь",
	"Июль",
	"Август",
	"Сентабрь",
	"Октябрь",
	"Ноябрь",
];

export const weeks = ["Пон", "Вто", "Ср", "Чет", "Пят", "Суб", "Вос"];

export const locale = {
	name: "default_locale",
	months: [
		["Январь", "Янв"],
		["Февраль", "Фев"],
		["Март", "Мар"],
		["Апрель", "Апр"],
		["Май", "Май"],
		["Июнь", "Июн"],
		["Июль", "Июл"],
		["Август", "Авг"],
		["Сентабрь", "Сен"],
		["Октябрь", "Окт"],
		["Ноябрь", "Ноя"],
		["Декабрь", "Дек"],
	],
	weekDays: [
		["Суббота", "Суб"],
		["Воскресенье", "Вос"],
		["Понедельник", "Пон"],
		["Вторник", "Вто"],
		["Среда", "Ср"],
		["Четверг", "Чет"],
		["Пятница", "Пят"],
	],
	digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
	meridiems: [
		["AM", "am"],
		["PM", "pm"],
	],
};
