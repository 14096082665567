import React from "react";
import { Avatar, Pagination, Table } from "../../../components";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useFetchList } from "../../../hooks";
import { useParams } from "react-router-dom";

export const PrisonerVisitHistory = (props) => {
  const { t } = useTranslation();
  const { region, id } = useParams();
  const visitor = useFetchList({
    url: `/visits`,
    urlSearchParams: {
      filters: {
        prisoner: {
          id,
        },
      },
      populate:
        "responsibleOfficer,visitor,visitor.image,vsx,prisoner,prisoner.person,prisoner.room",
    },
  });
  return (
    <div>
      <>
        <Table
          emptyUiText={t("list-emptey")}
          
          isLoading={visitor.isLoading}
          columns={[
            {
              title: t("number"),
              dataKey: "id",
              render: (value, item, index) => {
                return index + 1;
              },
            },
            {
              title: t("photo"),
              dataKey: "attributes",
              className: "white-space_no-wrap",
              render: (value, item) => {
                return <Avatar src={item?.visitor?.image} />;
              },
              // time?.timeFormater(item?.attributes?.createdAt, "DD.MM.YYYY"),
            },
            {
              title: t("visitor-person"),
              className: "white-space_no-wrap",
              dataKey: "attributes.sureName",
              render: (value, item) => {
                if (item.visitor) {
                  return (
                    <>
                      {item?.visitor?.firstName} {"  "}{" "}
                      {item?.visitor?.sureName}
                    </>
                  );
                } else return "--";
              },
            },

            {
              title: t("passport"),
              dataKey: "currency",
              render: (value, items) => items?.visitor?.passport,
            },
            {
              title: t("visit-time"),
              dataKey: "patient",
              className: "white-space_no-wrap",
              render: (value, item) => {
                if (item.date)
                  return dayjs(item?.date).format("YYYY-MM-DD HH:mm");
                return "--";
              },
            },
            //
            // {
            //   title: "Комментарий",
            //   dataKey: "comment",
            //   render: (value) => value,
            // },
            {
              title: t("products-give"),
              dataKey: "user",
              render: (value, item) => {
                if (item?.items?.length) {
                  return (
                    <>
                      {item?.items?.map((el) => {
                        return (
                          <div>
                            <span
                              style={{ color: "red", marginRight: 10 + "px" }}>
                              {el.key}
                            </span>
                            <span>{el.value}</span>
                          </div>
                        );
                      })}
                    </>
                  );
                }
              },
            },
          ]}
          items={visitor?.data?.length ? visitor?.data : []}
        />
        <Pagination
          currentPage={visitor.meta?.pagination?.page}
          pageCount={visitor.meta?.pagination?.pageCount}
          onPageChange={(newPage) => setPage(newPage + 1)}
        />
      </>
    </div>
  );
};
