import React from "react";

function Sun() {
  return (
    <svg
      version="1.1"
      class="sun"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 496 496">
      <rect
        x="152.994"
        y="58.921"
        transform="matrix(0.3827 0.9239 -0.9239 0.3827 168.6176 -118.5145)"
        width="40.001"
        height="16"
      />
      <rect
        x="46.9"
        y="164.979"
        transform="matrix(0.9239 0.3827 -0.3827 0.9239 71.29 -12.4346)"
        width="40.001"
        height="16"
      />
      <rect
        x="46.947"
        y="315.048"
        transform="matrix(0.9239 -0.3827 0.3827 0.9239 -118.531 50.2116)"
        width="40.001"
        height="16"
      />

      <rect
        x="164.966"
        y="409.112"
        transform="matrix(-0.9238 -0.3828 0.3828 -0.9238 168.4872 891.7491)"
        width="16"
        height="39.999"
      />

      <rect
        x="303.031"
        y="421.036"
        transform="matrix(-0.3827 -0.9239 0.9239 -0.3827 50.2758 891.6655)"
        width="40.001"
        height="16"
      />

      <rect
        x="409.088"
        y="315.018"
        transform="matrix(-0.9239 -0.3827 0.3827 -0.9239 701.898 785.6559)"
        width="40.001"
        height="16"
      />

      <rect
        x="409.054"
        y="165.011"
        transform="matrix(-0.9239 0.3827 -0.3827 -0.9239 891.6585 168.6574)"
        width="40.001"
        height="16"
      />
      <rect
        x="315.001"
        y="46.895"
        transform="matrix(0.9238 0.3828 -0.3828 0.9238 50.212 -118.5529)"
        width="16"
        height="39.999"
      />
      <path
        d="M248,88c-88.224,0-160,71.776-160,160s71.776,160,160,160s160-71.776,160-160S336.224,88,248,88z M248,392
               c-79.4,0-144-64.6-144-144s64.6-144,144-144s144,64.6,144,144S327.4,392,248,392z"
      />
      <rect x="240" width="16" height="72" />
      <rect
        x="62.097"
        y="90.096"
        transform="matrix(0.7071 0.7071 -0.7071 0.7071 98.0963 -40.6334)"
        width="71.999"
        height="16"
      />
      <rect y="240" width="72" height="16" />

      <rect
        x="90.091"
        y="361.915"
        transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 -113.9157 748.643)"
        width="16"
        height="71.999"
      />
      <rect x="240" y="424" width="16" height="72" />

      <rect
        x="361.881"
        y="389.915"
        transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 397.8562 960.6281)"
        width="71.999"
        height="16"
      />
      <rect x="424" y="240" width="72" height="16" />
      <rect
        x="389.911"
        y="62.091"
        transform="matrix(0.7071 0.7071 -0.7071 0.7071 185.9067 -252.6357)"
        width="16"
        height="71.999"
      />
    </svg>
  );
}

export default Sun;
