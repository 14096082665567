import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import uz from "../../locales/uz.json";
import cr from "../../locales/cr.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "cr",
    debug: true,
    ns: ["translations"],
    interpolation: {
      escapeValue: false,
    },
    resources: {
      uz: {
        translations: uz,
      },
      cr: {
        translations: cr,
      },
    },
  });

export default i18n;
