import React, {useState} from 'react';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw,convertFromRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
export const  Richtext = (props) => {
    const content = {entityMap:{},blocks:[{key:"637gr",text:"Initialized from content state.",type:"unstyled",depth:0,inlineStyleRanges:[],entityRanges:[],data:{}}]};
    const state = convertFromRaw(content)
    const [editorState, setEditorState] = useState()
    const handleState = (even) => {
        console.log(even, convertToRaw(even.getCurrentContent()))
        // setEditorState(even)
    }
    return (
        <div>
            <Editor
                // editorState={state}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={handleState}
            />
        </div>
    );
}
