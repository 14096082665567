import React, { useEffect, useRef, useState } from "react";
import { Avatar, Pagination, Table } from "../../../components";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Span } from "./Span";
import { useFetchList, useOverlay } from "../../../hooks";
import { useParams } from "react-router-dom";
import UpdateItemModal from "./UpdateItemModal";
import AppModal from "../sub-pages/AppModal";
import config from "config";
import jsPDF from "jspdf";
import { httpClient } from "services";
import html2pdf from "html2pdf.js";

export const UserMedInfo = (props) => {
  const [prisonderId, setPrisonerId] = useState("");
  const [htmlContent, setHtmlContent] = useState("");
  const { t } = useTranslation();
  const addItem = useOverlay({ uniqueName: "addRooms" });
  const { id } = useParams();
  const visitor = useFetchList({
    url: `/searches`,
    urlSearchParams: {
      filters: {
        prisoner: id,
      },
      populate:
        "prisoner, prisoner.person,prisoner.prohibitedItems, prohibitedItems.prohibitedItem",
    },
  });

  const fetchList = async () => {
    try {
      const response = await httpClient.post(
        `/prisoner/generate-statement-html/${prisonderId}`
      );
      const receivedHtmlContent = response?.data?.data?.html;
      html2pdf().from(receivedHtmlContent).save("bayonnoma.pdf");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="col-12 col-md-12 col-sm-12 col-xl-12">
      <>
        <Table
          emptyUiText={t("list-emptey")}
          isLoading={visitor.isLoading}
          className="table-prisoner-event-history"
          columns={[
            {
              title: t("number"),
              dataKey: "id",
              render: (value, item, index) => {
                return index + 1;
              },
            },
            {
              title: "Tintuv qilgan shaxs",
              className: "white-space_no-wrap",
              dataKey: "attributes.sureName",
              render: (value, item) => {
                return (
                  <span>
                    {item?.prisoner?.person?.sureName} <br />{" "}
                    {item?.prisoner?.person?.firstName}
                  </span>
                );
              },
            },
            {
              title: "Tintuv vaqti",
              dataKey: "patient",
              className: "white-space_no-wrap",
              render: (value, item) => {
                if (item.searchDate)
                  return dayjs(item?.searchDate).format("YYYY-MM-DD HH:mm");
                return "--";
              },
            },
            {
              title: "Aniqlangan buyumlar",
              dataKey: "user",
              render: (value, item) => {
                if (item?.items?.length) {
                  return (
                    <>
                      {item?.items?.map((el) => {
                        return (
                          <div>
                            <span
                              style={{ color: "red", marginRight: 10 + "px" }}>
                              {el.key}
                            </span>
                            <span>{el.value}</span>
                          </div>
                        );
                      })}
                    </>
                  );
                }
                return "--";
              },
            },
            {
              title: "Bayonnoma",
              className: "white-space_no-wrap",
              render: (value, item) => {
                setPrisonerId(item.id);
                return (
                  <button
                    onClick={fetchList}
                    type="submit"
                    style={{ color: "#fff" }}
                    className="btn download_bayonnoma">
                    {t("dowloand")}
                  </button>
                );
              },
            },
            {
              title: "Qaror",
              className: "white-space_no-wrap",
              render: (value, item) => {
                return (
                  <button
                    style={{ color: "#fff" }}
                    className="btn download_bayonnoma">
                   {t("dowloand")}
                  </button>
                );
              },
            },

            {
              title: "Ilova",
              className: "white-space_no-wrap",
              render: (value, item) => {
                return (
                  <>
                    <button
                      onClick={() => {
                        addItem.handleOverlayOpen();
                      }}
                      style={{ color: "#fff" }}
                      className="btn download_bayonnoma">
                      Yuklash
                    </button>
                    <AppModal
                      isOpen={addItem.isOverlayOpen}
                      handleModalClose={addItem.handleOverlayClose}
                    />
                  </>
                );
              },
            },
          ]}
          items={visitor?.data?.length ? visitor?.data : []}
        />
        <Pagination
          currentPage={visitor.meta?.pagination?.page}
          pageCount={visitor.meta?.pagination?.pageCount}
          onPageChange={(newPage) => setPage(newPage + 1)}
        />
      </>
    </div>
  );
};
