import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Containers from "../../../../containers";
import { get } from "lodash";
import { FastField } from "formik";
import { AttachFile, Button, Fields, Info } from "../../../../components";
import dayjs from "dayjs";
const FormInquest = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [workers, setWorkers] = useState([]);
  const { values, customData, method, title } = props;
  const { t } = useTranslation();
  const userData = JSON.parse(localStorage.getItem("userData"));

  const filters = {};
  if (userData.region) {
    filters["region"] = userData.region.id;
  }
  if (userData.vsx) {
    filters["vsx"] = userData.vsx.id;
  }
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter" && inputValue !== "") {
      e?.preventDefault();
      const existingWorkerIndex = workers.findIndex(
        (worker) => worker.name === inputValue
      );

      if (existingWorkerIndex !== -1) {
        setWorkers((prevWorkers) =>
          prevWorkers.map((worker, index) =>
            index === existingWorkerIndex
              ? { ...worker, isChecked: !worker?.isChecked }
              : worker
          )
        );
      } else {
        setWorkers((prevWorkers) => [
          ...prevWorkers,
          { name: inputValue, isChecked: false },
        ]);
      }

      // Clear input value
      setInputValue(null);
    }
  };

  const handleButtonClick = () => {
    const syntheticEvent = { key: "Enter", preventDefault: () => {} };

    handleInputKeyPress(syntheticEvent);
  };

  return (
    <div>
      <Containers.Form
        customData={customData}
        validate={(event) => {
          //   console.log(event);
        }}
        method={"post"}
        url={"/prisoner/leave-room"}
        onSuccess={({ data }) => {
          method();
        }}
        onError={() => {
          method();
        }}
        fields={[
          {
            name: "time",

            onSubmitValue: (value) => dayjs(dayjs()).format("YYYY-MM-DD HH:mm"),
          },
          {
            name: "meetingType",
            // validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "nationality") ?? "",
            onSubmitValue: (value) => value.value,
          },
          {
            name: "responsibleOfficer",
            value: get(values, "responsibleOfficer") ?? "",
            onSubmitValue: (value) => {
              return userData.id;
            },
          },

          {
            name: "delegates",
            validations: [{ type: "required" }],
            value: get(values, "delegates") ?? "",
            onSubmitValue: (value) => workers,
          },
          {
            name: "injures",
            value: get(values, "injures"),
            onSubmitValue: (value) => value,
          },
          {
            name: "document",
            value: get(values, "worker") ?? "",
            onSubmitValue: (value) => {
              if (!value) return null;
              return value;
            },
          },
        ]}>
        {({ isSubmitting, values, setFieldValue, ...rest }) => {
          return (
            <>
              <div className="row g-4">
                {/*birthdate*/}
                <div className="col-12">
                  <Info
                    label={t("inquest-out-employees")}
                    data={userData?.firstName + " " + userData?.sureName}
                  />
                </div>

                <div className="col-12">
                  <div className="d-flex align-items-center">
                    <div className="mb_20" style={{ width: "100%" }}>
                      <FastField
                        name="delegates"
                        component={Fields.InputText}
                        hasTimeSelect
                        style={{ width: "100%" }}
                        value={inputValue}
                        onKeyPress={handleInputKeyPress}
                        label={t("inquest-employees")}
                        onChange={handleInputChange}
                      />
                    </div>
                    <span
                      onClick={handleButtonClick}
                      style={{
                        fontSize: "18px",
                        background: "var(--stroke-input)",
                        padding: "3px 10px",
                        color: "#fff",
                        cursor: "pointer",
                      }}>
                      +
                    </span>
                  </div>
                  <ul>
                    {workers.length > 0
                      ? workers &&
                        workers?.map((worker, index) => (
                          <li
                            className="d-flex align-items-center advokat-menu-list"
                            key={index}>
                            <input
                              type="checkbox"
                              className="advokat_input-checkbox"
                              checked={worker.isChecked}
                              onChange={() => {
                                setWorkers((prevWorkers) =>
                                  prevWorkers.map((prevWorker, prevIndex) =>
                                    prevIndex === index
                                      ? {
                                          ...prevWorker,
                                          isChecked: !prevWorker.isChecked,
                                        }
                                      : prevWorker
                                  )
                                );
                              }}
                            />
                            <h2 className="search_label-name">{worker.name}</h2>
                          </li>
                        ))
                      : null}
                  </ul>
                </div>

                <div className="col-12">
                  <FastField
                    name="meetingType"
                    component={Fields.AsyncSelect}
                    loadOptionsUrl={"/prisoner-meeting-types"}
                    loadOptionsKey={(data) =>
                      data?.data?.map((el) => ({
                        label: el.name,
                        value: el.id,
                      }))
                    }
                    label={t("see-get-user")}
                    placeholder={t("select")}
                  />
                </div>
                <div className="col-12">
                  <FastField
                    name="injures"
                    style={{ height: 100 + "px" }}
                    component={Fields.Textarea}
                    hasTimeSelect
                    label={t("see-get-meeting")}
                  />
                </div>

                <div className="col-12">
                  <h2 className="search_label">{t("inquest-epistle")}</h2>
                  <div className="file-upload">
                    <FastField
                      name="document"
                      title={t("file")}
                      component={AttachFile}
                      label={t("minutes")}
                    />
                  </div>
                </div>
              </div>
              {/*  </div>*/}
              {/*</div>*/}
              <div className="d-flex justify-content-end">
                <Button
                  design="primary"
                  type="submit"
                  className="modal-btn-end fz_16 btn mt_40 w_full"
                  text={title.name}
                  isLoading={isSubmitting}
                />
              </div>
            </>
          );
        }}
      </Containers.Form>
    </div>
  );
};

export default FormInquest;
