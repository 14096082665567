import React from "react";
import Containers from "../../../../containers";
import { get } from "lodash";
import { time } from "../../../../services";
import { ErrorMessage, FastField, Field, FieldArray } from "formik";
import { AttachFile, Button, Fields } from "../../../../components";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../../Prisoners/styles/tintuv.scss";
import { ReactComponent as IconDelete } from "../../../../assets/icons/delete.svg";
const FieldArrays = ({ name, products, t }) => {
  return (
    <FieldArray name={name}>
      {({ insert, remove, push }) => (
        <>
          {products.map((product, index, arr) => (
            <div className="row" key={index}>
              <div
                className="col-12 col-md-12 col-sm-12 mt_20 d-flex align-items-center "
                key={index}>
                <div className="col-4 w_full">
                  <Field
                    name={`itemsStr.${index}.amount`}
                    component={Fields.InputText}
                    // placeholder={}
                  />
                </div>
              </div>
              <ErrorMessage
                name={`itemsStr.${index}.amount`}
                component="span"
                className="field-error"
              />{" "}
              <br />
              <div className="ml_10 mt_10">
                {index + 1 === arr.length && index !== 0 && (
                  <Button
                    className="color_primary-red product__btn"
                    onClick={() => remove(index)}>
                    <IconDelete />
                  </Button>
                )}
              </div>
              {/*</div>*/}
              {/*</div>*/}
            </div>
          ))}
          <Button
            className="w_30 mt_20 "
            style={{ padding: 0, borderRadius: 5 + "px" }}
            design={"primary"}
            onClick={() => push({ key: [], amount: "" })}>
            +
          </Button>
        </>
      )}
    </FieldArray>
  );
};
function FormRoomReturn(props) {
  const Info = ({ icon, data, label }) => {
    return (
      <div className="search">
        <h2 className="search_label">{label}</h2>
        <div className="search_data">
          <span>{data}</span>{" "}
          <span>
            {icon && (
              <svg
                width="15"
                height="15"
                viewBox="0 0 18 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16 2.3027H15V1.31029C15 0.764459 14.55 0.317871 14 0.317871C13.45 0.317871 13 0.764459 13 1.31029V2.3027H5V1.31029C5 0.764459 4.55 0.317871 4 0.317871C3.45 0.317871 3 0.764459 3 1.31029V2.3027H2C0.89 2.3027 0.00999999 3.19588 0.00999999 4.28754L0 18.1814C0 19.273 0.89 20.1662 2 20.1662H16C17.1 20.1662 18 19.273 18 18.1814V4.28754C18 3.19588 17.1 2.3027 16 2.3027ZM16 17.189C16 17.7348 15.55 18.1814 15 18.1814H3C2.45 18.1814 2 17.7348 2 17.189V7.26479H16V17.189ZM4 9.24962H6V11.2345H4V9.24962ZM8 9.24962H10V11.2345H8V9.24962ZM12 9.24962H14V11.2345H12V9.24962Z"
                  fill="#414141"
                />
              </svg>
            )}
          </span>
        </div>
      </div>
    );
  };
  const navLink = useNavigate();
  const { values, customData, method, title, isTitle } = props;
  const { t } = useTranslation();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const filters = {};
  if (userData.region) {
    filters["region"] = userData.region.id;
  }
  if (userData.vsx) {
    filters["vsx"] = userData.vsx.id;
  }
  return (
    <div>
      <Containers.Form
        customData={customData}
        validate={(event) => {
          //   console.log(event);
        }}
        method={"post"}
        url={"/prisoner/leave-room"}
        onSuccess={({ data }) => {
          method();
        }}
        onError={() => {
          method();
        }}
        fields={[
          {
            name: "time",
            // validations: [{ type: "required" }],
            value: get(values, "time"),
            onSubmitValue: (value) => dayjs().format("YYYY-MM-DD HH:mm"),
          },
          {
            name: "responsibleOfficer",
            // validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "nationality") ?? "",
            onSubmitValue: (value) => userData.id,
          },
          {
            name: "injuresFile",
            value: get(values, "injures"),
            onSubmitValue: (value) => {
              if (value) return value;
              return null;
            },
          },
          {
            name: "itemsStr",
            value: get(values, "itemsStr"),
            onSubmitValue: (value) => {
              return value;
            },
          },
          {
            name: "injures",
            value: get(values, "injures"),
            onSubmitValue: (value) => value,
          },

          {
            name: "isInjured",
            // validations: [{type: "required"}],
            value: `${get(values, "isLGBT")}` ?? false,
            onSubmitValue: (value) => {
              // console.log(value)
              const _item = value === "Ha" ? true : false;
              return _item;
            },
          },
          {
            name: "hasItems",
            // validations: [{type: "required"}],
            value: `${get(values, "hasItems")}` ?? false,
            onSubmitValue: (value) => {
              // console.log(value)
              const _item = value === "Ha" ? true : false;
              return _item;
            },
          },
          {
            name: "hasProhibitedItems",
            // validations: [{type: "required"}],
            value: `${get(values, "hasItems")}` ?? false,
            onSubmitValue: (value) => {
              // console.log(value)
              const _item = value === "Ha" ? true : false;
              return _item;
            },
          },
        ]}>
        {({ isSubmitting, values, setFieldValue, ...rest }) => {
          return (
            <>
              <div className="row g-4">
                <Info
                  label={t("get-emp")}
                  data={userData?.firstName + " " + userData?.sureName}
                />
                <div className="col-12">
                  <p
                    className="mb_20 control__label "
                    style={{ fontSize: 14 + "px" }}>
                    {t("Jarohatlar mavjud")}
                  </p>
                  <div className="d-flex">
                    <span>
                      <FastField
                        name="isInjured"
                        component={Fields.RadioButton}
                        label={t("yes")}
                        value={t("yes")}
                      />
                    </span>
                    <span className="ml_10">
                      <FastField
                        name="isInjured"
                        component={Fields.RadioButton}
                        label={t("no")}
                        value={t("no")}
                      />
                    </span>
                  </div>
                </div>
                {values.isInjured === "Ha" && (
                  <div className="col-12">
                    <FastField
                      name="injures"
                      component={Fields.InputText}
                      label={t("injuries-has")}
                    />
                  </div>
                )}
                {values.isInjured === "Ha" && (
                  <div className="col-12">
                    <h2 className="search_label">{t("file")}</h2>
                    <div className="file-upload">
                      <FastField
                        name="injuresFile"
                        title={t("file")}
                        component={AttachFile}
                        label={t("minutes")}
                      />
                    </div>
                  </div>
                )}
                <div className="col-12">
                  <p
                    className="mb_20 control__label "
                    style={{ fontSize: 14 + "px" }}>
                    {t("Taqiqlangan buyumlar mavjud")}
                  </p>
                  <div className="d-flex">
                    <span>
                      <FastField
                        name="hasProhibitedItems"
                        component={Fields.RadioButton}
                        label={t("yes")}
                        value={t("yes")}
                      />
                    </span>
                    <span className="ml_10">
                      <FastField
                        name="hasProhibitedItems"
                        component={Fields.RadioButton}
                        label={t("no")}
                        value={t("no")}
                      />
                    </span>
                  </div>
                </div>

                {values.hasProhibitedItems === "Ha" && (
                  <div className="col-12">
                    <FastField
                      name="itemsStr"
                      component={Fields.InputText}
                      // label={t("yes")}
                      // value={t("yes")}
                    />
                  </div>
                )}
              </div>
              {/*</div>*/}
              {/*</div>*/}
              <div className="d-flex justify-content-end">
                <Button
                  design="primary"
                  type="submit"
                  className="modal-btn-end fz_16 btn mt_40 w_full"
                  text={title.name}
                  isLoading={isSubmitting}
                />
              </div>
            </>
          );
        }}
      </Containers.Form>
    </div>
  );
}

export default FormRoomReturn;
