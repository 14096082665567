import React from "react";
import { get } from "lodash";
import Containers from "containers";
import { ModalDefault, Info } from "components";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export const PhscoDispanserModal = ({
  isOpen,
  handleOverlayClose,
  valuesUpdate,
  refetch,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  return (
    <ModalDefault
      isOpen={isOpen}
      handleModalClose={handleOverlayClose}
      title={t("phco-title")}
      innerClass="max-width_500">
      <Containers.Form
        method={"post"}
        url="/prisoner-punishments"
        onSuccess={() => {
          handleOverlayClose();
          refetch();
        }}
        fields={[
          {
            name: "responsibleOfficer",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(valuesUpdate, "nationality") ?? "",
            onSubmitValue: (value) => value.value,
          },
          {
            name: "type",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(valuesUpdate, "type") ?? "",
            onSubmitValue: (value) => value.value,
          },
          {
            name: "date",
            validations: [{ type: "required" }],
            // value:get(values, 'responsibleUser.data.id') ?  {label: get(values, 'responsibleUser.data.attributes.username'),value:get(values, 'responsibleUser.data.id')} : '',
            onSubmitValue: (value) => {
              return value;
            },
          },
        ]}>
        {({ isSubmitting, values, ...rest }) => {
          return (
            <>
              <div className="row g-4">
                <div className="col-12">
                  <Info label={t("q-add")} data={get(valuesUpdate, "status")} />
                </div>
                <div className="col-12">
                  <Info
                    label={t("q-docktor")}
                    data={get(valuesUpdate, "createdByDoctor.fullName")}
                  />
                </div>
                <div className="col-12">
                  <Info
                    label={t("q-change")}
                    data={get(valuesUpdate, "statusChangeData")}
                  />
                </div>{" "}
                <div className="col-12">
                  <Info
                    label={t("q-change-d")}
                    data={get(valuesUpdate, "updatedByDoctor.fullName")}
                  />
                </div>
                <div className="col-12">
                  {/*<h2 className='search_label'>{t('file')}</h2>*/}
                  {/*<div className='file-upload'>*/}
                  {/*    <FastField*/}
                  {/*        name="document"*/}
                  {/*        title={t('file')}*/}
                  {/*        component={AttachFile}*/}
                  {/*        label={t('minutes')}*/}
                  {/*    />*/}
                  {/*</div>*/}
                </div>
              </div>
            </>
          );
        }}
      </Containers.Form>
    </ModalDefault>
  );
};
