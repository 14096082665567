import React, { useState, useEffect } from "react";

export const CountdownTimer = ({ time }) => {
  const targetDate = time;
  const [timeRemaining, setTimeRemaining] = useState(time);
  useEffect(() => {
    const interval = setInterval(() => {
      const timeLeft = time;
      if (timeLeft <= 0) {
        clearInterval(interval);
        setTimeRemaining(0);
      } else {
        setTimeRemaining(timeLeft);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  // const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

  return (
    <div>
      <div>
        {hours}:{minutes}:{seconds}
      </div>
    </div>
  );
};
