import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useFetchList } from "../../../hooks";
import { InputSearch, Pagination, Table } from "../../../components";
import { time } from "../../../services";
import { Span } from "../../Visitors/components";
import { get } from "lodash";
import { useVsxId } from "context/VsxIdContext";
import { useRegionId } from "context/RegionIdContext";

const DashboardInfo = () => {
  const { t } = useTranslation();
  const navLink = useNavigate();
  const { name, populate } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const paginationData = searchParams.get("page");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const vsxId = sessionStorage.getItem("vsxId");
  const { regionId } = useRegionId();
  const { vsxId: vsxActiveId } = useVsxId();

  const filter = {};
  if (userData.region) {
    filter["region"] = userData.region.id;
  }
  if (userData.vsx) {
    filter["vsx"] = userData.vsx.id;
  }
  const [page, setPage] = useState({
    page: paginationData ?? paginationData + 1,
    pageSize: 10,
  });
  const [filters, setFilters] = useState({
    start: "",
    end: "",
    search: "",
    region_id: null,
  });

  const filterDashboard =
    userData?.role?.name === "Superadmin"
      ? { region: regionId, vsx: vsxActiveId }
      : { ...filter };

  const prisonerList = useFetchList({
    url: `/prisoner/get-dashboard-vsx-mi-each-metric`,
    urlSearchParams: {
      filters: {
        isLeftVsx: false,
        ...filterDashboard,
      },
      pagination: {
        ...page,
      },
      metric: `${populate}`,
      // populate: `${populate}, vsx`,
    },
  });

  return (
    <>
      <InputSearch
        setValue={setFilters}
        placeholder={t("search")}
        // text={t('emergency')}
        text={name}
      />
      <Table
        emptyUiText={t("list-emptey")}
        isLoading={prisonerList.isLoading}
        isColor={false}
        onRowClick={(row) => navLink(`/emergency-pdf/${row?.id}`)}
        columns={[
          {
            title: t("number"),
            dataKey: "id",
            render: (value, item, index) => {
              return page.page * page.pageSize + index + 1 - page.pageSize;
            },
          },
          //   {
          //     title: t("reester"),
          //     dataKey: "id",
          //     render: (value, item, index) => {
          //       if (item?.prisoner)
          //         return <span>{item?.prisoner?.reesterNumber ?? "--"}</span>;
          //       return "--";
          //     },
          //   },
          //   {
          //     title: t("room"),
          //     dataKey: "id",
          //     render: (value, item, index) => {
          //       if (item?.room?.name) return item?.room?.name;
          //       if (item?.prisoner?.room?.name) return item?.prisoner?.room?.name;
          //     },
          //   },
          {
            title: t("Sodir etilgan vaqti"),
            dataKey: "amount",
            className: "white-space_no-wrap",
            render: (value, items) => {
              if (items?.time)
                return time.timeFormater(items?.time, "YYYY-MM-DD");
            },
          },
          {
            title: t("VSX"),
            dataKey: "vsx",
            render: (value, item, index) => {
              if (item?.vsx) return item?.vsx?.name;
              else {
                return item?.vsx?.name;
              }
            },
          },
          {
            title: t("prisoner-time-name"),
            className: "white-space_no-wrap",
            // dataKey: "sureName",
            render: (value, item) => {
              if (item?.prisoner?.person) return Span(item?.prisoner?.person);
              return "";
            },
          },
          {
            title: t("birthdate"),
            dataKey: "amount",
            className: "white-space_no-wrap",
            render: (value, items) => {
              if (items?.prisoner?.person)
                return time.timeFormater(
                  items?.prisoner.person?.birthdate,
                  "YYYY-MM-DD"
                );
            },
          },
          {
            title: t("catch-time"),
            dataKey: "amount",
            className: "white-space_no-wrap",
            render: (value, items) => {
              if (items?.prisoner?.organizationArestTime)
                return time.timeFormater(
                  items?.prisoner?.organizationArestTime,
                  "YYYY-MM-DD HH:mm"
                );
            },
          },

          {
            title: t("ever-day"),
            dataKey: "currency",
            className: "white-space_no-wrap",
            render: (value, items) => {
              if (items?.prisoner?.arestTime)
                return time.timeFormater(
                  items?.prisoner?.arestTime,
                  "YYYY-MM-DD"
                );
            },
          },
          //   {
          //     title: t("main"),
          //     dataKey: "patient",
          //     className: "white-space_no-wrap",
          //     render: (value, item) => {
          //       if (item?.basisDocument?.name) {
          //         return (
          //           <TextResize
          //             text={item?.basisDocument?.name}
          //             url={item.investigatorReport}
          //             style={{ width: "350px" }}
          //           />
          //         );
          //       } else if (item?.prisoner?.basisDocument?.name) {
          //         return (
          //           <TextResize
          //             text={item?.prisoner.basisDocument?.name}
          //             url={item.investigatorReport}
          //             style={{ width: "350px" }}
          //           />
          //         );
          //       }
          //     },
          //   },
          //   {
          //     title: t("time-before"),
          //     dataKey: "patient",
          //     className: "white-space_no-wrap",
          //     render: (value, item) => {
          //       if (item?.prisoner?.basisDocument?.period)
          //         return item?.prisoner.basisDocument?.period;
          //     },
          //   },
          //   {
          //     title: t("to-account"),
          //     dataKey: "patient",
          //     className: "white-space_no-wrap",
          //     render: (value, item) => {
          //       if (item?.prisoner?.basisDocumentPart?.name)
          //         return item?.prisoner?.basisDocumentPart?.name;
          //     },
          //   },
          //   {
          //     title: t("kimga-teg"),
          //     dataKey: "patient",
          //     className: "white-space_no-wrap",
          //     render: (value, item) => {
          //       if (item?.prisoner?.organization?.name)
          //         return <span>{item?.prisoner?.organization?.name}</span>;
          //     },
          //   },
          {
            title: t("respon-employess"),
            dataKey: "patient",
            className: "white-space_no-wrap",
            render: (value, item) => {
              if (item?.prisoner?.organizationOfficer)
                return (
                  <span>{item?.prisoner.organizationOfficer ?? "--"}</span>
                );
              return "--";
            },
          },
          {
            title: t("eml-phone"),
            dataKey: "patient",
            className: "white-space_no-wrap",
            render: (value, item) => {
              if (item?.prisoner?.organizationPhone)
                return <span>{item?.prisoner?.organizationPhone ?? "--"}</span>;
            },
          },

          // {
          //     title: t("time-check"),
          //     dataKey: "patient",
          //     className: "white-space_no-wrap",
          //     render: (value, item, index) =>{
          //         const nowDate = dayjs()
          //         const fotmat = dayjs(item.createdAt);
          //         return <Countdown daysInHours={true} key={index} autoStart={true} date={Date.now()+nowDate.diff(fotmat)}/>
          //     }
          // },
        ]}
        items={get(prisonerList, "data.data.results")}
      />
      <Pagination
        currentPage={get(prisonerList, "data.data.pagination.page")}
        pageCount={get(prisonerList, "data.data.pagination.pageCount")}
        onPageChange={(newPage) => {
          setPage((old) => ({ ...old, page: newPage + 1 }));
          setSearchParams(createSearchParams({ page: newPage + 1 }));
        }}
      />
    </>
  );
};

export default DashboardInfo;
