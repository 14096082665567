import {lazy} from "react";

const PrisonersTopSecrer = lazy(() => import("./page/PrisonersTabletList"));
const PrisonerAddTabletSearch = lazy(() => import("./sub-pages/PrisonerAddTabletSearch"))
const TopForms = lazy(() => import("./sub-pages/TopForms"))
export const PrisonerTablet = [
    {
        id:4,
        path: '/prisoner-add-tablet',
        element: <TopForms/>,
        roles: new Set(['Superadmin','VsxManager', 'VsxInspector', 'SuperDuperAdmin'])
    },
    {
        id:4,
        path: '/prisoner-add-tablet-search/:id',
        element: <PrisonerAddTabletSearch/>,
        roles: new Set(['Superadmin','VsxManager', 'VsxInspector', 'SuperDuperAdmin'])
    },
    {
        id:4,
        path: '/prisoner-add-tablet-list',
        element: <PrisonersTopSecrer/>,
        roles: new Set(['Superadmin','VsxManager', 'VsxInspector', 'SuperDuperAdmin'])
    },
]
