import {lazy} from "react";

const VisitorsForms = lazy(() => import("../Visitors/sub-pages/VisitorsForms"))
const VisotorProduct = lazy(() => import("../VisitorProduct/page/VisitorProduct"));

export const VisitorRoter = [
    {
        id:8,
        path: "/visitors/form/:id",
        // element: <CashboxOrderSingle />,
        element: <VisitorsForms/>,
        roles: new Set(["Superadmin"])
    },
    {
        id:22,
        path: "/product",
        // element: <CashboxOrderSingle />,
        element: <VisotorProduct/>,
        roles: new Set(["Superadmin"])
    },
]
