import React, { useEffect, useState } from "react";

export const CountUp = (props) => {
  const { time } = props;
  let totalSeconds = Number(time);
  const [hour, setHour] = useState(Math.floor(totalSeconds / 3600));
  const [minute, setMinute] = useState(
    Math.floor((totalSeconds - hour * 3600) / 60)
  );
  const [seconds, setSeconds] = useState(
    totalSeconds - (hour * 3600 + minute * 60)
  );
  const [day, setDay] = useState(Math.floor(totalSeconds / 3600 / 24));
  const countUpTimer = () => {
    ++totalSeconds;
    setDay(Math.floor(totalSeconds / 3600 / 24));
    setHour(Math.floor(totalSeconds / 3600));
    setMinute(Math.floor((totalSeconds - hour * 3600) / 60));
    setSeconds(totalSeconds % 60);
  };
  useEffect(() => {
    const time = setInterval(countUpTimer, 1000);
    return () => clearInterval(time);
  }, []);
  return (
    <div style={{ color: "white" }}>
      {day + " kun"} : {Math.floor((hour < 10 ? "0" + hour : hour) % 24)} :{" "}
      {minute < 10 ? "0" + minute : minute} :{" "}
      {seconds < 10 ? "0" + seconds : seconds}
    </div>
  );
};
