import { Button, ModalDefault } from "components";
import Containers from "containers";
import React, { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import { useTranslation } from "react-i18next";
function InformationModal({ advocat, openModal, setOpenModal }) {
  const { t } = useTranslation();

  console.log(advocat)
  return (
    <div>
      {openModal && (
        <div className="modal_advocat information-modal-root">
          <>
            <ul>
              <li>
                <h2> Order raqami: {advocat[0]?.id}</h2>
                <h2>Tashkilot manzili:{advocat[0]?.contragentAddress}</h2>
                <h2> Tashkilot nomi:{advocat[0]?.contragentName}</h2>
                <h2>
                  {" "}
                  Tashkilot telefon raqami:{advocat[0]?.contragentPhone}
                </h2>
                <h2>
                  Guvohnoma bergan tashkilot:
                  {advocat[0]?.certificateGivenWho}
                </h2>
                <h2>
                  Guvohnoma raqami:
                  {advocat[0]?.certificateNumber}
                </h2>
                <h2>
                  Guvohnoma berilgan sana:
                  {advocat[0]?.certificateGivenDate}
                </h2>
                <h2>
                  Guvohnoma amal qilish muddati:
                  {advocat[0]?.certificateEndDate}
                </h2>
                <h2>
                  Guvohnoma turi:
                  {advocat[0]?.licenseTypes}
                </h2>
                <h2>Guvohnoma statusi:{advocat[0]?.licenseStatusLt}</h2>
                <h2>Order malumotnoma:{advocat[0]?.orderBaseName}</h2>
                <h2>
                  {" "}
                  Saqlanyotgan shaxs bilan shartnoma vaqti:
                  {advocat[0]?.contractDate}
                </h2>
                <h2> Qonuniy ko'mak nomi:{advocat[0]?.legalAidName}</h2>
                <h2>Tasdiqlangan vaqti:{advocat[0]?.approvedDate}</h2>
                <h2>Order statusi:{advocat[0]?.status}</h2>
              </li>
            </ul>
            <div className="d-flex justify-content-end">
              <Button
                design="primary"
                className="modal-btn-end fz_16 btn mt_30 w_full"
                text="Yopish"
                onClick={(event) => {
                  event.preventDefault();
                  setOpenModal(false);
                }}
              />
            </div>
          </>
        </div>
      )}
    </div>
  );
}

export default InformationModal;
