import React from "react";
import { FastField } from "formik";
import { get } from "lodash";

import Containers from "containers";
import {
  ModalDefault,
  Fields,
  Button,
  FileUpload,
  AvatarUpload,
  AttachFile,
  Info,
} from "components";
import { UploadBase } from "components/Upload/UploadBase";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

export const NarcoDispanserModal = ({
  isOpen,
  handleOverlayClose,
  valuesUpdate,
}) => {
  const { t } = useTranslation();
  //   console.log(valuesUpdate, "NarcoDispanserModal");
  //   console.log(get(valuesUpdate, "createdByDoctor"));
  return (
    <ModalDefault
      isOpen={isOpen}
      handleModalClose={handleOverlayClose}
      title={t("narco-title")}
      innerClass="max-width_500">
      <Containers.Form
        method={"post"}
        url="/prisoner-punishments"
        onSuccess={() => {
          handleOverlayClose();
        }}
        fields={[
          {
            name: "responsibleOfficer",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(valuesUpdate, "nationality") ?? "",
            onSubmitValue: (value) => value.value,
          },
          {
            name: "type",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(valuesUpdate, "type") ?? "",
            onSubmitValue: (value) => value.value,
          },
          {
            name: "date",
            validations: [{ type: "required" }],
            // value:get(values, 'responsibleUser.data.id') ?  {label: get(values, 'responsibleUser.data.attributes.username'),value:get(values, 'responsibleUser.data.id')} : '',
            onSubmitValue: (value) => {
              return value;
            },
          },
        ]}>
        {({ isSubmitting, values, ...rest }) => {
          return (
            <>
              <div className="row g-4">
                <div className="col-12">
                  <Info label={t("q-add")} data={get(valuesUpdate, "status")} />
                </div>
                <div className="col-12">
                  <Info
                    label={t("q-docktor")}
                    data={get(valuesUpdate, "createdByDoctor.fullName")}
                  />
                </div>
                <div className="col-12">
                  <Info
                    label={t("q-change")}
                    data={dayjs(get(valuesUpdate, "statusChangeData")).format(
                      "YYYY-MM-DD"
                    )}
                  />
                </div>{" "}
                <div className="col-12">
                  <Info
                    label={t("q-change-d")}
                    data={get(valuesUpdate, "updatedByDoctor.fullName")}
                  />
                </div>
              </div>
            </>
          );
        }}
      </Containers.Form>
    </ModalDefault>
  );
};
