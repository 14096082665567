import React from "react";
import { FastField } from "formik";
import { get } from "lodash";

import Containers from "containers";
import {
  ModalDefault,
  Fields,
  Button,
  FileUpload,
  AvatarUpload,
  AttachFile,
} from "components";
import { UploadBase } from "components/Upload/UploadBase";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export const MeasuresModal = ({
  isOpen,
  handleOverlayClose,
  handleOverlayOpen,
  values,
  refetch,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const filters = {};
  if (userData.region) {
    filters["region"] = userData.region.id;
  }
  if (userData.vsx) {
    filters["vsx"] = userData.vsx.id;
  }
  return (
    <ModalDefault
      isOpen={isOpen}
      handleModalClose={handleOverlayClose}
      title={t("measures")}
      innerClass="max-width_500">
      <Containers.Form
        method={"post"}
        url="/prisoner-encourages"
        customData={{ prisoners: id }}
        onSuccess={() => {
          handleOverlayClose();
          refetch();
        }}
        fields={[
          {
            name: "responsibleOfficer",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "nationality") ?? "",
            onSubmitValue: (value) => value.value,
          },
          {
            name: "type",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "type") ?? "",
            onSubmitValue: (value) => value.value,
          },
          {
            name: "date",
            validations: [{ type: "required" }],
            // value:get(values, 'responsibleUser.data.id') ?  {label: get(values, 'responsibleUser.data.attributes.username'),value:get(values, 'responsibleUser.data.id')} : '',
            onSubmitValue: (value) => {
              return value;
            },
          },
        ]}>
        {({ isSubmitting, values, ...rest }) => {
          console.log(rest);
          return (
            <>
              <div className="row g-4">
                <div className="col-12">
                  <FastField
                    name="responsibleOfficer"
                    component={Fields.AsyncSelect}
                    loadOptionsUrl={"/users"}
                    loadOptionsKey={(data) =>
                      data?.map((el) => ({
                        label: el.username,
                        value: el.id,
                      }))
                    }
                    loadOptionsParams={(searchText) => ({
                      filters: { ...filters },
                    })}
                    label={t("encourages-person")}
                    placeholder={t("select")}
                  />
                </div>
                <div className="col-12">
                  <FastField
                    name="type"
                    component={Fields.AsyncSelect}
                    loadOptionsUrl={"/prisoner-encourage-types"}
                    loadOptionsKey={(data) =>
                      data?.data?.map((el) => ({
                        label: el.name,
                        value: el.id,
                      }))
                    }
                    label={t("encourages-type")}
                    placeholder={t("select")}
                  />
                </div>
                <div className="col-12">
                  <FastField
                    name="date"
                    component={Fields.DatePicker}
                    label={t("seletect-date")}
                    placeholder={t("seletect-date")}
                  />
                </div>
                <div className="col-12">
                  <h2 className="search_label">{t("file")}</h2>
                  <div className="file-upload">
                    <FastField
                      name="document"
                      title={t("file")}
                      component={AttachFile}
                      label={t("file")}
                    />
                  </div>
                </div>
              </div>

              <Button
                design="primary"
                type="submit"
                className="modal-btn fz_16 btn mt_40 w_full"
                text={t("save")}
                isLoading={isSubmitting}
              />
            </>
          );
        }}
      </Containers.Form>
    </ModalDefault>
  );
};
