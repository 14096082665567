import React, { useEffect, useState } from "react";
import { FastField } from "formik";
import { get } from "lodash";

import Containers from "containers";
import {
  ModalDefault,
  Fields,
  Button,
  FileUpload,
  AvatarUpload,
  AttachFile,
  SearchStatus,
} from "components";
import { UploadBase } from "components/Upload/UploadBase";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { value } from "lodash/seq";

export const ModdaChangeModal = ({
  isOpen,
  handleOverlayClose,
  values,
  refetch,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [formValue, setFormValue] = useState();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const CustomSelect = ({ filters, url }) => {
    console.log(filters);
    const [fil, setfil] = useState();
    useEffect(() => {
      setfil(filters);
    }, [filters]);
    return (
      <>
        <div className="col-12">
          <FastField
            name="basisDocumentPart"
            component={Fields.AsyncSelect}
            loadOptionsUrl={url}
            filters={filters}
            loadOptionsKey={(data) =>
              data?.data?.map((el) => ({
                label: el.name,
                value: el.id,
              }))
            }
            label={t("jk-role-section")}
            placeholder={t("select")}
          />
        </div>
      </>
    );
  };
  const filters = {};
  if (userData.region) {
    filters["region"] = userData.region.id;
  }
  if (userData.vsx) {
    filters["vsx"] = userData.vsx.id;
  }
  return (
    <ModalDefault
      isOpen={isOpen}
      handleModalClose={handleOverlayClose}
      title={t("prisoner-time-out")}
      innerClass="max-width_500">
      <div>
        <SearchStatus
          name={"reestr"}
          label={t("reester-search")}
          placeholder={t("search")}
          setValues={(data) => setFormValue(data)}
        />
      </div>{" "}
      <br />
      <Containers.Form
        method={"put"}
        customData={{ prisoner: id }}
        url="/prisoner/update-prisoner-bases-doc"
        onSuccess={() => {
          handleOverlayClose();
          refetch();
        }}
        fields={[
          {
            name: "basisDocument",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "basisDocument") ?? "",
            onSubmitValue: (value) => value.value,
          },
          {
            name: "basisDocumentPart",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "basisDocumentPart") ?? "",
            onSubmitValue: (value) => value.value,
          },
        ]}>
        {({ isSubmitting, values, ...rest }) => {
          setFormValue(values);
          return (
            <>
              <div className="row g-4">
                <div className="col-12">
                  <FastField
                    name="basisDocument"
                    component={Fields.AsyncSelect}
                    filters={{ id: { $ne: 7 } }}
                    loadOptionsUrl={"/prisoner-basis-documents"}
                    loadOptionsKey={(data) =>
                      data?.data?.map((el) => ({
                        label: el.name,
                        value: el.id,
                      }))
                    }
                    label={t("jk-role")}
                    placeholder={t("select")}
                  />
                </div>
                <div className="col-12">
                  <FastField
                    component={CustomSelect}
                    url={`/prisoner-basis-document-parts`}
                    // loadOptionsUrl={'/prisoner-punishment-types'}
                    // loadOptionsKey={(data) => data?.data?.map((el) => ({
                    //     label: el.name,
                    //     value: el.id
                    // }))}
                    // loadOptionsParams={(searchText) => ({
                    //     filters: {...filters},
                    // })}
                    label={t("punishment-type")}
                    // placeholder="Миллати"
                  />
                </div>
              </div>

              <Button
                design="primary"
                type="submit"
                className="modal-btn fz_16 btn mt_40 w_full"
                text={t("save")}
                isLoading={isSubmitting}
              />
            </>
          );
        }}
      </Containers.Form>
    </ModalDefault>
  );
};
