import {lazy} from "react";

const Emergency = lazy(() => import("../Emergency/page/Emergency"));
const PDF = lazy(() => import("../Emergency/components/PDF"));
const  EmergencyForm= lazy(() => import("../Emergency/components/EmergencyForm"));
export const EmergencyRouter =[
    {
        id:324543567854332,
        path: "/emergency",
        // element: <Debtors />,
        element: <EmergencyForm />,
        roles: new Set(['Superadmin','VsxManager', 'VsxInspector', 'SuperDuperAdmin'])
    },
    {
        id:3245435678543322,
        path: "/emergency-pdf/:id",
        // element: <Debtors />,
        element: <PDF/>,
        roles: new Set(['Superadmin','VsxManager', 'VsxInspector', 'SuperDuperAdmin'])
    }
    // {
    //     id:32454356785433222,
    //     path: "/emergency/:id",
    //     // element: <Debtors />,
    //     element: <EmergencyForm/>,
    //     roles: new Set(['Superadmin','VsxManager', 'VsxInspector', 'SuperDuperAdmin'])
    // }
]

