import React from "react";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty } from "lodash";

import { storage } from "services";
import { useFetchOneAuth } from "hooks";
import { auth } from "store/actions";
import { userSelector } from "store/selectors";

import { AuthorizedRoutes, UnAuthorizedRoutes } from "AppRoutes";

import "react-toastify/dist/ReactToastify.css";
import { useDarkMode } from "context/DarkModeContext";

export function App() {
  const { darkMode } = useDarkMode();
  const user = useSelector(userSelector)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useFetchOneAuth({
    url: "/users/me",
    urlSearchParams: {
      populate: "*",
    },
    queryOptions: {
      onSuccess: (user) => {
        dispatch(auth.success({ user }));
        storage.set("token", get(user, "token"));
      },
      onError: (error) => {
        dispatch(auth.failure(error));
        navigate("/login");
      },
    },
  });

  return (
    <div className={darkMode ? `App light` : `App dark`}>
      {!isEmpty(user) ? <AuthorizedRoutes /> : <UnAuthorizedRoutes />}
      <ToastContainer className="app-toast" />
    </div>
  );
}
