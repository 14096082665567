export { statisticsAdapter } from "./statisticsAdapter";
export { productAdapter } from "./productAdapter";
export { kitchenMenuProductAdapter } from "./kitchenMenuProductAdapter";
export { kitchenMenuFoodAdapter } from "./kitchenMenuFoodAdapter";
export { roomProductsAdapter } from "./roomProductsAdapter";
export { excelAdapter } from "./excelAdapter";
export { storeOrderAdapter } from "./storeOrderAdapter";
export { questionAdapter } from "./questionAdapter";
export { orderAdapter } from "./orderAdapter";
export { debtorAdapter } from "./debtorAdapter";
export { documentAdapter } from "./documentAdapter";
