import { lazy } from "react";
import Inspection from "./components/Inspection";

const CheckList = lazy(() => import("../Inspection/page/CheckList"));

export const InspectionRouter = [
  {
    id: 67,
    path: "/inspection",
    element: (
      <>
        <CheckList />
      </>
    ),
    roles: new Set(["Superadmin"]),
  },
  {
    id: 68,
    path: "/archive/inspection/:id",
    element: (
      <>
        <Inspection />
      </>
    ),
    roles: new Set(["Superadmin"]),
  },
];
