import React, { createContext, useContext, useState } from "react";

const VsxMqContext = createContext();

const VsxMqContextProvider = ({ children }) => {
    const [vsxMode, setVsxMode] = useState(false);
    const toggleVsxMq = () => {
        setVsxMode(!vsxMode);
    };
  
    return (
      <VsxMqContext.Provider value={{ vsxMode, toggleVsxMq }}>
        {children}
      </VsxMqContext.Provider>
    );
  };
  
  
  const useVsxMqMode = () => {
    const context = useContext(VsxMqContext);
    if (!context) {
      throw new Error("useDarkMode must be used within a DarkModeProvider");
    }
    return context;
  };
  
  export { VsxMqContextProvider, useVsxMqMode };