import React, { useEffect, useMemo, useState } from "react";
import {
  Table,
  Pagination,
  CountUp,
  TextResize,
  PageHeading,
  ConfirmModal,
  InputSearch,
  Spinner,
} from "components";
import { useTranslation } from "react-i18next";
import { useDeleteWithConfirm, useFetchList, useOverlay } from "hooks";
import { useNavigate, useParams } from "react-router-dom";
import { time } from "../../../services/time";
import dayjs from "dayjs";
import { Span } from "modules/Prisoners/components";
import { AddRoomsModal } from "modules/Camera/components";
import { Select } from "components/Fields";
import Example from "../component/Table";
import {
  MRT_TableContainer,
  useMaterialReactTable,
} from "material-react-table";
import { httpClient } from "services";
import { toast } from "react-toastify";
import "./style.scss";
import { useVsxMqMode } from "context/VsxMqContext";

function CameraEditTable() {
  const { t } = useTranslation();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const { vsxMode } = useVsxMqMode();
  const isRole = localStorage.getItem("roleName") === "Superadmin";
  const roleName = localStorage.getItem("roleName") === "RegionalManager";
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    name: undefined,
    value: undefined,
  });
  const searchFilter = {
    region: isRole
      ? { id: 2, label: "IIV Markaziy VSX" }
      : { id: userData?.vsx?.id },
    vsx: undefined,
  };
  const [search, setSearch] = useState(searchFilter);
  const filter = {};

  if (userData.region) {
    filter["vsx][region"] = userData?.region?.id;
  }
  const addRooms = useOverlay({ uniqueName: "addRooms" });
  const cameraList = useFetchList({
    url: "/rooms",
    urlSearchParams: {
      populate: "responsibleUser,vsx",
      pagination: {
        pageSize: 1000,
      },
      filters: {
        vsx: search?.vsx === undefined ? search?.region?.id : search?.vsx?.id,
      },
      sort: "order:asc",
    },
  });

  const cameraDelete = useDeleteWithConfirm({
    uniqueName: "removePrisonerModal1",
    url: "/rooms",
  });
  const remove = (item) => {
    cameraDelete.setId(item.id);
    cameraDelete.handleOverlayOpen();
  };

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row?.name,
        header: t("room-name"),
      },
      {
        accessorFn: (row) => row?.capacity,
        header: t("room-size"),
      },
      {
        accessorFn: (row) => row?.vsx?.name,
        header: t("VSX-name"),
      },
      {
        header: t("responsible-employees"),
        accessorFn: (row) =>
          row?.responsibleUser?.firstName +
          " " +
          row?.responsibleUser?.sureName,
      },
      {
        header: "O'chirish",
        accessorFn: (row) => {
          return (
            <span
              style={{
                background: "var(--primary-red)",
                color: "var(--text-light)",
                padding: "8px 14px",
                borderRadius: "12px",
                cursor: "pointer",
                fontSize: "14px",
              }}
              onClick={(e) => {
                e.preventDefault();
                remove(row);
              }}>
              O'chirish
            </span>
          );
        },
      },
    ],
    []
  );

  const [data, setData] = useState([]);

  useEffect(() => {
    setData(cameraList?.data || []);
  }, [cameraList?.data]);

  const table = useMaterialReactTable({
    autoResetPageIndex: false,
    columns,
    data,
    enableRowOrdering: true,
    enableSorting: false,
    initialState: { pagination: { pageSize: 1000 } },
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          data?.splice(
            hoveredRow?.index,
            0,
            data?.splice(draggingRow?.index, 1)[0]
          );
          setData([...data]);
        }
      },
    }),
  });
  const regionList = isRole
    ? useFetchList({
        url: "/regions",
        urlSearchParams: {
          pagination: {
            page: 1,
            pageSize: 1000,
          },
          filters: {
            id: userData?.vsx?.id,
          },
        },
      })
    : () => false;

  const vsxList = isRole
    ? useFetchList({
        url: "/vsxes",
        urlSearchParams: {
          pagination: {
            page: 1,
            pageSize: 1000,
          },
          populate: "*",
          filters: {
            region: search?.region?.id,
            isSpecial: vsxMode,
          },
        },
      })
    : () => false;

  const vsxListData = roleName
    ? useFetchList({
        url: "/vsxes",
        urlSearchParams: {
          pagination: {
            page: 1,
            pageSize: 1000,
          },
          populate: "*",
          filters: {
            region: userData?.region?.id,
            isSpecial: vsxMode,
          },
        },
      })
    : () => false;

  const editRooms = async () => {
    try {
      const { dataRequest } = await httpClient.post("/rooms/change-order", {
        rooms: data,
      });
      cameraList.refetch();
      toast.success("Ma'lumot saqlandi!");
    } catch (err) {
      console.log(err);
      toast.error("Xatolik yuz berdi!");
    }
  };
  if (cameraList.isLoading) {
    <Spinner className="table-spinner" />;
  }

  return (
    <div className="cameratable-edit">
      <AddRoomsModal
        isOpen={addRooms.isOverlayOpen}
        handleModalClose={addRooms.handleOverlayClose}
        onSuccess={cameraList.refetch}
      />
      <ConfirmModal
        cancelText={t("cancel-text")}
        successText={t("remove")}
        title={t("camera-delete")}
        isOpen={cameraDelete.isOverlayOpen}
        cancelAction={cameraDelete.handleOverlayClose}
        successAction={() => {
          cameraDelete
            .mutateAsync(cameraDelete.id)
            .then(() => cameraList.refetch());
          cameraDelete.handleOverlayClose();
        }}
      />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <InputSearch>
          {isRole && (
            <Select
              value={search?.region}
              isClearable={true}
              options={regionList?.data?.map((el) => ({
                id: el.id,
                label: el.name,
              }))}
              placeholder={t("region-name-search")}
              onValueChange={(data) => {
                data
                  ? setSearch((old) => ({ ...old, region: data }))
                  : setSearch((old) => ({ ...old, region: undefined }));
              }}
            />
          )}
          {isRole && (
            <Select
              isClearable={true}
              value={search?.vsx}
              options={vsxList?.data?.map((el) => ({
                id: el.id,
                label: el.name,
              }))}
              placeholder={t("vsx-name-select")}
              onValueChange={(data) => {
                data
                  ? setSearch((old) => ({ ...old, vsx: data }))
                  : setSearch((old) => ({ ...old, vsx: undefined }));
              }}
            />
          )}
        </InputSearch>
        {roleName && (
          <Select
            isClearable={true}
            value={search?.vsx}
            options={vsxListData?.data?.map((el) => ({
              id: el.id,
              label: el.name,
            }))}
            placeholder={t("vsx-name-select")}
            onValueChange={(data) => {
              data
                ? setSearch((old) => ({ ...old, vsx: data }))
                : setSearch((old) => ({ ...old, vsx: undefined }));
            }}
          />
        )}
      </div>

      <div
        className="d-flex align-items-center mb_20"
        style={{ justifyContent: "flex-end" }}>
        {(userData?.role?.name === "RegionalManager" ||
          userData?.role?.name === "SuperDuperAdmin" ||
          userData?.role?.name === "VsxManager" ||
          userData?.role?.name === "SpecialVsxManager" ||
          userData?.role?.name === "Superadmin") && (
          <button onClick={editRooms} className="btn btn__primary mt_10">
            {t("Saqlash")}
          </button>
        )}
        {(userData?.role?.name === "RegionalManager" ||
          userData?.role?.name === "SuperDuperAdmin" ||
          userData?.role?.name === "VsxManager" ||
          userData?.role?.name === "SpecialVsxManager") && (
          <PageHeading
            title={t("camera-add")}
            mainAction={addRooms.handleOverlayOpen}
            btnText={t("camera-add")}
            style={{ width: "auto" }}
          />
        )}
      </div>
      {cameraList.isLoading ? (
        <Spinner className="table-spinner" />
      ) : (
        <MRT_TableContainer table={table} />
      )}
    </div>
  );
}

export default CameraEditTable;
