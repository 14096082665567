import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { Header, Sidebar } from "./components";
import { isMobile, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";

export const MainLayout = () => {
  const [mini, setMini] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const checkList = (roleName, id) => {
    // console.log(roleName, id)
    switch (roleName) {
      case "Superadmin":
        return navigate(`/dashboard`);
      case "VsxManager":
        return navigate(`/dashboard`);
      case "VsxInspector":
        return navigate(`/cameras`);
      case "RegionalManager":
        return navigate(`/dashboard`);
      case "SuperDuperAdmin":
        return navigate(`/dashboard`);
      case "SpecialVsxInspector":
        return navigate(`/cameras`);
      case "SpecialVsxManager":
        return navigate(`/dashboard`);
      // default: return navigate(`/${id}/dashboard`)
    }
  };
  const rolename = localStorage.getItem("roleName");
  useEffect(() => {
    checkList(rolename);
  }, []);

  return (
    <>
      <Header
        hasSearch={true}
        hasDatePicker={false}
        hasNotification={rolename !== "SpecialVsxInspector"}
        hasLanguage={true}
        hasProfile={true}
        hasLogo={true}
        containerClass="max-width_full w_full px_0"
      />
      <div
        className={mini || isMobile || isTablet ? "page pl_70" : "page pl_350"}
        style={{ position: "relative" }}>
        <Sidebar setMini={setMini} mini={mini} />
        <main className="main scroll-style">
          <Outlet />
        </main>
      </div>
    </>
  );
};
