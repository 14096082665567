import React from 'react';
import {useTranslation} from "react-i18next";
import Containers from "../../containers";
import {FastField, Form, Formik} from "formik";
import *as Yup from 'yup'
import {Fields, Typography} from '../../components'
import {ReactComponent as SearchIcon} from "../../assets/icons/search.svg";
import {get, isFunction} from "lodash";
import dayjs from "dayjs";
export const  ReportsSearch = (props) => {
    const {onSubmit} = props
    const {t} = useTranslation()
    const handleSubmit = (values, formHelpres) => {
        console.log(values, formHelpres)
        isFunction(setValue) ? setValue(values) : formHelpres;
    }
    return (
        <div>
            <Containers.Form

                fields={[

                    {
                        name:'region',
                        validations: [{type: "required"}],
                        validationType: "object",
                        onSubmitValue: (value) => value,
                    }, {
                        name:'range',
                        validations: [{type: "required"}],
                        validationType: "object",
                        onSubmitValue: (value) => value,
                    },
                ]}

            >
                {({isSubmitting, values, setFieldValue, ...rest}) => {
                    return <>
                        <div className="row">
                            <div className={'col-4'}>
                                <FastField
                                    name="region"
                                    component={Fields.AsyncSelect}
                                    loadOptionsUrl={"/regions"}
                                    loadOptionsKey={(data) => data?.data?.map((el) => ({
                                                                        label: el.name,
                                                                        value: el.id,
                                                                    }))
                                                                }
                                    // label={t('VSX-address')}
                                    placeholder={t('select')}
                                />
                            </div> <div className={'col-4'}>
                            <FastField
                                name="range"
                                                        component={Fields.RangePicker}
                                                        size="xsm"
                                                        className="filter__control mr_15"
                                                        // label={t('isSpecial-name-l')}
                                                        placeholder={t('isSpecial-name-l')}
                                                    />
                            </div>
                            <div className="col-2">
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="fz_16 btn btn__primary d-flex gap_12"
                                >{isSubmitting && <span className="btn__spinner"></span>}<SearchIcon/></button>
                            </div>
                        </div>
                    </>
                }

                }

            </Containers.Form>
        </div>
    );
    // return (
    //     <div className='mb_20'>
    //         <Formik
    //             initialValues={{
    //                 region: '',
    //                 range: ''
    //             }}
    //             validationSchema={SignupSchema}
    //             enableReinitialize={true}
    //             onSubmit={
    //                 (value,formHelpers) => {
    //                     // console.log(value, formHelpers)
    //                     isFunction(onSubmit) ? onSubmit(value, formHelpers) : handleSubmit(value, formHelpers)
    //                 }
    //             }
    //         >
    //             {(formik) => {
    //                 console.log(formik, 'formik')
    //                 return <Form>
    //                 <div className='row'>
    //                     <div className="col-5">
    //                         <FastField
    //                             name="region"
    //                             component={Fields.AsyncSelect}
    //                             loadOptionsUrl={"/regions"}
    //                             loadOptionsKey={(data) =>
    //                                 data?.data?.map((el) => ({
    //                                     label: el.name,
    //                                     value: el.id,
    //                                 }))
    //                             }
    //                             label={t('VSX-address')}
    //                             placeholder={t('select')}
    //                         />
    //                         {get(formik?.touched, 'region') && get(formik.errors, 'region') && (
    //                             <Typography Type="span" className="control__error" text={formik.errors['region']} />
    //                         )}
    //                     </div>
    //                     <div className="col-5">
    //                         <FastField
    //                             name="range"
    //                             component={Fields.RangePicker}
    //                             size="xsm"
    //                             className="filter__control mr_15"
    //                             onDateChange={(date) => {
    //                                 const  {start_at, end_at} =
    //                                 {start_at:dayjs(date[0]).format('YYYY-MM-DD'), end_at:dayjs(date[0]).format('YYYY-MM-DD')}
    //                                 formik.setFieldValue("range", {start_at, end_at});
    //                             }}
    //                             label={t('isSpecial-name-l')}
    //                             placeholder={t('isSpecial-name-l')}
    //                         />
    //                         {get(formik?.touched, 'range') && get(formik?.errors, 'range') && (
    //                             <Typography Type="span" className="control__error" text={formik?.errors['range']} />
    //                         )}
    //                     </div>
    //                     <div className='col-2'>
    //                         <button
    //                             type="submit"
    //                             disabled={isSubmitting}
    //                             className="fz_16 btn mt_25 btn__primary d-flex gap_12"
    //                         >{isSubmitting && <span className="btn__spinner"></span>}<SearchIcon/></button>
    //                     </div>
    //                 </div>
    //
    //                 </Form>
    //             }}
    //         </Formik>
    //     </div>
    // );
}
