import { lazy } from "react";

const Emergency = lazy(() => import("../Specialnotice/page/Emergency"));
const PDF = lazy(() => import("../Specialnotice/components/PDF"));
const EmergencyForm = lazy(() =>
  import("../Specialnotice/components/EmergencyForm")
);
export const SpecialnoticeRouter = [
  {
    id: 324543567854332,
    path: "/specialnotice-list",
    // element: <Debtors />,
    element: <Emergency />,
    roles: new Set(["RegionalManager", "Superadmin"]),
  },
  {
    id: 3245435678543322,
    path: "/emergency-pdf/:id",
    // element: <Debtors />,
    element: <PDF />,
    roles: new Set([
      "Superadmin",
      "VsxManager",
      "VsxInspector",
      "SuperDuperAdmin",
    ]),
  },
  {
    id: 32454356785433222435345,
    path: "/specialnotice-create",
    // element: <Debtors />,
    element: <EmergencyForm />,
    roles: new Set([
      "Superadmin",
      "VsxManager",
      "VsxInspector",
      "SuperDuperAdmin",
      "",
      "",
    ]),
  },
];
