import { Button, ModalDefault } from "components";
import Containers from "containers";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function UpdateItemModal({ isOpen, handleModalClose }) {
  return (
    <ModalDefault
      isOpen={isOpen}
      handleModalClose={handleModalClose}
      innerClass="max-width_500">
      <Containers.Form
        // url={`/prisoner-room-leaves/${id}`}
        method={"put"}
        onSuccess={() => {
          handleModalClose();
        }}
        fields={
          [
            //   {
            //     name: "delegates",
            //     onSubmitValue: (value) => dataArray,
            //   },
          ]
        }>
        {({ isSubmitting, values, ...rest }) => {
          return (
            <>
              {/* <ul>
                {dataArray?.length > 0
                  ? dataArray &&
                    dataArray?.map((worker, index) => (
                      <li
                        className="d-flex align-items-center advokat-menu-list"
                        key={index}>
                        <input
                          type="checkbox"
                          className="advokat_input-checkbox"
                          checked={worker.isChecked}
                          onChange={() => updateCheckbox(index)}
                        />
                        <h2 className="search_label-name">{worker.name}</h2>
                      </li>
                    ))
                  : null}
              </ul> */}
              <Button
                design="primary"
                type="submit"
                className="modal-btn fz_16 btn mt_40"
                text="Yangilash"
                isLoading={isSubmitting}
              />
            </>
          );
        }}
      </Containers.Form>
    </ModalDefault>
  );
}

export default UpdateItemModal;
