import React, {useMemo, useState} from "react";
import {ErrorMessage, FastField, Field, FieldArray} from "formik";
import {useSelector} from "react-redux";
import {get} from "lodash";

import {userSelector} from "store/selectors";
import {useFetchList, useGetLanguage,} from "hooks";
import {httpClient, queryBuilder, time} from "services";
import '../../Prisoners/styles/tintuv.scss'
import {ReactComponent as IconDelete} from "../../../assets/icons/delete.svg";
import Containers from "containers";
import {
    Fields,
    Button,
    AvatarUpload, AttachFile, Info,
} from "components";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
// const Info = ({icon, data, label}) => {
//     return (
//         <div className='search'>
//             <h2 className='search_label'>{label}</h2>
//             <div className='search_data'><span>{data}</span> <span>{icon &&
//                 <svg width="15" height="15" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
//                     <path
//                         d="M16 2.3027H15V1.31029C15 0.764459 14.55 0.317871 14 0.317871C13.45 0.317871 13 0.764459 13 1.31029V2.3027H5V1.31029C5 0.764459 4.55 0.317871 4 0.317871C3.45 0.317871 3 0.764459 3 1.31029V2.3027H2C0.89 2.3027 0.00999999 3.19588 0.00999999 4.28754L0 18.1814C0 19.273 0.89 20.1662 2 20.1662H16C17.1 20.1662 18 19.273 18 18.1814V4.28754C18 3.19588 17.1 2.3027 16 2.3027ZM16 17.189C16 17.7348 15.55 18.1814 15 18.1814H3C2.45 18.1814 2 17.7348 2 17.189V7.26479H16V17.189ZM4 9.24962H6V11.2345H4V9.24962ZM8 9.24962H10V11.2345H8V9.24962ZM12 9.24962H14V11.2345H12V9.24962Z"
//                         fill="#414141"/>
//                 </svg>
//             }</span></div>
//         </div>
//     )
// }
export const InsperktorTForms = (props) => {
    const {values} = props;
    const {getLanguageValue} = useGetLanguage();
    const user = useSelector(userSelector);
    const navLink = useNavigate()
    const {id} = useParams();
    const {t} = useTranslation()

    const [childpartId, setPartId] = useState(null)
    const handleFormOptions = (values, setFields) => {
        if (values.basisDocument?.value) {
            setPartId(values.basisDocument.value)
        }
    }
    return (
        <>
            <Containers.Form
                validate={(event) => {}}
                method={"put"}
                url={"/prisoner/top-secret-prisoner"}
                customData={{id}}
                onSuccess={({data}) => {
                    navLink(`/prisoner/medical/${data.id}/create`)
                }}
                fields={[
                    {
                        name: "hasItems",
                        value: get(values, 'hasItems'),
                        onSubmitValue: (value) => {
                            if(value === 'Ha') return  true
                            return false
                        }
                    },
                    {
                        name: "hasProhibiteditems",
                        value: get(values, 'hasProhibiteditems'),
                        onSubmitValue: (value) => {
                            if(value === 'Ha') return  true
                            return false
                        }
                    },
                    {
                        name: "items",
                        value: get(values, 'middleName'),
                        onSubmitValue: (value) => value||''
                    },{
                        name: "prohibiteditems",
                        value: get(values, 'middleName'),
                        onSubmitValue: (value) => value||''
                    },
                    {
                        name: "responsibleMedWorkers",
                        validations: [{type: "required"}],
                        value: get(values, 'responsibleMedWorkers'),
                        onSubmitValue: (value) => value,
                    },
                    {
                        name: "searchDocument",
                        // validations: [{type: "required"}],
                        value: get(values, 'searchDocument'),
                        onSubmitValue: (value) => {
                            if(!value) return null;
                            return  value
                        },
                    },
                    {
                        name: "medExamInfoDocument",
                        // validations: [{type: "required"}],
                        value: get(values, 'medExamInfoDocument'),
                        onSubmitValue: (value) => {
                            if(!value) return null;
                            return  value;
                        },
                    },
                    {
                        name: "medExamDeedDocument",
                        validationType: 'array',
                        value: get(values, 'medExamDeedDocument'),
                        onSubmitValue: (value) => {
                            if(!value) return null;
                            return  value
                        },
                    },
                    {
                        name: "medExamDate",
                        // validations: [{type: "required"}],
                        value: get(values, 'medExamDate'),
                        onSubmitValue: (value) => {
                            if(value) return value;
                            return  dayjs().format('YYYY-MM-DD HH:mm')
                        },
                    },
                    {
                        name: "medExamResponsibleOfficer",
                        // validations: [{type: "required"}],
                        value: get(values, 'medExamResponsibleOfficer'),
                        onSubmitValue: (value) => {
                            if(value) return value;
                            return  JSON.parse(localStorage.getItem('userData')).id
                        },
                    },
                    {
                        name: "isConvicted",
                        validations: [{type: "required"}],
                        value: get(values, 'isConvicted') ?? false,
                        onSubmitValue: (value) => {
                            return value
                        },
                    },

                    {
                        name: "isInvalid",
                        validations: [{type: "required"}],
                        value: get(values, 'isInvalid') ?? false,
                        onSubmitValue: (value) => {
                            return value
                        },
                    },

                    {
                        name: "invalidType",
                        validations: [{type: "required"}],
                        validationType: "object",
                        value: get(values, 'gender.data.id') ? {
                            label: get(values, 'gender.data.name'),
                            value: get(values, 'gender.data.id')
                        } : '',
                        onSubmitValue: (value) => {
                            return value.value
                        }
                    },

                    {
                        name: "appearance",
                        validationType: "object",
                        value: get(values, 'appearance.id') ? {
                            label: get(values, 'appearance.name'),
                            value: get(values, 'appearance.id')
                        } : '',
                        onSubmitValue: (value) => {
                            return value.value
                        }
                    },

                ]}
            >
                {({isSubmitting, values, setFieldValue, ...rest}) => {
                    handleFormOptions(values, setFieldValue)
                    console.log(values, rest)
                    return <>
                        <div className="row g-4">
                            <div className="col-12">
                                <h2 className='title'>{t('search-to')}</h2>
                                <hr/>
                                <br/>
                                <div className="row">

                                    <div className="col-3 col-md-4 col-lg-4 col-xl-4 col-lg-3 col-sm-6"><Info
                                        className='w_full'
                                        label={t('search-data')}
                                        icon
                                        data={dayjs().format('DD.MM.YYYY HH:mm')}/>
                                    </div>
                                    {/*<div className="col-2 col-md-4 col-lg-2"><Info label={'Тинтув вақти'} icon data={dayjs().format('HH:mm')}/></div>*/}
                                    <div className="col-3 col-md-4 col-lg-4 col-xl-4 col-lg-3 col-sm-6"><Info
                                        label={t('respon-employess')}
                                        data={JSON.parse(localStorage.getItem('userData')).username}/>
                                    </div>
                                    <div className="col-3 col-md-4 col-lg-2 col-xl-2 col-lg-2 col-sm-6">
                                        <h2 className='search_label'>{t('minutes')}</h2>
                                        <div className='file-upload'>
                                            <FastField
                                                name="searchDocument"
                                                title={t('file')}
                                                component={AttachFile}
                                                label={t('minutes')}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 col-sm-6 col-md-12'></div>
                                    <br/>
                                    <div className='col-1 col-md-2 col-lg-2 col-xl-2'>
                                        <p className="mb_20 mt_20" style={{fontSize: 14 + 'px'}}>{t('has-product')}</p>
                                        <div className="d-flex">
                          <span>
                            <FastField
                                name="hasItems"
                                component={Fields.RadioButton}
                                label={t('yes')}
                                value={t('yes')}
                            />
                          </span>
                                            <span className='ml_10'>
                            <FastField
                                name="hasItems"
                                component={Fields.RadioButton}
                                label={t('no')}
                                value={t('no')}
                            />
                       </span>
                                        </div>
                                    </div>
                                    {values.hasItems === 'Ha' &&<div className='col-1 col-md-2 col-lg-2 col-xl-2'>
                                        <p className="mb_20 mt_20" style={{fontSize: 14 + 'px'}}>{t('proxibid-product')}</p>
                                        <div className="d-flex">
                          <span>
                            <FastField
                                name="hasProhibiteditems"
                                component={Fields.RadioButton}
                                label={t('yes')}
                                value={t('yes')}
                            />
                          </span>
                                            <span className='ml_10'>
                            <FastField
                                name="hasProhibiteditems"
                                component={Fields.RadioButton}
                                label={t('no')}
                                value={t('no')}
                            />
                       </span>
                                        </div>
                                    </div>}
                                    <div className="col-9 col-md-12 col-xl-9 col-sm-12">
                                        {values.hasItems === 'Ha'&&  <div className="col-6 col-md-12 col-sm-col-lg-6 col-xl-6 mt_10">
                                            <FastField
                                                name="items"
                                                component={Fields.InputText}
                                                label={t('has-product')}
                                                // placeholder="Доимий рўйхатга олинган манзил"
                                            />
                                        </div>}
                                        {values.hasProhibiteditems === 'Ha'&&  <div className="col-6 col-md-12 col-sm-col-lg-6 col-xl-6 mt_10">
                                            <FastField
                                                name="prohibiteditems"
                                                component={Fields.InputText}
                                                label={t('proxibid-product')}
                                                // placeholder="Доимий рўйхатга олинган манзил"
                                            />
                                        </div>}

                                    </div>


                                </div>
                                {/*<span className='time_now'>{dayjs().format('DD.MM.YYYY')}</span>*/}
                                {/*<span className='time_now'>{dayjs().format('HH:mm')}</span>*/}
                            </div>
                            <div className="col-12">
                                <h2 className='title'>{t('body-to-search')}</h2>
                                <hr/>
                                <br/>
                                <div className="row">

                                    <div className="col-3 col-md-4 col-lg-4 col-xl-4 col-lg-3 col-sm-6">
                                        <FastField
                                        name='medExamDate'
                                        component={Info}
                                        label={t('search-medical-time')}
                                        data={dayjs().format('DD.MM.YYYY HH:mm')}
                                        >
                                        </FastField>
                                    </div>
                                    {/*<div className="col-2 col-md-4 col-lg-2"><Info label={'Тинтув вақти'} icon data={dayjs().format('HH:mm')}/></div>*/}
                                    <div className="col-3 col-md-4 col-lg-4 col-xl-4 col-lg-3 col-sm-6">
                                        <FastField
                                            name='medExamResponsibleOfficer'
                                            component={Info}
                                            label={t('respon-employess')}
                                            data={JSON.parse(localStorage.getItem('userData')).username}
                                        >
                                        </FastField>
                                    </div>
                                    <div className="col-3 col-md-4 col-lg-2 col-xl-2 col-lg-2 col-sm-6">
                                        <h2 className='search_label'>{t('medical-data')}</h2>
                                        <div className='file-upload'>
                                            <FastField
                                                name="medExamInfoDocument"
                                                title={t('file')}
                                                component={AttachFile}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-3 col-md-4 col-lg-2 col-xl-2 col-lg-2 col-sm-6">
                                        <h2 className='search_label'>{t('act')}</h2>
                                        <div className='file-upload'>
                                            <FastField
                                                name="medExamDeedDocument"
                                                title={t('file')}
                                                isMultiple
                                                component={AttachFile}
                                            />
                                        </div>
                                    </div>
                                    {/*<div className='col-4 col-sm-6 col-md-12'></div> */}
                                    <br/>
                                    <div className="col-4 col-sm-6 mt_10 col-md-4">
                                        <FastField
                                            name="invalidType"
                                            component={Fields.AsyncSelect}
                                            loadOptionsUrl={'/prisoner-invalid-types'}
                                            loadOptionsKey={(data) => data?.data?.map((el) => ({
                                                label: el.name,
                                                value: el.id
                                            }))}
                                            label="Nogironligi *"
                                            placeholder={t('select')}
                                        />
                                    </div>
                                    <div className="col-4 col-sm-6 mt_10 col-md-4">
                                        <FastField
                                            name="responsibleMedWorkers"
                                            component={Fields.InputText}
                                            label="Masul tibbiy xodimalar F.I.SH.*"
                                            // placeholder="Яшаш жойи"
                                        />
                                    </div>
                                    <div className='col-4 col-md-6 col-xl-4 col-lg-4 mt_10'>
                                        <p className="mb_20 control__label "
                                           style={{fontSize: 14 + 'px'}}>Jarohatlar</p>
                                        <div className="d-flex">
                          <span>
                            <FastField
                                name="isInjured"
                                component={Fields.RadioButton}
                                label={t('yes')}
                                value={t('yes')}
                            />
                          </span>
                                            <span className='ml_10'>
                            <FastField
                                name="isInjured"
                                component={Fields.RadioButton}
                                label={t('no')}
                                value={t('no')}
                            />
                       </span>
                                        </div>
                                    </div>

                                    {values.isInjured === t('yes')&&<div className="col-4 col-sm-6 mt_10 col-md-4">
                                        <FastField
                                            name="additionalNotes"
                                            component={Fields.Textarea}
                                            type={''}
                                            label="Qo'shimcha qaydlar"
                                            // placeholder="Яшаш жойи"
                                        />
                                    </div>}


                                </div>
                                {/*<span className='time_now'>{dayjs().format('DD.MM.YYYY')}</span>*/}
                                {/*<span className='time_now'>{dayjs().format('HH:mm')}</span>*/}
                            </div>
                        </div>
                        <div className='d-flex justify-content-end'>
                            <Button
                                design="cancel"
                                className="modal-btn-end fz_16 btn mt_40"
                                text={t('cansel')}
                                onClick={() => navLink(-1)}
                                isLoading={isSubmitting}
                            /> <Button
                            design="primary"
                            type="submit"
                            className="modal-btn-end fz_16 btn mt_40"
                            text={get(values, "id") ? t('updata') :t('save')}
                            isLoading={isSubmitting}
                        />
                        </div>
                    </>
                }

                }
            </Containers.Form>
        </>
    );
};

