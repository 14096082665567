import React from "react";
import { FastField } from "formik";
import { get } from "lodash";

import Containers from "containers";
import {
  ModalDefault,
  Fields,
  Button,
  FileUpload,
  AvatarUpload,
  AttachFile,
} from "components";
import { UploadBase } from "components/Upload/UploadBase";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

export const ApplicationModal = ({
  isOpen,
  handleOverlayClose,
  values,
  refetch,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const filters = {};
  if (userData.region) {
    filters["region"] = userData.region.id;
  }
  if (userData.vsx) {
    filters["vsx"] = userData.vsx.id;
  }
  return (
    <ModalDefault
      isOpen={isOpen}
      handleModalClose={handleOverlayClose}
      title={t("application")}
      innerClass="max-width_500">
      <Containers.Form
        method={"post"}
        customData={{ prisoner: Number(id) }}
        url="/prisoner-applications"
        onSuccess={() => {
          handleOverlayClose();
          refetch();
        }}
        fields={[
          {
            name: "organization",
            validations: [{ type: "required" }],
            value: get(values, "nationality") ?? "",
            onSubmitValue: (value) => value,
          },
          {
            name: "number",
            validations: [{ type: "required" }],
            value: get(values, "number") ?? "",
            onSubmitValue: (value) => "" + value,
          },
          {
            name: "time",
            validations: [{ type: "required" }],
            // value:get(values, 'responsibleUser.data.id') ?  {label: get(values, 'responsibleUser.data.attributes.username'),value:get(values, 'responsibleUser.data.id')} : '',
            onSubmitValue: (value) => {
              return value;
            },
          },
          {
            name: "document",
            value: get(values, "document") ?? "",
            onSubmitValue: (value) => {
              if (!value) return null;
              return value;
            },
          },
        ]}>
        {({ isSubmitting, values, ...rest }) => {
          return (
            <>
              <div className="row g-4">
                <div className="col-12">
                  <FastField
                    name="organization"
                    component={Fields.InputText}
                    label={t("application-organ-name")}
                    placeholder={t("application-organ-name")}
                  />
                </div>
                <div className="col-12">
                  <FastField
                    name="number"
                    component={Fields.InputText}
                    label={t("out-number")}
                    placeholder={t("out-number")}
                  />
                </div>
                <div className="col-12">
                  <FastField
                    name="time"
                    component={Fields.DatePicker}
                    label={t("application-date")}
                    placeholder={t("application-date")}
                  />
                </div>
                <div className="col-12">
                  <h2 className="search_label">{t("application-file")}</h2>
                  <div className="file-upload">
                    <FastField
                      name="document"
                      title={t("file")}
                      component={AttachFile}
                      label={t("minutes")}
                    />
                  </div>
                </div>
              </div>

              <Button
                design="primary"
                type="submit"
                className="modal-btn fz_16 btn mt_40 w_full"
                text={t("save")}
                isLoading={isSubmitting}
              />
            </>
          );
        }}
      </Containers.Form>
    </ModalDefault>
  );
};
