import UserInfo from "../../VSX/components/userInfo";
import React from "react";

export const medicalInfoF = (data) => {
  const item = data;
  const list = [
    {
      userName: item?.person?.firstName,
      key: t("fullname"),
      icon: "user",
    },
    {
      userName: item?.person?.sureName,
      key: t("sure-name"),
      icon: "user",
    },
    {
      userName: item?.person?.middleName,
      key: t("middle-name"),
      icon: "user",
    },
    {
      userName: item?.person?.birthAddress,
      key: t("birthplace"),
      icon: "user",
    },
    {
      userName: item?.person?.birthdate,
      key: t("birthdate"),
      icon: "user",
    },
    {
      userName: item?.person?.gender?.name,
      key: t("gender"),
      icon: "birthday",
    },
    {
      userName: item?.person?.nationality?.name,
      key: t("nationality"),
      icon: "gendor",
    },
    {
      userName: item?.person?.nationality?.name,
      key: t("citizenship"),
      icon: "passpor",
    },
    {
      userName: item?.person?.passport,
      key: t("passport"),
      icon: "passpor",
    },
    {
      userName: "--",
      key: "ПИНФЛ",
      icon: "passpor",
    },
  ];
  return (
    <div className="col-3 col-md-6 col-sm-12 col-xl-4">
      <UserInfo list={list} />
    </div>
  );
};
