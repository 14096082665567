import React, { useState } from "react";
import { FastField } from "formik";
import { get, isFunction } from "lodash";

import { time, utils } from "services";

import Containers from "containers";
import {
  ModalDefault,
  Fields,
  Button,
  Typography,
  FileUpload,
} from "components";
import { useTranslation } from "react-i18next";
import { ReturnForms } from "./camera-forms";
import { FetchAsyncSelect } from "../../../components/Fields";

export const OutSideModal = ({
  isOpen,
  handleModalClose,
  onSuccess,
  values,
  customData,
  refetch,
  isTitle,
  statusItem,
}) => {
  const { t } = useTranslation();
  // console.log(statusItem)
  const handleSuccess = () => {
    onSuccess();
    handleModalClose();
    refetch();
  };
  const handleClose = () => {
    handleModalClose();
    onSuccess();
  };
  return (
    <ModalDefault
      isOpen={isOpen}
      handleModalClose={() => {
        handleClose();
      }}
      title={statusItem?.name}
      innerClass="max-width_500">
      <div className="mt_20">
        {statusItem &&
          ReturnForms({
            id: statusItem?.id,
            title: { name: statusItem?.name },
            customData: { ...customData, reason: statusItem?.id },
            method: handleSuccess,
            isTitle: isTitle,
          })}
      </div>
    </ModalDefault>
  );
};
