import React, { useEffect, useRef } from 'react';
import mp3 from '../../assets/mp3/audio.mp3'
const AudioPlayer = () => {
    const audioRef = useRef(null);
    // video.setAttribute('controls')
    // video.setAttribute('autoplay')
    const play = () => {
        const playAudio = async () => {
            const audio = new Audio(mp3);
            try {
                await audio.play();
            } catch (error) {
                console.error('Error playing audio:', error.message);
            }
        };
        useEffect(() => {

            playAudio();
        }, []); // Empty dependency array ensures the effect runs only once after the initial render

        return
    };
}
export default AudioPlayer;
