import React, { useContext } from "react";
import { get, isFunction } from "lodash";

import { InputBase } from "./InputBase";
import { useDarkMode } from "context/DarkModeContext";

export const InputText = ({
  placeholder = "",
  type = "",
  label = "",
  isDisabled = false,
  size = "sm",
  outerClass = "",
  innerClass = "",
  append,
  prepend,
  field,
  form,
  onKeyPress,
  autoComplete,
  value,
  isValid = () => true,
  ...inputProps
}) => {
  const darkMode = useDarkMode();
  return (
    <InputBase
      label={label}
      isDisabled={isDisabled}
      size={size}
      outerClass={outerClass}
      innerClass={innerClass}
      append={append}
      prepend={prepend}
      field={field}
      form={form}>
      <input
        type={type}
        disabled={isDisabled}
        placeholder={placeholder}
        value={value}
        autoComplete={autoComplete}
        onKeyPress={onKeyPress}
        className="control__input"
        {...field}
        {...inputProps}
        onChange={(event) => {
          if (isValid(event)) {
            isFunction(get(inputProps, "onChange")) &&
              inputProps.onChange(event);
            field.onChange(event);
          }
        }}
      />
    </InputBase>
  );
};
