import React from 'react';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Containers from "../../../../containers";
import {get} from "lodash";
import {time} from "../../../../services";
import {FastField} from "formik";
import {AttachFile, Button, Fields} from "../../../../components";
import dayjs from "dayjs";

function FormSpecially(props) {
    const navLink = useNavigate()
    const {values, customData, method, title} = props;
    const {t} = useTranslation()
    const userData = JSON.parse(localStorage.getItem('userData'))
    const filters={
    }
    if(userData.region) {
        filters['region'] =userData.region.id
    }
    if(userData.vsx) {
        filters['vsx'] =userData.vsx.id
    }
    return (
        <div>
            <Containers.Form
                customData={customData}
                validate={(event) => {
                    // console.log(event)
                }}
                method={"post"}
                url={"/prisoner/leave-room"}
                onSuccess={({data}) => {
                    method()
                }}
                onError={() => {
                    method()
                }}
                fields={[
                    {
                        name: "time",
                        // validations: [{type: "required"}],
                        value: get(values, 'birthdate'),
                        onSubmitValue: (value) => dayjs().format('YYYY-MM-DD HH:mm'),
                    }, {
                        name: "medicalDiagnosis",
                        validations: [{type: "required"}],
                        value: get(values, 'medicalDiagnosis'),
                        onSubmitValue: (value) => value,
                    },
                    {
                        name: "worker",
                        validations: [{type: "required"}],
                        value: get(values, 'worker'),
                        onSubmitValue: (value) => value,
                    },
                    // {
                    //     name: "injures",
                    //     value: get(values, 'injures'),
                    //     onSubmitValue: (value) => value,
                    // },
                    // {
                    //     name: "injuresFile",
                    //     value: get(values, 'injures'),
                    //     onSubmitValue: (value) => {
                    //         if(value) return value;
                    //         return  null
                    //     },
                    // },
                    // {
                    //     name: "isInjured",
                    //     // validations: [{type: "required"}],
                    //     value: `${get(values, 'isLGBT')}` ?? false,
                    //     onSubmitValue: (value) => {
                    //         // console.log(value)
                    //         const _item = value === 'Ha' ? true : false
                    //         return _item;
                    //     }
                    // },
                    {
                        name: "responsibleOfficer",
                        // validations: [{type: "required"}],
                        validationType: "object",
                        value: get(values, 'nationality') ?? '',
                        onSubmitValue: (value) => userData.id,
                    },
                ]}

            >
                {({isSubmitting, values, setFieldValue, ...rest}) => {
                    console.log(values, rest)
                    return <>
                        <div className="row g-4">
                            <div className="col-12">
                                <div className="row g-4">

                                    {/*birthdate*/}
                                    {/*<div className="col-6 col-sm-12 col-md-12 col-xl-6 col-lg-6">*/}
                                    {/*    <FastField*/}
                                    {/*        name="responsibleOfficer"*/}
                                    {/*        component={Fields.AsyncSelect}*/}
                                    {/*        loadOptionsUrl={'/users'}*/}
                                    {/*        loadOptionsKey={(data) => data?.map((el) => ({*/}
                                    {/*            label: el.username,*/}
                                    {/*            value: el.id*/}
                                    {/*        }))}*/}
                                    {/*        loadOptionsParams={(searchText) => ({*/}
                                    {/*            filters: {...filters},*/}
                                    {/*        })}*/}
                                    {/*        label={t('check-employees')}*/}
                                    {/*        // placeholder="Миллати"*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    <div className="col-12">
                                        <FastField
                                            name="worker"
                                            component={Fields.InputText}
                                            label={t('medical-employees')}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <FastField
                                            name="medicalDiagnosis"
                                            component={Fields.Textarea}
                                            label={t('medical-employees-before')}
                                        />
                                    </div>
                                    {/*<div className="col-6 col-sm-12 col-md-12 col-xl-6 col-lg-6">*/}
                                    {/*    <FastField*/}
                                    {/*        name="time"*/}
                                    {/*        component={Fields.DatePicker}*/}
                                    {/*        hasTimeSelect*/}
                                    {/*        maskformat={'####-##-## ##:##'}*/}
                                    {/*        format={'YYYY-MM-DD HH:mm'}*/}
                                    {/*        label={t('maxsus')}*/}
                                    {/*        placeholder={t('seletect-date')}*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    {/*nationality*/}
                       {/*             <div className='col-6 col-sm-12 col-md-12 col-xl-6 col-lg-4 mt_10'>*/}
                       {/*                 <p className="mb_20 control__label "*/}
                       {/*                    style={{fontSize: 14 + 'px'}}>{t('injuries-has')}</p>*/}
                       {/*                 <div className="d-flex">*/}
                       {/*   <span>*/}
                       {/*     <FastField*/}
                       {/*         name="isInjured"*/}
                       {/*         component={Fields.RadioButton}*/}
                       {/*         label={t('yes')}*/}
                       {/*         value={t('yes')}*/}
                       {/*     />*/}
                       {/*   </span>*/}
                       {/*                     <span className='ml_10'>*/}
                       {/*     <FastField*/}
                       {/*         name="isInjured"*/}
                       {/*         component={Fields.RadioButton}*/}
                       {/*         label={t('no')}*/}
                       {/*         value={t('no')}*/}
                       {/*     />*/}
                       {/*</span>*/}
                       {/*                 </div>*/}
                       {/*             </div>*/}
                       {/*             {*/}
                       {/*                 values.isInjured === 'Ha' &&   <div className="col-6 col-sm-12 col-md-12 col-xl-6 col-lg-4">*/}
                       {/*                     <FastField*/}
                       {/*                         name="injures"*/}
                       {/*                         component={Fields.InputText}*/}
                       {/*                         label={t('injuries-has')}*/}
                       {/*                         // placeholder="Фамилия"*/}
                       {/*                     />*/}
                       {/*                 </div>*/}
                       {/*             }*/}
                       {/*             {  values.isInjured === 'Ha' &&   <div className="col-6 col-sm-12 col-md-12 col-xl-6 col-lg-4">*/}
                       {/*                 <h2 className='search_label'>{t('file')}</h2>*/}
                       {/*                 <div className='file-upload'>*/}
                       {/*                     <FastField*/}
                       {/*                         name="injuresFile"*/}
                       {/*                         title={t('file')}*/}
                       {/*                         component={AttachFile}*/}
                       {/*                         label={t('minutes')}*/}
                       {/*                     />*/}
                       {/*                 </div>*/}
                       {/*             </div>}*/}

                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <Button
                                design="primary"
                                type="submit"
                                className="modal-btn-end fz_16 btn mt_40 w_full"
                                text={title.name}
                                isLoading={isSubmitting}
                            />
                        </div>
                    </>
                }

                }

            </Containers.Form>
        </div>
    );
}
export default FormSpecially;
