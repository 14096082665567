import {lazy} from "react";
const Setting = lazy(() => import("./page/Setting"));
export const SettingRouter =[
    {
        id:15,
        path: "/settings",
        // element: <CashboxOrderSingle />,
        element: <><Setting/></>,
        roles: new Set(["Superadmin"])
    }
]
