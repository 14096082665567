import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Containers from "../../../../containers";
import { get } from "lodash";
import { time } from "../../../../services";
import { FastField } from "formik";
import { AttachFile, Button, Fields } from "../../../../components";
import dayjs from "dayjs";
function FormOther(props) {
  const navLink = useNavigate();
  const { values, customData, method, title } = props;
  const { t } = useTranslation();
  const userData = JSON.parse(localStorage.getItem('userData'))
  const filters={
  }
  if(userData.region) {
    filters['region'] =userData.region.id
  }
  if(userData.vsx) {
    filters['vsx'] =userData.vsx.id
  }
  return (
    <div>
      <Containers.Form
        customData={customData}
        validate={(event) => {
          // console.log(event);
        }}
        method={"post"}
        url={"/prisoner/leave-room"}
        onSuccess={({ data }) => {
          method();
        }}
        onError={() => {
          method();
        }}
        fields={[
          {
            name: "time",
            // validations: [{ type: "required" }],
            value: get(values, "birthdate"),
            onSubmitValue: (value) => dayjs(dayjs()).format('YYYY-MM-DD HH:mm')
          },

          {
            name: "responsibleOfficer",
            // validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "responsibleOfficer") ?? "",
            onSubmitValue: (value) => userData.id,
          },
          // {
          //   name: "investigatingOrganization",
          //   validations: [{ type: "required" }],
          //   validationType: "object",
          //   value: get(values, "investigatingOrganization") ?? "",
          //   onSubmitValue: (value) => value.value,
          // },
          // {
          //   name: "injures",
          //   value: get(values, 'injures'),
          //   onSubmitValue: (value) => {return value},
          // },
       //    {
       //      name: "injuresFile",
       //      value: get(values, 'injures'),
       //      onSubmitValue: (value) => {
       //        if (value) return value;
       //        return null
       //      }
       //    },
       // {
       //      name: "injures",
       //      value: get(values, 'injures'),
       //      onSubmitValue: (value) => {  return value}
       //    },
       //    {
       //      name: "isInjured",
       //      // validations: [{type: "required"}],
       //      value: `${get(values, 'isLGBT')}` ?? false,
       //      onSubmitValue: (value) => {
       //        // console.log(value)
       //        const _item = value === 'Ha' ? true : false
       //        return _item;
       //      }
       //    },
          {
            name: "worker",
            validations: [{ type: "required" }],
            value: get(values, "worker") ?? "",
            onSubmitValue: (value) => value,
          },
        ]}
      >
        {({ isSubmitting, values, setFieldValue, ...rest }) => {
          // console.log(values, rest);
          return (
            <>
              <div className="row g-4">
                <div className="col-12">
                  <div className="row g-4">
                    {/*birthdate*/}

                    {/*<div className="col-6 col-sm-12 col-md-12 col-xl-6 col-lg-6">*/}
                    {/*  <FastField*/}
                    {/*    name="responsibleOfficer"*/}
                    {/*    component={Fields.AsyncSelect}*/}
                    {/*    loadOptionsUrl={"/users"}*/}
                    {/*    loadOptionsParams={(searchText) => ({*/}
                    {/*      filters: {...filters},*/}
                    {/*    })}*/}
                    {/*    loadOptionsKey={(data) =>*/}
                    {/*      data?.map((el) => ({*/}
                    {/*        label: el.username,*/}
                    {/*        value: el.id,*/}
                    {/*      }))*/}
                    {/*    }*/}
                    {/*    label={t("inquest-out-employees")}*/}
                    {/*    placeholder={t("choose")}*/}
                    {/*  />*/}
                    {/*</div>*/}
                    {/*<div className="col-12">*/}
                    {/*  <FastField*/}
                    {/*    name="investigatingOrganization"*/}
                    {/*    component={Fields.AsyncSelect}*/}
                    {/*    loadOptionsUrl={"/investigating-organizations"}*/}
                    {/*    loadOptionsKey={(data) =>*/}
                    {/*      data?.data?.map((el) => ({*/}
                    {/*        label: el.name,*/}
                    {/*        value: el.id,*/}
                    {/*      }))*/}
                    {/*    }*/}
                    {/*    label={t("select-reason")}*/}
                    {/*    placeholder={t("select-reason")}*/}
                    {/*  />*/}
                    {/*</div>*/}
                    <div className="col-12">
                      <FastField
                        name="worker"
                        style={{height: 100+"px"}}
                        component={Fields.Textarea}
                        hasTimeSelect
                        label={t("reason")}
                        placeholder={t("reason")}
                      />
                    </div>
                    {/*<div className="col-6 col-sm-12 col-md-12 col-xl-6 col-lg-6">*/}
                    {/*  <FastField*/}
                    {/*    name="time"*/}
                    {/*    component={Fields.DatePicker}*/}
                    {/*    hasTimeSelect*/}
                    {/*    maskformat={"####-##-## ##:##"}*/}
                    {/*    format={"YYYY-MM-DD HH:mm"}*/}
                    {/*    label={t("inquest-out-time")}*/}
                    {/*    placeholder={t("inquest-out-time")}*/}
                    {/*  />*/}
                    {/*</div>*/}
                    {/*nationality*/}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  design="primary"
                  type="submit"
                  className="modal-btn-end fz_16 btn mt_40 w_full"
                  text={title.name}
                  isLoading={isSubmitting}
                />
              </div>
            </>
          );
        }}
      </Containers.Form>
    </div>
  );
}

export default FormOther;
