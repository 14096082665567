import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "../styles/main.scss";
import { httpClient } from "../../../services";
import { useNavigate, useParams } from "react-router-dom";
import { Button, TextResize } from "../../../components";
import { useNotification } from "../../../hooks";
import { ReactComponent as GiftB } from "../../../assets/icons/giftB.svg";
import { ReactComponent as GiftR } from "../../../assets/icons/giftR.svg";
import { ReactComponent as View } from "../../../assets/icons/view.svg";
import { isArray } from "lodash";
import { CheckBox } from "../../../components/Table/components/TableCheckBox/CheckBox/CheckBox";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
export const DrogDrop = (props) => {
  const {
    isNewPrsonerAdd,
    boardsList,
    refetch,
    draggable,
    boardItemRemove,
    boardItemUpdata,
    routerPush,
    checkList,
    onCheckList,
    onHeaderClick,
    role,
    getProducts,
  } = props;
  const nav = useNavigate();
  const IsInvalid = ({ item, link }) => {
    if (link && item?.hasExpiredVisit) {
      return (
        <div style={{ cursor: "pointer" }} onClick={() => getProducts(item)}>
          <GiftR />
        </div>
      );
    } else if (link && item?.hasNewVisit) {
      return (
        <div style={{ cursor: "pointer" }} onClick={() => getProducts(item)}>
          <GiftB />
        </div>
      );
    }
    return <div></div>;
  };
  const notif = useNotification();
  const { t } = useTranslation();
  const { pr_id } = useParams();
  const boardUpdata = async (boardPrisoner, e) => {
    try {
      if (e && e.stopPropagation) e.stopPropagation();
      return await httpClient.put(`/rooms/change-prisoner-room`, {
        ...boardPrisoner,
      });
    } catch (err) {
      const {
        response: {
          data: {
            error: { message },
          },
        },
      } = err;
      notif.error(message);
      throw new Error(err);
    }
  };

  const dragEndHandler = async (item) => {
    if (
      item &&
      item.destination &&
      item.source &&
      item.destination.droppableId !== item.source.droppableId
    ) {
      await boardUpdata({
        prisonerId: item.draggableId,
        fromRoomId: item.source.droppableId,
        toRoomId: item.destination.droppableId,
      });
      await refetch();
    }
  };
  const itemAddUser = async (board) => {
    // let _items = board.prisoners.map((el) => el.id);
    // _items.push(Number(pr_id));
    await httpClient
      .post("/rooms/connect-room-to-prisoner", {
        data: {
          prisonerId: Number(pr_id),
          roomId: board?.id,
        },
      })
      .then((res) => {
        refetch();
        nav(-1);
      })
      .catch((err) => {
        nav(`/prisoner`);
      });
  };
  return (
    <div className="row">
      <DragDropContext
        className="w_full"
        onDragEnd={(result) => dragEndHandler(result)}>
        <div className="row">
          {boardsList?.length &&
            boardsList?.map((board, index) => {
              return (
                <div
                  key={index}
                  className="col-sm-12 col-md-12 col-lg-5 col-xl-4">
                  <div className="board__title__wrapper">
                    <div className="board__title">{board.name}</div>
                    <div className="board__icon">
                      <span>{board?.prisonersStatus?.total}</span> -
                      <span>{board?.prisonersStatus?.in}</span> -
                      <span>{board?.prisonersStatus?.out}</span>
                    </div>
                  </div>
                  <Droppable
                    type="COLUMN"
                    droppableId={`${board.id}`}
                    index={index}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className="board">
                          {board?.prisoners?.map((item, key) => {
                            return (
                              !item.isLeftRoom && (
                                <Draggable
                                  isDragDisabled={draggable}
                                  draggableId={`${item.id}`}
                                  key={item.id}
                                  index={key}>
                                  {(provided) => (
                                    <div
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      ref={provided.innerRef}
                                      className="item">
                                      <span className="item__text">
                                        {isArray(checkList) && role && (
                                          <CheckBox
                                            row={item}
                                            isChecked={checkList?.includes(
                                              item?.id
                                            )}
                                            setItemChecked={onCheckList}
                                          />
                                        )}
                                        <TextResize
                                          style={{
                                            color: `${
                                              item?.person
                                                ?.hadDangerousItemsBefore ===
                                              true
                                                ? "red"
                                                : "var(--dashboard-number)"
                                            }`,
                                          }}
                                          text={
                                            item?.person?.sureName +
                                            "  " +
                                            item?.person?.firstName
                                          }
                                        />
                                        <span
                                          style={{
                                            display: "flex",
                                            gap: "10px",
                                          }}>
                                          <span
                                            className="item-view-icon"
                                            onClick={() => {
                                              // (item.isTopSecret) => {

                                              if (
                                                item.isTopSecret &&
                                                localStorage.getItem(
                                                  "roleName"
                                                ) === "VsxInspector"
                                              ) {
                                                toast.warning(
                                                  t("data-not-see")
                                                );
                                              } else {
                                                nav(
                                                  `/prisoner/detail/${item.id}`
                                                );
                                              }
                                            }}>
                                            <View />
                                          </span>
                                          {role && (
                                            <IsInvalid
                                              item={item}
                                              link={`/product`}
                                            />
                                          )}
                                        </span>
                                      </span>
                                    </div>
                                  )}
                                </Draggable>
                              )
                            );
                          })}
                        </div>
                      );
                    }}
                  </Droppable>
                  {isNewPrsonerAdd && (
                    <Button
                      className="bnt mt_20 btn__primary"
                      style={{ borderRadios: 12 + "px" }}
                      design={"primary"}
                      onClick={() => itemAddUser(board)}>
                      {t("add-camera")}
                    </Button>
                  )}
                </div>
              );
            })}
        </div>
      </DragDropContext>
    </div>
  );
};
