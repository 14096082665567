import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Containers from "../../../../containers";
import { get } from "lodash";
import { time } from "../../../../services";
import { ErrorMessage, FastField, Field, FieldArray } from "formik";
import { AttachFile, Button, Fields } from "../../../../components";
import dayjs from "dayjs";
import { ReactComponent as IconDelete } from "../../../../assets/icons/delete.svg";
const FieldArrays = ({ name, products, t }) => {
  // console.log(products, '===>>>>>')
  return (
    <FieldArray name={"convoyOfficer"}>
      {({ insert, remove, push }) => (
        <>
          {products.map((product, index, arr) => (
            <div className="row" key={index}>
              <div
                className="col-12 col-md-12 col-sm-12 mt_20 d-flex align-items-center "
                key={index}>
                {/*<div className="d-flex">*/}
                <div className="col-12">
                  <Field
                    name={`convoyOfficer.${index}.name`}
                    component={Fields.InputText}
                    label={t("san-user")}
                    placeholder={t("san-user")}
                  />
                </div>
                <div
                  style={{ width: 120 + "px" }}
                  className="col-4 w_full"></div>
              </div>
              <ErrorMessage
                name={`convoyOfficer.${index}.name`}
                component="span"
                className="field-error"
              />{" "}
              <br />
              <div className="ml_10 mt_10">
                {index + 1 === arr?.length && index !== 0 && (
                  <Button
                    className="color_primary-red product__btn"
                    onClick={() => remove(index)}>
                    <IconDelete />
                  </Button>
                )}
              </div>
            </div>
          ))}
          <Button
            className="w_30 mt_20 "
            style={{ padding: 0, borderRadius: 5 + "px" }}
            design={"primary"}
            onClick={() => push({ name: "" })}>
            +
          </Button>
        </>
      )}
    </FieldArray>
  );
};
function FormEtap(props) {
  const navLink = useNavigate();
  const { values, customData, method, title } = props;
  const { t } = useTranslation();
  const [stateList, setStateList] = useState(null);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const filters = {};
  if (userData.region) {
    filters["region"] = userData.region.id;
  }
  if (userData.vsx) {
    filters["vsx"] = userData.vsx.id;
  }
  const CustomSelect = ({ filters, url, label, name }) => {
    const [filter, setFilter] = useState(filters);
    useEffect(() => {
      // console.log('men qayta render b'
      // )
      setFilter(filters);
    }, [filters]);

    return (
      <>
        <div className="col-12">
          <FastField
            name={name}
            component={Fields.AsyncSelect}
            loadOptionsUrl={url}
            filters={filters}
            loadOptionsKey={(data) =>
              data?.data?.map((el) => ({
                label: el.name,
                value: el.id,
              }))
            }
            label={label}
            placeholder={t("select")}
          />
        </div>
      </>
    );
  };
  return (
    <div>
      <Containers.Form
        customData={customData}
        validate={(event) => {
          // console.log(event)
        }}
        method={"post"}
        url={"/prisoner/leave-room"}
        onSuccess={({ data }) => {
          method();
        }}
        onError={() => {
          method();
        }}
        fields={[
          {
            name: "time",
            // validations: [{type: "required"}],
            value: get(values, "birthdate"),
            onSubmitValue: (value) => dayjs(dayjs()).format("YYYY-MM-DD HH:mm"),
          },
          {
            name: "etapOrganization",
            // validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "etapOrganization") ?? "",
            onSubmitValue: (value) => value.value,
          },
          {
            name: "injures",
            value: get(values, "injures"),
            onSubmitValue: (value) => value,
          },
          {
            name: "etapInvestigatingPrison",
            validationType: "object",
            value: get(values, "injures"),
            onSubmitValue: (value) => {
              if (value) return value.value;
              return null;
            },
          },
          {
            name: "etapVsx",
            validationType: "object",
            value: get(values, "injures"),
            onSubmitValue: (value) => {
              if (value) return value.value;
              return null;
            },
          },
          {
            name: "injuresFile",
            validationType: "array",
            value: get(values, "injures"),
            onSubmitValue: (value) => {
              if (value) return value;
              return null;
            },
          },
          {
            name: "isInjured",
            // validations: [{type: "required"}],
            value: `${get(values, "isLGBT")}` ?? false,
            onSubmitValue: (value) => {
              // console.log(value)
              const _item = value === "Ha" ? true : false;
              return _item;
            },
          },
          {
            name: "convoyOfficer",
            validationType: "array",
            value: get(values, "worker"),
            onSubmitValue: (value) => value.map((el) => el.name),
          },
          {
            name: "treatment",
            // validations: [{type: "required"}],
            value: get(values, "treatment"),
            onSubmitValue: (value) => value,
          },
          {
            name: "responsibleOfficer",
            // validations: [{type: "required"}],
            validationType: "object",
            value: get(values, "nationality") ?? "",
            onSubmitValue: (value) => userData.id,
          },
          {
            name: "document",
            value: get(values, "worker") ?? "",
            onSubmitValue: (value) => {
              if (!value) return null;
              return value;
            },
          },
        ]}>
        {({ isSubmitting, values, setFieldValue, ...rest }) => {
          setStateList(values);
          // console.log(values, rest)
          return (
            <>
              <div className="row g-4">
                <div className="col-12">
                  <div className="row g-4">
                    {/*birthdate*/}
                    <div className="col-12">
                      <div className="d-flex">
                        <span>
                          <FastField
                            name="isEtap"
                            component={Fields.RadioButton}
                            label={t("ter-room")}
                            value={"Room"}
                          />
                        </span>
                        <span className="ml_10">
                          <FastField
                            name="isEtap"
                            component={Fields.RadioButton}
                            label={t("VSX")}
                            value={"VSX"}
                          />
                        </span>
                        <span className="ml_10">
                          <FastField
                            name="isEtap"
                            component={Fields.RadioButton}
                            label={t("compulsory-treatment")}
                            value={"treatment"}
                          />
                        </span>
                      </div>
                    </div>
                    {values.isEtap === "Room" && (
                      <CustomSelect
                        name={"etapInvestigatingPrison"}
                        url={`/investigating-prisons`}
                        label={t("ter-room")}
                      />
                    )}
                    {values.isEtap === "VSX" && (
                      <CustomSelect
                        name={"region"}
                        url={`/regions`}
                        label={t("region-name")}
                      />
                    )}
                    {values.region && values.isEtap === "VSX" && (
                      <CustomSelect
                        name={"etapVsx"}
                        url={`/vsxes`}
                        label={t("vsx")}
                        filters={{
                          region: values?.region?.value,
                          isSpecial: false,
                        }}
                      />
                    )}
                    {values.isEtap === "treatment" && (
                      <div className="col-12">
                        <FastField
                          name="treatment"
                          component={Fields.InputText}
                          label={t("compulsory-treatment")}
                        />
                      </div>
                    )}

                    {/*<div className="col-12">*/}
                    {/*    <FastField*/}
                    {/*        name="etapOrganization"*/}
                    {/*        component={Fields.AsyncSelect}*/}
                    {/*        loadOptionsUrl={'/prisoner-etap-organizations'}*/}
                    {/*        loadOptionsKey={(data) => data?.map((el) => ({*/}
                    {/*            label: el.username,*/}
                    {/*            value: el.id*/}
                    {/*        }))}*/}
                    {/*        loadOptionsParams={(searchText) => ({*/}
                    {/*            filters: {...filters},*/}
                    {/*        })}*/}
                    {/*        label={t('where-etap')}*/}
                    {/*        // placeholder="Миллати"*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div className="col-12">
                      <FieldArrays
                        t={t}
                        products={
                          values?.convoyOfficer?.length
                            ? values?.convoyOfficer
                            : [{ name: "" }]
                        }
                      />
                      {/*<FastField*/}
                      {/*    name="worker"*/}
                      {/*    component={Fields.InputText}*/}
                      {/*    label={t('con-employees')}*/}
                      {/*/>*/}
                    </div>
                    <div className="col-12">
                      <h2 className="search_label">{t("main-doc")}</h2>
                      <div className="file-upload">
                        <FastField
                          name="document"
                          title={t("file")}
                          component={AttachFile}
                          label={t("minutes")}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <p
                        className="mb_20 control__label "
                        style={{ fontSize: 14 + "px" }}>
                        {t("injuries-has")}
                      </p>
                      <div className="d-flex">
                        <span>
                          <FastField
                            name="isInjured"
                            component={Fields.RadioButton}
                            label={t("yes")}
                            value={t("yes")}
                          />
                        </span>
                        <span className="ml_10">
                          <FastField
                            name="isInjured"
                            component={Fields.RadioButton}
                            label={t("no")}
                            value={t("no")}
                          />
                        </span>
                      </div>
                    </div>
                    {values.isInjured === "Ha" && (
                      <div className="col-12">
                        <FastField
                          name="injures"
                          component={Fields.InputText}
                          label={t("injuries-has")}
                          // placeholder="Фамилия"
                        />
                      </div>
                    )}
                    {values.isInjured === "Ha" && (
                      <div className="col-12">
                        <h2 className="search_label">{t("file")}</h2>
                        <div className="file-upload">
                          <FastField
                            name="injuresFile"
                            title={t("file")}
                            isMultiple
                            component={AttachFile}
                            label={t("minutes")}
                          />
                        </div>
                      </div>
                    )}
                    {/*<div className="col-6 col-sm-12 col-md-12 col-xl-4 col-lg-4">*/}
                    {/*    <FastField*/}
                    {/*        name="time"*/}
                    {/*        component={Fields.DatePicker}*/}
                    {/*        hasTimeSelect*/}
                    {/*        maskformat={'####-##-## ##:##'}*/}
                    {/*        format={'YYYY-MM-DD HH:mm'}*/}
                    {/*        label={t('inquest-out-time')}*/}
                    {/*        placeholder={t('inquest-out-time')}*/}
                    {/*    />*/}
                    {/*</div>*/}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  design="primary"
                  type="submit"
                  className="modal-btn-end fz_16 btn mt_40 w_full"
                  text={title.name}
                  isLoading={isSubmitting}
                />
              </div>
            </>
          );
        }}
      </Containers.Form>
    </div>
  );
}

export default FormEtap;
