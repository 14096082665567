import { AttachFile, Button, ModalDefault } from "components";
import Containers from "containers";
import { FastField } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

function AppModal({ handleModalClose, isOpen }) {
  const { t } = useTranslation();
  return (
    <ModalDefault
      isOpen={isOpen}
      handleModalClose={handleModalClose}
      title={t("search-to")}
      innerClass="max-width_500">
      <div>
        <Containers.Form
          //   customData={{ prisoner: Number(id) }}
          validate={(event) => {}}
          method={"post"}
          url={"/searches"}
          onSuccess={({ data }) => {
            handleModalClose();
          }}
          onError={() => {
            handleModalClose();
          }}
          fields={[]}>
          {({ isSubmitting, values, setFieldValue, ...rest }) => {
            return (
              <>
                <div className="col-12 col-sm-12 col-md-12 col-xl-6 col-lg-6">
                  <h2 className="search_label">Bayonnoma</h2>
                  <div className="file-upload">
                    <FastField
                      name="document"
                      title={t("file")}
                      component={AttachFile}
                      label={t("minutes")}
                    />
                  </div>
                </div>
                <div className="col-12 mt_10 col-sm-12 col-md-12 col-xl-6 col-lg-6">
                  <h2 className="search_label">Qaror</h2>
                  <div className="file-upload">
                    <FastField
                      name="document"
                      title={t("file")}
                      component={AttachFile}
                      label={t("minutes")}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <Button
                    design="primary"
                    type="submit"
                    className="modal-btn-end fz_16 btn mt_40 w_full"
                    text={t("save")}
                    isLoading={isSubmitting}
                  />
                </div>
              </>
            );
          }}
        </Containers.Form>
      </div>
    </ModalDefault>
  );
}

export default AppModal;
