import { lazy } from "react";

const Prisoners = lazy(() => import("../Prisoners/page/Prisoners"));
const PrisonerDetail = lazy(() =>
  import("../Prisoners/sub-pages/Prisoner-detail")
);
const PrisonerForms = lazy(() =>
  import("../Prisoners/sub-pages/PrisonerForms")
);
const PrisonerMadicalForms = lazy(() =>
  import("../Prisoners/sub-pages/PrisonerMadicalForms")
);
export const PrisonersRouter = [
  {
    id: 2,
    path: "/prisoner",
    // element: <Debtors />,
    element: <Prisoners />,
    roles: new Set([
      "Superadmin",
      "VsxManager",
      "VsxInspector",
      "SuperDuperAdmin",
    ]),
  },

  {
    id: 3,
    path: "/prisoner/detail/:id",
    element: <PrisonerDetail />,
    roles: new Set([
      "Superadmin",
      "VsxManager",
      "VsxInspector",
      "SuperDuperAdmin",
    ]),
  },
  {
    id: 4,
    path: "/prisoner-add",
    element: <PrisonerForms />,
    roles: new Set([
      "Superadmin",
      "VsxManager",
      "VsxInspector",
      "SuperDuperAdmin",
    ]),
  },

  {
    id: 28,
    path: "/prisoner/medical/:pr_id/",
    element: <PrisonerMadicalForms />,
    roles: new Set([
      "Superadmin",
      "VsxManager",
      "VsxInspector",
      "SuperDuperAdmin",
    ]),
  },
];
