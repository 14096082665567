import React from "react";
import { useParams } from "react-router-dom";
import {
  Camera,
  Download,
  FilePlus,
  Home,
  User,
  UserPlus,
  Users,
  RefreshCw,
  CreditCard,
  UserCheck,
  BookOpen,
  CheckCircle,
  Inbox,
  Clock,
  Volume2,
  Flag,
  Printer,
  FolderMinus,
  Folder,
} from "react-feather";

import { useTranslation } from "react-i18next";
import { useVsxMqMode } from "context/VsxMqContext";

export const SidbarMenu = (name) => {
  const params = useParams();
  const { t } = useTranslation();
  const { vsxMode, toggleVsxMq } = useVsxMqMode();

  // 1, 9, 8, 2, 15

  const _menu = {
    tashkent: [
      {
        id: "employDashboard",
        link: "/dashboard",
        linkId: 1,
        label: t("dashboard-employee"),
        icon: <Home className="mr_10" />,
        roles: new Set([
          "RegionalManager",
          "Superadmin",
          "VsxInspector",
          "VsxManager",
          "SuperDuperAdmin",
          "SpecialVsxManager",
        ]),
      },
      {
        id: "cameras",
        // link: "/tashkent/cameras",?
        linkId: 2,
        label: t("VSX-camera-list-take"),
        icon: <Camera className="mr_10" />,
        link: `/cameras`,
        roles: new Set([
          "Superadmin",
          "VsxManager",
          "RegionalManager",
          "VsxInspector",
          "SuperDuperAdmin",
          "SpecialVsxInspector",
          "SpecialVsxManager",
        ]),
      },
      {
        id: "prisoners-add",
        // link: "/tashkent/prisoner",
        link: `/prisoner-add`,
        linkId: 3,
        label: t("prisoners-list-add"),
        icon: <UserPlus className="mr_10" />,
        roles: new Set([
          "VsxInspector",
          "SuperDuperAdmin",
          "SpecialVsxInspector",
        ]),
      },
      {
        id: "prisoners",
        link: `/prisoner`,
        linkId: 4,
        label: t("saqlanayotgan shaxs"),
        icon: <Users className="mr_10" />,
        submenu: [
          {
            id: "prisoners",
            link: `/prisoner`,
            linkId: 4,
            label: t("prisoners-list-get"),
            icon: <Users className="mr_10" />,
            roles: new Set([
              "Superadmin",
              "VsxManager",
              "RegionalManager",
              "VsxInspector",
              "SuperDuperAdmin",
              "SpecialVsxInspector",
              "SpecialVsxManager",
            ]),
          },
          {
            id: "prisoners-camera",
            link: `/prisoner-camera`,
            linkId: 5,
            label: t("camera-inspector"),
            icon: <Users className="mr_10" />,
            roles: new Set([
              "Superadmin",
              "VsxManager",
              "RegionalManager",
              "VsxInspector",
              "SuperDuperAdmin",
              "SpecialVsxInspector",
              "SpecialVsxManager",
            ]),
          },
          {
            id: "prisoners-add-plus-list",
            // link: "/tashkent/prisoner",
            link: `/prisoner-add-topSecret-list`,
            linkId: 7,
            label: t("prisoners-new-list-add"),
            icon: <UserPlus className="mr_10" />,
            roles: new Set([
              "SuperDuperAdmin",
              "VsxManager",
              "VsxInspector",
              "SpecialVsxInspector",
              "SpecialVsxManager",
            ]),
          },
          {
            id: "prisoners-add-plus",
            // link: "/tashkent/prisoner",
            link: `/prisoner-add-topSecret`,
            linkId: 6,
            label: t("prisoners-new-list-top"),
            icon: <UserPlus className="mr_10" />,
            roles: new Set([
              "RegionalManager",
              "SuperDuperAdmin",
              "VsxManager",
              "SpecialVsxManager",
            ]),
          },
          // {
          //   id: "prisoner-add-tablet-list",
          //   // link: "/tashkent/prisoner",
          //   linkId: 8,
          //   link: `/prisoner-add-tablet-list`,
          //   label: t("prisoner-add-tablet-list"),
          //   icon: <UserPlus className="mr_10" />,
          //   roles: new Set([
          //     "SuperDuperAdmin",
          //     "VsxManager",
          //     "VsxInspector",
          //     "SpecialVsxInspector",
          //     "SpecialVsxManager",
          //   ]),
          // },
        ],
        roles: new Set([
          "Superadmin",
          "VsxManager",
          "RegionalManager",
          "VsxInspector",
          "SuperDuperAdmin",
          "SpecialVsxInspector",
          "SpecialVsxManager",
        ]),
      },
      {
        id: "product",
        // link: "/tashkent/visitors",
        link: `/product`,
        linkId: 9,
        label: t("person-visitor-product"),
        icon: <RefreshCw className="mr_10" />,
        roles: new Set(["VsxInspector", "SuperDuperAdmin", "VsxManager"]),
      },
      {
        id: "employees",
        // link: "/tashkent/employees",
        linkId: 10,
        link: `/employees`,
        label: t("Xodimlar"),
        icon: <UserPlus className="mr_10" />,
        roles: new Set([
          "Superadmin",
          "RegionalManager",
          "VsxManager",
          "SuperDuperAdmin",
          "SpecialVsxManager",
        ]),
        submenu: [
          {
            id: "employees",
            // link: "/tashkent/employees",
            linkId: 10,
            link: `/employees`,
            label: t("employees-list-get"),
            icon: <UserPlus className="" />,
            roles: new Set([
              "Superadmin",
              "RegionalManager",
              "VsxManager",
              "SuperDuperAdmin",
              "SpecialVsxManager",
            ]),
          },
          {
            id: "employees-history",
            // link: "/tashkent/employees",
            linkId: 10,
            link: `/employees-history`,
            label: t("Xodimlar harakati tarixi"),
            icon: <UserPlus className="" />,
            roles: new Set(["Superadmin"]),
          },
        ],
      },
      {
        id: "vsx",
        // link: "/tashkent/vsx",
        label: t("VSX-list"),
        linkId: 11,
        link: `/vsx`,
        icon: <FilePlus className="mr_10" />,
        roles: new Set([
          "Superadmin",
          "SuperDuperAdmin",
          "RegionalManager",
          "SpecialVsxManager",
        ]),
      },
      {
        id: "emergency",
        // link: "/tashkent/vsx",
        label: t("emergency"),
        linkId: 12,
        link: `/emergency`,
        icon: <Flag className="mr_10" />,
        roles: new Set([
          "SuperDuperAdmin",
          "VsxManager",
          "VsxInspector",
          "SpecialVsxInspector",
          "SpecialVsxManager",
        ]),
      },
      {
        id: "mx-text-list",
        // link: "/tashkent/vsx",
        linkId: 13,
        label: t("mx-text-list"),
        link: `/specialnotice-list`,
        icon: <Inbox className="mr_10" />,
        roles: new Set([
          "SuperDuperAdmin",
          "Superadmin",
          "RegionalManager",
          "VsxManager",
          "SpecialVsxInspector",
          "SpecialVsxManager",
        ]),
      },

      {
        id: "sanitation",
        // link: "/tashkent/cameras",?
        label: t("sanitation"),
        linkId: 14,
        icon: <UserCheck className="mr_10" />,
        link: `/sanitation`,
        roles: new Set([
          "VsxInspector",
          "SuperDuperAdmin",
          "SpecialVsxInspector",
          "SpecialVsxManager",
          "SpecialVsxManager",
        ]),
      },
      {
        id: "inspection",
        label: t("inspection"),
        linkId: 15,
        icon: <CheckCircle className="mr_10" />,
        link: `/inspection`,
        roles: new Set([
          "VsxManager",
          "VsxInspector",
          "SuperDuperAdmin",
          "SpecialVsxInspector",
          "SpecialVsxManager",
        ]),
      },

      {
        id: "reports",
        // link: "/tashkent/cameras",?
        label: t("reports"),
        linkId: 16,
        icon: <BookOpen className="mr_10" />,
        submenu: [
          {
            id: "reports-id-list",
            icon: <Clock className="mr_10" />,
            linkId: 17,
            label: t("reports-vsx-month"),
            link: `/reports`,
            roles: new Set([
              "Superadmin",
              "VsxManager",
              "RegionalManager",
              "SuperDuperAdmin",
              "SpecialVsxManager",
            ]),
          },
          {
            id: "report-mq",
            label: t("reports-mq"),
            linkId: 18,
            icon: <CreditCard className="mr_10" />,
            link: `/reports-mq`,
            roles: new Set([
              "Superadmin",
              "VsxManager",
              "RegionalManager",
              "SuperDuperAdmin",
              "SpecialVsxManager",
            ]),
          },
          {
            id: "reports-vsx",
            label: t("reports-vsx"),
            icon: <Printer className="mr_10" />,
            linkId: 19,
            link: `/reports-vsx`,
            roles: new Set([
              "Superadmin",
              "VsxManager",
              "RegionalManager",
              "SuperDuperAdmin",
              "SpecialVsxManager",
            ]),
          },
          {
            id: "reports-state",
            label: t("reports-state"),
            linkId: 20,
            icon: <FolderMinus className="mr_10" />,
            link: `/reports-state`,
            roles: new Set([
              "Superadmin",
              "VsxManager",
              "RegionalManager",
              "SuperDuperAdmin",
              "SpecialVsxManager",
            ]),
          },
          {
            id: "reports-temporary",
            label: t("reports-fh"),
            linkId: 21,
            link: `/reports-fh`,
            icon: <Folder className="mr_10" />,
            roles: new Set([
              "Superadmin",
              "VsxManager",
              "SuperDuperAdmin",
              "SpecialVsxManager",
            ]),
          },
          {
            id: "food-report",
            label: t("food-report"),
            linkId: 25,
            link: `/food-report`,
            icon: <Folder className="mr_10" />,
            roles: new Set([
              "Superadmin",
              "VsxManager",
              "SuperDuperAdmin",
              "SpecialVsxManager",
            ]),
          },
          {
            id: "jqb-report",
            label: t("jqb-report"),
            linkId: 26,
            link: `/jqb-report`,
            icon: <Folder className="mr_10" />,
            roles: new Set([
              "Superadmin",
              "VsxManager",
              "SuperDuperAdmin",
              "SpecialVsxManager",
            ]),
          },
          {
            id: "hand-report",
            label: t("hand-report"),
            linkId: 27,
            link: `/hand-report`,
            icon: <Folder className="mr_10" />,
            roles: new Set([
              "Superadmin",
              "VsxManager",
              "SuperDuperAdmin",
              "SpecialVsxManager",
            ]),
          },
        ],
        roles: new Set([
          "Superadmin",
          "RegionalManager",
          "SuperDuperAdmin",
          "SpecialVsxManager",
        ]),
      },
      {
        id: "arxive",
        // link: "/tashkent/archive",
        linkId: 22,
        link: `/archive`,
        label: t("VSX-archive"),
        icon: <Download className="mr_10" />,
        roles: new Set([
          "Superadmin",
          "RegionalManager",
          "VsxManager",
          "SuperDuperAdmin",
          "VsxInspector",
          "SpecialVsxInspector",
          "SpecialVsxManager",
        ]),
      },

      // {
      //   id: "settings",
      //   // link: "/tashkent/",
      //   linkId: 23,
      //   link: `/settings`,
      //   label: t("settings"),
      //   icon: <Settings className="mr_10" />,
      //   roles: new Set([
      //     "Superadmin",
      //     "RegionalManager",
      //     "VsxManager",
      //     "SuperDuperAdmin",
      //     "SpecialVsxManager",
      //   ]),
      // },
      // {
      //   id: "mq-arxiv",
      //   // link: "/tashkent/",
      //   link: `/mq-arxiv`,
      //   linkId: 24,
      //   label: t("mq-arxiv"),
      //   icon: <Settings className="mr_10" />,
      //   roles: new Set([
      //     "Superadmin",
      //     "RegionalManager",
      //     "SuperDuperAdmin",
      //     "SpecialVsxManager",
      //   ]),
      // },
    ],
  };

  const _menuMq = {
    tashkent: [
      {
        id: "employDashboard",
        link: "/dashboard",
        linkId: 1,
        label: t("dashboard-employee"),
        icon: <Home className="mr_10" />,
        roles: new Set([
          "RegionalManager",
          "Superadmin",
          "VsxInspector",
          "VsxManager",
          "SuperDuperAdmin",
          "SpecialVsxManager",
        ]),
      },
      {
        id: "cameras",
        // link: "/tashkent/cameras",?
        linkId: 2,
        label: t("VSX-camera-list-take"),
        icon: <Camera className="mr_10" />,
        link: `/cameras`,
        roles: new Set([
          "Superadmin",
          "VsxManager",
          "RegionalManager",
          "VsxInspector",
          "SuperDuperAdmin",
          "SpecialVsxInspector",
          "SpecialVsxManager",
        ]),
      },
      {
        id: "prisoners-add",
        // link: "/tashkent/prisoner",
        link: `/prisoner-add`,
        linkId: 3,
        label: t("prisoners-list-add"),
        icon: <UserPlus className="mr_10" />,
        roles: new Set([
          "VsxInspector",
          "SuperDuperAdmin",
          "SpecialVsxInspector",
        ]),
      },
      {
        id: "prisoners",
        link: `/prisoner`,
        linkId: 4,
        label: t("saqlanayotgan shaxs"),
        icon: <Users className="mr_10" />,
        submenu: [
          {
            id: "prisoners",
            link: `/prisoner`,
            linkId: 4,
            label: t("prisoners-list-get"),
            icon: <Users className="mr_10" />,
            roles: new Set([
              "Superadmin",
              "VsxManager",
              "RegionalManager",
              "VsxInspector",
              "SuperDuperAdmin",
              "SpecialVsxInspector",
              "SpecialVsxManager",
            ]),
          },
          {
            id: "prisoners-camera",
            link: `/prisoner-camera`,
            linkId: 5,
            label: t("camera-inspector"),
            icon: <Users className="mr_10" />,
            roles: new Set([
              "Superadmin",
              "VsxManager",
              "RegionalManager",
              "VsxInspector",
              "SuperDuperAdmin",
              "SpecialVsxInspector",
              "SpecialVsxManager",
            ]),
          },
          {
            id: "prisoners-add-plus-list",
            // link: "/tashkent/prisoner",
            link: `/prisoner-add-topSecret-list`,
            linkId: 7,
            label: t("prisoners-new-list-add"),
            icon: <UserPlus className="mr_10" />,
            roles: new Set([
              "SuperDuperAdmin",
              "VsxManager",
              "VsxInspector",
              "SpecialVsxInspector",
              "SpecialVsxManager",
            ]),
          },
          {
            id: "prisoners-add-plus",
            // link: "/tashkent/prisoner",
            link: `/prisoner-add-topSecret`,
            linkId: 6,
            label: t("prisoners-new-list-top"),
            icon: <UserPlus className="mr_10" />,
            roles: new Set([
              "RegionalManager",
              "SuperDuperAdmin",
              "VsxManager",
              "SpecialVsxManager",
            ]),
          },
          // {
          //   id: "prisoner-add-tablet-list",
          //   // link: "/tashkent/prisoner",
          //   linkId: 8,
          //   link: `/prisoner-add-tablet-list`,
          //   label: t("prisoner-add-tablet-list"),
          //   icon: <UserPlus className="mr_10" />,
          //   roles: new Set([
          //     "SuperDuperAdmin",
          //     "VsxManager",
          //     "VsxInspector",
          //     "SpecialVsxInspector",
          //     "SpecialVsxManager",
          //   ]),
          // },
        ],
        roles: new Set([
          "Superadmin",
          "VsxManager",
          "RegionalManager",
          "VsxInspector",
          "SuperDuperAdmin",
          "SpecialVsxInspector",
          "SpecialVsxManager",
        ]),
      },
      {
        id: "product",
        // link: "/tashkent/visitors",
        link: `/product`,
        linkId: 9,
        label: t("person-visitor-product"),
        icon: <RefreshCw className="mr_10" />,
        roles: new Set(["VsxInspector", "SuperDuperAdmin", "VsxManager"]),
      },
      {
        id: "employees",
        linkId: 10,
        link: `/employees`,
        label: t("Xodimlar"),
        icon: <UserPlus className="mr_10" />,
        roles: new Set([
          "Superadmin",
          "RegionalManager",
          "VsxManager",
          "SuperDuperAdmin",
          "SpecialVsxManager",
        ]),
        submenu: [
          {
            id: "employees",
            linkId: 10,
            link: `/employees`,
            label: t("employees-list-get"),
            icon: <UserPlus className="" />,
            roles: new Set([
              "Superadmin",
              "RegionalManager",
              "VsxManager",
              "SuperDuperAdmin",
              "SpecialVsxManager",
            ]),
          },
          {
            id: "employees-history",
            // link: "/tashkent/employees",
            linkId: 10,
            link: `/employees-history`,
            label: t("Xodimlar harakati tarixi"),
            icon: <UserPlus className="" />,
            roles: new Set(["Superadmin"]),
          },
        ],
      },
      {
        id: "vsx",
        // link: "/tashkent/vsx",
        label: t("VSX-list"),
        linkId: 11,
        link: `/vsx`,
        icon: <FilePlus className="mr_10" />,
        roles: new Set([
          "Superadmin",
          "SuperDuperAdmin",
          "RegionalManager",
          "SpecialVsxManager",
        ]),
      },
      {
        id: "emergency",
        // link: "/tashkent/vsx",
        label: t("emergency"),
        linkId: 12,
        link: `/emergency`,
        icon: <Flag className="mr_10" />,
        roles: new Set([
          "SuperDuperAdmin",
          "VsxManager",
          "VsxInspector",
          "SpecialVsxInspector",
          "SpecialVsxManager",
        ]),
      },
      {
        id: "mx-text-list",
        // link: "/tashkent/vsx",
        linkId: 13,
        label: t("mx-text-list"),
        link: `/specialnotice-list`,
        icon: <Inbox className="mr_10" />,
        roles: new Set([
          "SuperDuperAdmin",
          "Superadmin",
          "RegionalManager",
          "VsxManager",
          "SpecialVsxInspector",
          "SpecialVsxManager",
        ]),
      },

      {
        id: "sanitation",
        // link: "/tashkent/cameras",?
        label: t("sanitation"),
        linkId: 14,
        icon: <UserCheck className="mr_10" />,
        link: `/sanitation`,
        roles: new Set([
          "VsxInspector",
          "SuperDuperAdmin",
          "SpecialVsxInspector",
          "SpecialVsxManager",
        ]),
      },
      {
        id: "inspection",
        label: t("inspection"),
        linkId: 15,
        icon: <CheckCircle className="mr_10" />,
        link: `/inspection`,
        roles: new Set([
          "VsxManager",
          "VsxInspector",
          "SuperDuperAdmin",
          "SpecialVsxInspector",
          "SpecialVsxManager",
        ]),
      },

      {
        id: "reports",
        // link: "/tashkent/cameras",?
        label: t("reports"),
        linkId: 16,
        icon: <BookOpen className="mr_10" />,
        submenu: [
          {
            id: "reports-id-list",
            icon: <Clock className="mr_10" />,
            linkId: 17,
            label: t("reports-vsx-month"),
            link: `/reports`,
            roles: new Set([
              "Superadmin",
              "VsxManager",
              "RegionalManager",
              "SuperDuperAdmin",
              "SpecialVsxManager",
            ]),
          },
          {
            id: "reports-vsx",
            label: t("reports-vsx"),
            icon: <Printer className="mr_10" />,
            linkId: 19,
            link: `/reports-vsx`,
            roles: new Set([
              "Superadmin",
              "VsxManager",
              "RegionalManager",
              "SuperDuperAdmin",
              "SpecialVsxManager",
            ]),
          },
          {
            id: "reports-state",
            label: t("reports-state"),
            linkId: 20,
            icon: <FolderMinus className="mr_10" />,
            link: `/reports-state`,
            roles: new Set([
              "Superadmin",
              "VsxManager",
              "RegionalManager",
              "SuperDuperAdmin",
              "SpecialVsxManager",
            ]),
          },
          {
            id: "reports-temporary",
            label: t("reports-fh"),
            linkId: 21,
            link: `/reports-fh`,
            icon: <Folder className="mr_10" />,
            roles: new Set([
              "Superadmin",
              "VsxManager",
              "SuperDuperAdmin",
              "SpecialVsxManager",
            ]),
          },
          {
            id: "food-report",
            label: t("food-report"),
            linkId: 25,
            link: `/food-report`,
            icon: <Folder className="mr_10" />,
            roles: new Set([
              "Superadmin",
              "VsxManager",
              "SuperDuperAdmin",
              "SpecialVsxManager",
            ]),
          },
          {
            id: "jqb-report",
            label: t("jqb-report"),
            linkId: 26,
            link: `/jqb-report`,
            icon: <Folder className="mr_10" />,
            roles: new Set([
              "Superadmin",
              "VsxManager",
              "SuperDuperAdmin",
              "SpecialVsxManager",
            ]),
          },
          {
            id: "hand-report",
            label: t("hand-report"),
            linkId: 27,
            link: `/hand-report`,
            icon: <Folder className="mr_10" />,
            roles: new Set([
              "Superadmin",
              "VsxManager",
              "SuperDuperAdmin",
              "SpecialVsxManager",
            ]),
          },
        ],
        roles: new Set([
          "Superadmin",
          "RegionalManager",
          "SuperDuperAdmin",
          "SpecialVsxManager",
        ]),
      },
      {
        id: "mq-arxiv",
        // link: "/tashkent/",
        link: `/mq-arxiv`,
        linkId: 24,
        label: t("MQ arxivi"),
        icon: <Download className="mr_10" />,
        roles: new Set([
          "Superadmin",
          "RegionalManager",
          "SuperDuperAdmin",
          "SpecialVsxManager",
        ]),
      },
      // {
      //   id: "settings",
      //   // link: "/tashkent/",
      //   linkId: 23,
      //   link: `/settings`,
      //   label: t("settings"),
      //   icon: <Settings className="mr_10" />,
      //   roles: new Set([
      //     "Superadmin",
      //     "RegionalManager",
      //     "VsxManager",
      //     "SuperDuperAdmin",
      //     "SpecialVsxManager",
      //   ]),
      // },
    ],
  };

  return vsxMode === false ? _menu[name] : _menuMq[name];
};
