import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Containers from "../../../../containers";
import { get } from "lodash";
import { time } from "../../../../services";
import { FastField } from "formik";
import {
  AttachFile,
  Button,
  Fields,
  SearchStatus,
} from "../../../../components";
import dayjs from "dayjs";
import SearchAdvokat from "../SearchAdvokat";
import InformationModal from "../InformationModal";
import { useOverlay } from "hooks";
function FormAdvakat(props) {
  const urlList = process.env.REACT_APP_IMAGE_BASE_URL;
  const [openModal, setOpenModal] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [workers, setWorkers] = useState([]);
  const [advocat, setAdvocat] = useState([]);
  const { values, customData, method, title } = props;
  const [valuesList, setValueList] = useState(values);
  const { t } = useTranslation();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const filters = {};
  if (userData.region) {
    filters["region"] = userData.region.id;
  }
  if (userData.vsx) {
    filters["vsx"] = userData.vsx.id;
  }
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter" && inputValue !== "") {
      e?.preventDefault();
      const existingWorkerIndex = workers?.findIndex(
        (worker) => worker.name === inputValue
      );

      if (existingWorkerIndex !== -1) {
        setWorkers((prevWorkers) =>
          prevWorkers.map((worker, index) =>
            index === existingWorkerIndex
              ? { ...worker, isChecked: !worker.isChecked }
              : worker
          )
        );
      } else {
        setWorkers((prevWorkers) => [
          ...prevWorkers,
          { lawyerFirstName: inputValue, isChecked: false, lawyerLastName: "" },
        ]);
      }

      // Clear input value
      setInputValue(null);
    }
  };

  const handleButtonClick = () => {
    const syntheticEvent = { key: "Enter", preventDefault: () => {} };

    handleInputKeyPress(syntheticEvent);
  };

  return (
    <div>
      <InformationModal
        advocat={advocat}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <SearchAdvokat setWorkers={setWorkers} setAdvocat={setAdvocat} />
      <Containers.Form
        customData={customData}
        validate={(event) => {}}
        method={"post"}
        url={"/prisoner/leave-room"}
        onSuccess={({ data }) => {
          method();
        }}
        onError={() => {
          method();
        }}
        fields={[
          {
            name: "time",
            value: get(valuesList, "birthdate"),
            onSubmitValue: (value) => dayjs(dayjs()).format("YYYY-MM-DD HH:mm"),
          },
          {
            name: "delegates",
            value: get(valuesList, "delegates"),
            validations: [
              { type: workers?.length > 0 ? "typeError" : "required" },
            ],
            onSubmitValue: (value) => workers,
          },
          {
            name: "responsibleOfficer",
            validationType: "object",
            value: get(valuesList, "nationality") ?? "",
            onSubmitValue: (value) => userData.id,
          },
          {
            name: "document",
            value: get(valuesList, "worker") ?? "",
            onSubmitValue: (value) => {
              if (!value) return null;
              return value;
            },
          },
        ]}>
        {({ isSubmitting, values, setFieldValue, ...rest }) => {
          return (
            <>
              <div className="row g-4">
                <div className="col-12">
                  <div className="row g-4">
                    <div className="col-12 mt_30">
                      <div className="d-flex align-items-center">
                        <div style={{ width: "100%" }}>
                          <FastField
                            name="delegates"
                            component={Fields.InputText}
                            hasTimeSelect
                            style={{ width: "100%" }}
                            value={inputValue}
                            onKeyPress={handleInputKeyPress}
                            label={t("lawyer")}
                            onChange={handleInputChange}
                          />
                        </div>
                        <span
                          onClick={handleButtonClick}
                          style={{
                            fontSize: "18px",
                            background: "var(--stroke-input)",
                            padding: "3px 8px",
                            marginTop: "25px",
                            color: "#fff",
                            cursor: "pointer",
                          }}>
                          +
                        </span>
                      </div>
                      <ul>
                        {advocat?.length > 0
                          ? advocat?.map((evt, index) => (
                              <li
                                key={index}
                                className="d-flex mt_20"
                                style={{ gap: "10px 25px" }}>
                                <h2
                                  style={{ fontSize: "14px" }}
                                  className="search_label-name">
                                  {evt?.lawyerFirstName} {evt?.lawyerLastName}{" "}
                                </h2>
                                <a
                                  href={`${urlList}${evt?.file?.url}`}
                                  target="_blank"
                                  style={{
                                    fontSize: "12px",
                                    background: "var(--stroke-input)",
                                    width: "90px",
                                    height: "35px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#fff",
                                  }}>
                                  {t("Yuklab olish")}
                                </a>
                                <button
                                  onClick={(event) => {
                                    event.preventDefault();
                                    setOpenModal(true);
                                  }}
                                  style={{
                                    fontSize: "14px",
                                    background: "var(--stroke-input)",
                                    width: "70px",
                                    height: "35px",
                                    color: "#fff",
                                  }}>
                                  Info
                                </button>
                              </li>
                            ))
                          : ""}
                        {workers.length > 0
                          ? workers &&
                            workers?.map((worker, index) => (
                              <li
                                className="d-flex align-items-center advokat-menu-list"
                                key={index}>
                                <input
                                  type="checkbox"
                                  id={`employee-${index}`}
                                  className="advokat_input-checkbox"
                                  checked={worker?.isChecked}
                                  onChange={() => {
                                    setWorkers((prevWorkers) =>
                                      prevWorkers.map((prevWorker, prevIndex) =>
                                        prevIndex === index
                                          ? {
                                              ...prevWorker,
                                              isChecked: !prevWorker?.isChecked,
                                            }
                                          : prevWorker
                                      )
                                    );
                                  }}
                                />
                                <label
                                  htmlFor={`employee-${index}`}
                                  className="search_label-name">
                                  {worker.lawyerFirstName}{" "}
                                  {worker.lawyerLastName}
                                </label>
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                    <div className="col-12">
                      <h2 className="search_label">{t("order")}</h2>
                      <div className="file-upload">
                        <FastField
                          name="document"
                          title={t("file")}
                          component={AttachFile}
                          label={t("minutes")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  design="primary"
                  type="submit"
                  className="modal-btn-end fz_16 btn mt_40 w_full"
                  text={title.name}
                  isLoading={isSubmitting}
                />
              </div>
            </>
          );
        }}
      </Containers.Form>
    </div>
  );
}

export default FormAdvakat;
