import React, { useEffect, useState } from "react";
import get from "lodash";
import { httpClient } from "../../services";

export const DangerouslySetInnerHtml = ({ id, pdfRef }) => {
  const [htmlToString, setHtmlToString] = useState(null);
  const [loading, setLoading] = useState(false);
  const fetchList = async () => {
    try {
      const { data } = await httpClient.post(
        "/prisoner/generate-questionnaire-html",
        { prisoner: id }
      );
      setHtmlToString(data.data.html);
      return data?.data;
    } catch (err) {
      console.log(err);
    } finally {
      setLoading((old) => !old);
    }
    useEffect(() => {
      fetchList();
    }, []);

    return (
      <div>
        <div ref={pdfRef} dangerouslySetInnerHTML={{ __html: htmlToString }} />
      </div>
    );
  };
};
