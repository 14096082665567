import { useMemo, useState } from "react";
import {
  MRT_TableContainer,
  useMaterialReactTable,
} from "material-react-table";
import { useTranslation } from "react-i18next";

const Example = ({ cameraList, number }) => {
  const { t } = useTranslation();
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row?.name,
        header: t("room-name"),
      },
      {
        accessorFn: (row) => row?.capacity,
        header: t("room-size"),
      },
      {
        accessorFn: (row) => row?.vsx?.name,
        header: t("VSX-name"),
      },
      {
        header: t("responsible-employees"),
        accessorFn: (row) =>
          row?.responsibleUser?.firstName +
          " " +
          row?.responsibleUser?.sureName,
      },
    ],
    []
  );

  const [data, setData] = useState(() => cameraList || []);

  const table = useMaterialReactTable({
    autoResetPageIndex: false,
    columns,
    data,
    enableRowOrdering: true,
    enableSorting: false,
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          data?.splice(
            hoveredRow?.index,
            0,
            data?.splice(draggingRow?.index, 1)[0]
          );
          setData([...data]);
        }
      },
    }),
  });

  return <MRT_TableContainer table={table} />;
};

export default Example;
