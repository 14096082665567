import userimg from "../../../assets/images/user-default.png";
import React from "react";
export const Avatar = (src) =>
  src?.includes("data") ? (
    <img className="user__avatar" src={src} />
  ) : src ? (
    <img
      className="user__avatar"
      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${src}`}
    />
  ) : (
    <img className="user__avatar" src={userimg} />
  );
