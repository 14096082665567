import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
  isDesktop,
} from "react-device-detect";
import { AppLink, Avatar, Button, Typography } from "components";
import { SubMenu } from "./SubMenu";
import { SidbarMenu } from "../helpers/menu";
import { formatters } from "services/utils";

import userAva from "../../assets/images/user-default.png";
import { ReactComponent as ArrowLeft } from "assets/icons/arrow.svg";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import { useTranslation } from "react-i18next";
import { accessTableMenu } from "../../mock-data";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
export const Sidebar = ({ setMini, mini }) => {
  const navigate = useNavigate();
  const getWidth = () => {
    if (window.innerWidth < 992) {
      setMini(true);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", getWidth);
    return () => window.removeEventListener("resize", getWidth);
  }, []);

  const { pathname } = useLocation();
  const menuKey = pathname.split("/")[1];
  const roleName = localStorage.getItem("roleName");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const filerUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  const { t } = useTranslation();
  useEffect(() => {
    // console.log(isTablet, isDesktop, '=====>>>')
  }, [isTablet, isDesktop]);

  return (
    <aside
      className={`sidebar scroll-style ${
        mini || isMobile || isTablet ? "sidebar__mini" : ""
      } `}>
      <Button
        className="sidebar__prev sidebar__btn"
        design="circled"
        type="button"
        onClick={() => navigate(-1)}
        prepend={<ArrowBackIcon />}
      />
      <Button
        className="sidebar__btn"
        type="button"
        design="circled"
        prepend={mini ? <ArrowRight /> : <ArrowLeft />}
        onClick={() => setMini(!mini)}
      />
      {!(mini || isMobile || isTablet) && (
        <div className="sidebar__admin d-flex align-items-center">
          {!userData?.image && !mini && (
            <Avatar
              size="md"
              borderColor="white"
              className="mr_10"
              src={userAva}
            />
          )}
          {userData?.image && !mini && (
            <Avatar
              size="md"
              borderColor="white"
              className="mr_10"
              src={userData.image}
            />
          )}
          <div className="sidebar__admin?-info">
            <Typography
              Type="p"
              className="sidebar__role"
              text={t(localStorage.getItem("roleName")?.toLowerCase())}
            />
            <Typography
              Type="h5"
              className="sidebar__admin-name"
              text={
                userData?.firstName
                  ? userData?.firstName
                  : "" + " " + userData?.sureName
                  ? userData?.sureName
                  : ""
              }
            />
            <Typography
              Type="p"
              className="sidebar__admin-pnfl "
              text={t("pinifl") + (userData?.JSHSHIR ?? 0)}
            />
          </div>
        </div>
      )}

      {isDesktop &&
        SidbarMenu("tashkent").map((menu, index) =>
          menu.submenu
            ? menu?.roles?.has(roleName) && (
                <SubMenu
                  className="sidebar__link"
                  key={menu.id}
                  menu={menu}
                  rolename={roleName}
                />
              )
            : menu?.roles?.has(roleName) && (
                <AppLink
                  key={menu.id}
                  className="sidebar__link"
                  link={menu.link}
                  prepend={menu.icon}
                  text={menu.label}
                  activeClass="sidebar__link_active"
                />
              )
        )}
      {isMobile &&
        SidbarMenu("tashkent")
          ?.filter((el) => accessTableMenu.has(el.linkId))
          ?.map((menu, index) =>
            menu.submenu
              ? menu?.roles?.has(roleName) && (
                  <SubMenu
                    className="sidebar__link"
                    key={menu.id}
                    menu={menu}
                    rolename={roleName}
                  />
                )
              : menu?.roles?.has(roleName) && (
                  <AppLink
                    key={menu.id}
                    className="sidebar__link"
                    link={menu.link}
                    prepend={menu.icon}
                    text={menu.label}
                    activeClass="sidebar__link_active"
                  />
                )
          )}
    </aside>
  );
};
