import { lazy } from "react";
// import InformationModal from "./components/InformationModal";
const Camera = lazy(() => import("../Camera/page/Camera"));
const CameraReverese = lazy(() =>
  import("../Camera/page/Camera-insede-reverese")
);
const InformationModal = lazy(() => import("./components/InformationModal"));
const CameraSpecial = lazy(() => import("../Camera/page/Camera-Special"));
const DocumentDownload = lazy(() => import("../Camera/page/DocumentDownload"));
const ItemCamer = lazy(() => import("../Camera/page/ItemCamera"));

export const CameraRouter = [
  {
    id: 24435,
    path: "/camera-insede/:id/:name",
    // element: <Debtors />,
    element: <CameraReverese />,
    roles: new Set(["Superadmin"]),
  },
  {
    id: 245,
    path: "/camera-insede/:name",
    element: <CameraSpecial />,
    roles: new Set([
      "Superadmin",
      "VsxManager",
      "RegionalManager",
      "SpecialVsxManager",
    ]),
  },
  {
    id: 9612,
    path: "/camera-insedes/:name",
    element: <DocumentDownload />,
    roles: new Set([
      "Superadmin",
      "VsxManager",
      "RegionalManager",
      "SpecialVsxManager",
    ]),
  },
  {
    id: 9,
    path: "/cameras",
    element: <Camera />,
    roles: new Set(["Superadmin", "VsxManager"]),
  },
  {
    id: 24,
    path: "/cameras/:id/:name",
    element: (
      <>
        <ItemCamer />
      </>
    ),
    roles: new Set(["Superadmin"]),
  },
];
