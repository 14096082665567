import {lazy} from "react";

const EmployeesList = lazy(() => import("../Employees/page/Employees"));
const EmployeesDetail = lazy(() => import("../Employees/sub-pages/Employees-detail"))
const EmployessForms = lazy(() => import("../Employees/sub-pages/EmployeesForms"))
export const Employees =[
    {
        id:5,
        path: "/employees",
        // element: <Outgo />,
        element: <EmployeesList/>,
        roles: new Set(['Superadmin', 'RegionalManager', 'VsxManager'])
    },
    {
        id:6,
        path: "/employees/:id",
        // element: <Outgo />,
        element: <EmployessForms/>,
        roles: new Set(['Superadmin', 'RegionalManager', 'VsxManager'])
    },
    {
        id:31,
        path: '/employees/detail/:id',
        element: <EmployeesDetail/>,
        roles: new Set(["Superadmin", 'RegionalManager'])
    },
]
