import { lazy } from "react";
const Arxiv = lazy(() => import("./page/Arxiv"));
export const ArxivRouter = [
  {
    id: 11,
    path: "/archive",
    // element: <CashboxOrderSingle />,
    element: (
      <>
        <Arxiv />
      </>
    ),
    roles: new Set(["Superadmin"]),
  },
];
