import React from "react";
import { get } from "lodash";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Containers from "../../../../containers";
import { Button, AttachFile, Fields } from "../../../../components";
import { FastField } from "formik";
import dayjs from "dayjs";

function FormRelease(props) {
  const navLink = useNavigate();
  const { values, customData, method, title } = props;
  const { t } = useTranslation();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const filters = {};
  if (userData.region) {
    filters["region"] = userData.region.id;
  }
  if (userData.vsx) {
    filters["vsx"] = userData.vsx.id;
  }
  return (
    <div>
      <Containers.Form
        customData={customData}
        validate={(event) => {
          // console.log(event)
        }}
        method={"post"}
        url={"/prisoner/leave-room"}
        onSuccess={({ data }) => {
          method();
        }}
        onError={() => {
          method();
        }}
        fields={[
          {
            name: "time",
            // validations: [{type: "required"}],
            value: get(values, "birthdate"),
            onSubmitValue: (value) => dayjs().format("YYYY-MM-DD HH:mm"),
          },
          {
            name: "injures",
            value: get(values, "injures"),
            onSubmitValue: (value) => value,
          },
          {
            name: "injuresFile",
            validationType: "array",
            value: get(values, "injures"),
            onSubmitValue: (value) => {
              if (value) return value;
              return null;
            },
          },
          {
            name: "isInjured",
            // validations: [{type: "required"}],
            value: `${get(values, "isLGBT")}` ?? false,
            onSubmitValue: (value) => {
              // console.log(value)
              const _item = value === "Ha" ? true : false;
              return _item;
            },
          },
          // {
          //     name: "medicalDiagnosis",
          //     validations: [{type: "required"}],
          //     value: get(values, 'medicalDiagnosis'),
          //     onSubmitValue: (value) => value,
          // },
          {
            name: "worker",
            validations: [{ type: "required" }],
            value: get(values, "worker"),
            onSubmitValue: (value) => value,
          },
          {
            name: "responsibleOfficer",
            // validations: [{type: "required"}],
            validationType: "object",
            value: get(values, "nationality") ?? "",
            onSubmitValue: (value) => userData.id,
          },
          {
            name: "investigatingOrganization",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "investigatingOrganization") ?? "",
            onSubmitValue: (value) => value.value,
          },
          {
            name: "freeReason",
            // validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "investigatingOrganization") ?? "",
            onSubmitValue: (value) => value.value,
          },
          {
            name: "document",
            value: get(values, "document") ?? "",
            validationType: "array",
            onSubmitValue: (value) => {
              if (!value) return null;
              return value;
            },
          },
        ]}>
        {({ isSubmitting, values, setFieldValue, ...rest }) => {
          return (
            <>
              <div className="row g-4">
                <div className="col-12">
                  <div className="row g-4">
                    <div className="col-12">
                      <FastField
                        name="freeReason"
                        component={Fields.AsyncSelect}
                        loadOptionsUrl={"/prisoner-free-reasons"}
                        loadOptionsKey={(data) =>
                          data?.data?.map((el) => ({
                            label: el.name,
                            value: el.id,
                          }))
                        }
                        label={t("select-reason")}
                        placeholder={t("select")}
                      />
                    </div>
                    <div className="col-12">
                      <FastField
                        name="investigatingOrganization"
                        component={Fields.AsyncSelect}
                        loadOptionsUrl={"/investigating-organizations"}
                        loadOptionsKey={(data) =>
                          data?.data?.map((el) => ({
                            label: el.name,
                            value: el.id,
                          }))
                        }
                        label={t("inquest-out-organition")}
                        placeholder={t("select")}
                      />
                    </div>

                    <div className="col-12">
                      <FastField
                        name="worker"
                        component={Fields.InputText}
                        hasTimeSelect
                        label={t("inquest-employees")}
                      />
                    </div>
                    <div className="col-12">
                      <h2 className="search_label">{t("free-verdict")}</h2>
                      <div className="file-upload">
                        <FastField
                          name="document"
                          title={t("file")}
                          isMultiple
                          component={AttachFile}
                          label={t("minutes")}
                        />
                      </div>
                    </div>
                    {/*<div className="col-6 col-sm-12 col-md-12 col-xl-4 col-lg-4">*/}
                    {/*    <FastField*/}
                    {/*        name="time"*/}
                    {/*        component={Fields.DatePicker}*/}
                    {/*        hasTimeSelect*/}
                    {/*        maskformat={'####-##-## ##:##'}*/}
                    {/*        format={'YYYY-MM-DD HH:mm'}*/}
                    {/*        label={t('free-verdict-time')}*/}
                    {/*        placeholder={t('free-verdict-time')}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    {/*nationality*/}
                    <div className="col-12">
                      <p
                        className="mb_20 control__label "
                        style={{ fontSize: 14 + "px" }}>
                        {t("injuries-has")}
                      </p>
                      <div className="d-flex">
                        <span>
                          <FastField
                            name="isInjured"
                            component={Fields.RadioButton}
                            label={t("yes")}
                            value={t("yes")}
                          />
                        </span>
                        <span className="ml_10">
                          <FastField
                            name="isInjured"
                            component={Fields.RadioButton}
                            label={t("no")}
                            value={t("no")}
                          />
                        </span>
                      </div>
                    </div>
                    {values.isInjured === "Ha" && (
                      <div className="col-12">
                        <FastField
                          name="injures"
                          component={Fields.InputText}
                          label={t("injuries-has")}
                          // placeholder="Фамилия"
                        />
                      </div>
                    )}
                    {values.isInjured === "Ha" && (
                      <div className="col-12">
                        <h2 className="search_label">{t("file")}</h2>
                        <div className="file-upload">
                          <FastField
                            name="injuresFile"
                            title={t("file")}
                            isMultiple
                            component={AttachFile}
                            label={t("minutes")}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  design="primary"
                  type="submit"
                  className="modal-btn-end fz_16 btn mt_40 w_full"
                  text={title.name}
                  isLoading={isSubmitting}
                />
              </div>
            </>
          );
        }}
      </Containers.Form>
    </div>
  );
}

export default FormRelease;
