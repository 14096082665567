import React from "react";
import { get, isFunction } from "lodash";
import cn from "classnames";
import { CheckBox } from "./TableCheckBox/CheckBox/CheckBox";
import { ActionButtons } from "./ActionButtons";

export const TableRow = (props) => {
  const {
    row,
    columns,
    deleteAction,
    editAction,
    onRowClick,
    index,
    isColor = true,
    renderButtons,
    seeAction,
    passwordAction,
    pdfDownload,
    isChecked,
    isTimeOut,
    isTimeMin,
    isCheckedSee,
    blockInActive,
    blockActive,
    setItemChecked,
    giveAction,
    isButtonsVisible,
    handleStatus,
    tableBg,
    status,
  } = props;
  const bgStatus = status === "emergency" ? (tableBg ? "" : "#BDEBB4") : "";
  return (
    <tr
      // className={`${cn("table__tr", { cursor_pointer: onRowClick })} ${
      //   isColor && row?.isNotReleasedInTime
      //     ? "bg-red"
      //     : isColor && row?.isExpired
      //     ? "bg-yellow"
      //     : ""
      // }`}
      className={`${cn("table__tr", { cursor_pointer: onRowClick })} `}
      style={{ background: `${bgStatus}` }}
      onClick={() => {
        isFunction(onRowClick) && onRowClick(row);
      }}>
      {columns.map((col, innerIndex) => {
        return (
          <td
            key={innerIndex}
            className={`table__td index-table ${get(col, "className", "")}`}>
            <span
              className="d-flex"
              style={{ gap: 10 + "px", alignItems: "center" }}>
              {innerIndex === 0 && isCheckedSee && (
                <CheckBox
                  row={row}
                  isChecked={isChecked}
                  setItemChecked={setItemChecked}
                />
              )}
              {/*{row}*/}
              {col.render(row[col.dataKey], row, index)}
            </span>
          </td>
        );
      })}

      {(editAction ||
        deleteAction ||
        renderButtons ||
        giveAction ||
        seeAction ||
        passwordAction ||
        pdfDownload ||
        blockActive ||
        blockInActive ||
        handleStatus) && (
        <td className="table__td">
          <ActionButtons
            row={row}
            giveAction={giveAction}
            editAction={editAction}
            pdfDownload={pdfDownload}
            deleteAction={deleteAction}
            renderButtons={renderButtons}
            seeAction={seeAction}
            blockInActive={blockInActive}
            blockActive={blockActive}
            isButtonsVisible={isButtonsVisible}
            passwordAction={passwordAction}
            handleStatus={handleStatus}
          />
        </td>
      )}
    </tr>
    // </div>
  );
};
