import React, { useEffect, useState } from "react";

import PDFViewer from "pdf-viewer-reactjs";
import { httpClient } from "../../services";
export const PDFVIEWER = ({ url, id }) => {
  const [render, setRender] = useState(null);
  const urlList = process.env.REACT_APP_IMAGE_BASE_URL;
  const fetchList = async () => {
    try {
      const { data } = await httpClient.post(url, { prisoner: id });
      setRender(data.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchList();
  }, []);
  return (
    <div>
      {render && <PDFViewer document={{ url: urlList + render[0].url }} />}
    </div>
  );
};
