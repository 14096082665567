import React, { Fragment, useState } from "react";
import { ErrorMessage, FastField, Field, FieldArray } from "formik";
import { get, isFunction } from "lodash";
import { useOverlay } from "hooks";
import { httpClient, time } from "services";
import "../styles/tintuv.scss";
import { ReactComponent as IconDelete } from "../../../assets/icons/delete.svg";
import Containers from "containers";
import { Fields, Button, AvatarUpload, AttachFile } from "components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { NarcoDispanserModal } from "./narcoDispanserModal";
import { PhscoDispanserModal } from "./phsycoDispanserModal";
import SignatureModal from "../sub-pages/SignatureModal";
import InspectorModal from "../sub-pages/InspectorModal";
import { AsyncPaginate } from "react-select-async-paginate";

const FieldArraysItems = ({ name, products, t }) => {
  return (
    <FieldArray name={"items"}>
      {({ insert, remove, push }) => (
        <>
          {products.map((product, index, arr) => (
            <div className="row" key={index}>
              <div className="d-flex align-items-center mt_10" key={index}>
                <div style={{ width: 160 + "px" }} className="w_full">
                  <Field
                    name={`items.${index}.name`}
                    component={Fields.InputText}
                    // placeholder={}
                  />
                </div>
                {index + 1 === arr.length && index !== 0 && (
                  <Button
                    className="color_primary-red product__btn ml_20"
                    onClick={() => remove(index)}>
                    <IconDelete />
                  </Button>
                )}
              </div>
              <ErrorMessage
                name={`items.${index}.name`}
                component="span"
                className="field-error"
              />
            </div>
          ))}
          <Button
            className="w_30 mt_20 "
            style={{ padding: 0, borderRadius: 5 + "px" }}
            design={"primary"}
            onClick={() => push({ name: "" })}
            onKeyDown={() => push({ name: "" })}>
            +
          </Button>
        </>
      )}
    </FieldArray>
  );
};
const FieldArrays = ({ name, products, t }) => {
  return (
    <FieldArray name={"prohibitedItems"}>
      {({ insert, remove, push }) => (
        <>
          {products.map((product, index, arr) => (
            <div className="row" key={index}>
              <div
                className="col-12 col-md-12 col-sm-12  d-flex align-items-center "
                key={index}>
                {/*<div className="d-flex">*/}
                <div className="col-8">
                  <FastField
                    name={`prohibitedItems.${index}.key`}
                    component={Fields.AsyncSelect}
                    isPagination
                    loadOptionsUrl={"/prohibited-items"}
                    loadOptionsKey={(data) => {
                      return data?.data?.map((el) => ({
                        label: el.name,
                        value: el.id,
                      }));
                    }}
                  />
                </div>
                <div style={{ width: 120 + "px" }} className="col-4 w_full">
                  <Field
                    name={`prohibitedItems.${index}.amount`}
                    component={Fields.InputText}
                  />
                </div>
              </div>
              <ErrorMessage
                name={`prohibitedItems.${index}.amount`}
                component="span"
                className="field-error"
              />{" "}
              <br />
              <div className="ml_10 mt_10">
                {index + 1 === arr.length && index !== 0 && (
                  <Button
                    className="color_primary-red product__btn"
                    onClick={() => remove(index)}>
                    <IconDelete />
                  </Button>
                )}
              </div>
            </div>
          ))}
          <Button
            className="w_30 mt_20 "
            style={{ padding: 0, borderRadius: 5 + "px" }}
            design={"primary"}
            onClick={() => push({ key: [], amount: "" })}>
            +
          </Button>
        </>
      )}
    </FieldArray>
  );
};
export const PForms = (props) => {
  const [timeRadio, setTimeRadio] = useState("");
  const { valuesUpdate, onSuccessValue, toastify, setToastify } = props;
  const BaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  const FieldArraysChilds = ({ products, t }) => {
    return (
      <FieldArray name={"children"}>
        {({ insert, remove, push }) => (
          <>
            {products.map((product, index, arr) => (
              <div className="row mt_10 g-4">
                <div
                  className="col-6 col-md-12 col-sm-12 col-lg-6 col-xl-3"
                  key={index}>
                  <Field
                    name={`children.${index}.fullName`}
                    component={Fields.InputText}
                    placeholder={t("fullname")}
                    label={t("fullname")}
                  />
                  <ErrorMessage
                    name={`children.${index}.fullName`}
                    component="span"
                    className="field-error"
                  />
                  {/*</div>*/}
                </div>
                <div
                  className="col-6 col-md-12 col-sm-12 col-lg-6 col-xl-3"
                  key={index}>
                  <Field
                    name={`children.${index}.birthLisenc`}
                    component={Fields.InputText}
                    placeholder={t("birthlisenc")}
                    label={t("birthlisenc")}
                  />
                  <ErrorMessage
                    name={`children.${index}.birthLisenc`}
                    component="span"
                    className="field-error"
                  />
                  {/*</div>*/}
                </div>
                <div
                  className="col-6 col-md-12 col-sm-12 col-lg-6 col-xl-3"
                  key={index}>
                  <Field
                    name={`children.${index}.birthDate`}
                    component={Fields.DatePicker}
                    placeholder={t("birthdate")}
                    label={t("birthdate")}
                  />
                  <ErrorMessage
                    name={`children.${index}.birthdate`}
                    component="span"
                    className="field-error"
                  />
                  {/*</div>*/}
                </div>
                <div
                  className="col-6 col-md-12 col-sm-12 col-lg-6 col-xl-3"
                  key={index}>
                  <Field
                    name={`children.${index}.birthPlace`}
                    component={Fields.InputText}
                    placeholder={t("birthplace")}
                    label={t("birthplace")}
                  />
                  <ErrorMessage
                    name={`children.${index}.birthPlace`}
                    component="span"
                    className="field-error"
                  />
                  <div className="ml_10">
                    {index + 1 === arr.length && index !== 0 && (
                      <Button
                        className="color_primary-red product__btn mt_10"
                        style={{ float: "right" }}
                        onClick={() => remove(index)}>
                        <IconDelete />
                      </Button>
                    )}
                  </div>
                  {/*</div>*/}
                </div>
              </div>
            ))}
            <div>
              <Button
                className="w_30 mt_20 "
                style={{ padding: 0, borderRadius: 5 + "px" }}
                design={"primary"}
                onClick={() =>
                  push({
                    fullName: "",
                    birthLisenc: "",
                    birthDate: "",
                    birthPlace: "",
                  })
                }>
                +
              </Button>
            </div>
          </>
        )}
      </FieldArray>
    );
  };
  const Info = ({ icon, data, label }) => {
    return (
      <div className="search">
        <h2 style={{ color: "var(--text-color)" }} className="search_label">
          {label}
        </h2>
        <div className="search_data">
          <span>{data}</span>{" "}
          <span>
            {icon && (
              <svg
                width="15"
                height="15"
                viewBox="0 0 18 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16 2.3027H15V1.31029C15 0.764459 14.55 0.317871 14 0.317871C13.45 0.317871 13 0.764459 13 1.31029V2.3027H5V1.31029C5 0.764459 4.55 0.317871 4 0.317871C3.45 0.317871 3 0.764459 3 1.31029V2.3027H2C0.89 2.3027 0.00999999 3.19588 0.00999999 4.28754L0 18.1814C0 19.273 0.89 20.1662 2 20.1662H16C17.1 20.1662 18 19.273 18 18.1814V4.28754C18 3.19588 17.1 2.3027 16 2.3027ZM16 17.189C16 17.7348 15.55 18.1814 15 18.1814H3C2.45 18.1814 2 17.7348 2 17.189V7.26479H16V17.189ZM4 9.24962H6V11.2345H4V9.24962ZM8 9.24962H10V11.2345H8V9.24962ZM12 9.24962H14V11.2345H12V9.24962Z"
                  fill="#414141"
                />
              </svg>
            )}
          </span>
        </div>
      </div>
    );
  };
  const [toSearch, setToSearch] = useState(false);
  const navLink = useNavigate();
  const { t } = useTranslation();
  const narcoModal = useOverlay({ uniqueName: "narcoModal" });
  const addItem = useOverlay({ uniqueName: "addRooms" });
  const addInspector = useOverlay({ uniqueName: "addRooms1" });
  const phocyModal = useOverlay({ uniqueName: "phocyModal" });

  const [childpartId, setPartId] = useState(null);
  const [signiture, setSigniture] = useState("");
  const [basicDocument, setBasicDocument] = useState("");
  const [inspector, setInspector] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [complaint, setComplaint] = useState("");
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handleFormOptions = (values, setFields) => {
    if (values.basisDocument?.value) {
      setPartId(values.basisDocument.value);
    }
  };
  const hadleNarco = (data, has) => {
    if (has || data === "-") {
      toast.info(t("person-not-narco"), { autoClose: 2000 });
    } else if (data) {
      narcoModal.handleOverlayOpen();
    } else {
      toast.info(t("search-not"), { autoClose: 1500 });
    }
  };
  const hadlePhcy = (data, has) => {
    if (has || data === "-") {
      toast.info(t("person-not-phcy"), { autoClose: 2000 });
    } else if (data) {
      phocyModal.handleOverlayOpen();
    } else {
      toast.info(t("search-not"), { autoClose: 1500 });
    }
  };

  const loadOptions = async (search, loadedOptions, { page }) => {
    try {
      const jkUrl = search
        ? `/prisoner-basis-document-parts?filters[name][$containsi]=${search}`
        : `/prisoner-basis-document-parts`;
      const jkModdaList = await httpClient.get(jkUrl);
      const data = jkModdaList?.data?.data;
      const formattedOptions = data?.map((prisoner) => ({
        value: prisoner.id,
        label: prisoner.name,
      }));

      return {
        options: formattedOptions,
      };
    } catch (error) {
      console.error("Error fetching options:", error);
      return { options: [] };
    }
  };

  const userData = JSON.parse(localStorage.getItem("userData"));

  return (
    <Fragment>
      <div>
        <div>
          <NarcoDispanserModal
            valuesUpdate={get(valuesUpdate, "narco")}
            isOpen={narcoModal.isOverlayOpen}
            handleOverlayClose={narcoModal.handleOverlayClose}
          />
          <SignatureModal
            isOpen={addItem.isOverlayOpen}
            handleModalClose={addItem.handleOverlayClose}
            setSigniture={setSigniture}
          />
          <InspectorModal
            isOpen={addInspector.isOverlayOpen}
            handleModalClose={addInspector.handleOverlayClose}
            setInspector={setInspector}
          />
          <PhscoDispanserModal
            valuesUpdate={get(valuesUpdate, "psyco")}
            isOpen={phocyModal.isOverlayOpen}
            handleOverlayOpen={phocyModal.handleOverlayOpen}
            handleOverlayClose={phocyModal.handleOverlayClose}
          />
        </div>
        <div>
          <Containers.Form
            validate={(event) => {}}
            method={get(valuesUpdate, "id") ? "put" : "post"}
            url={
              get(valuesUpdate, "id")
                ? `/prisoner/create-prisoner/${get(valuesUpdate, "id")}`
                : "/prisoner/create-prisoner"
            }
            onSuccess={({ data }) => {
              isFunction(onSuccessValue)
                ? onSuccessValue(data)
                : (data) => {
                    console.log(data);
                  };
              toast.success(t("prisoner-success-add-message"));
              toastify
                ? ""
                : toast.success(
                    "Mazkur shaxs kamera biriktirilmagan maxbuslar menyusida"
                  );
            }}
            onError={(err) => {
              console.log(err);
              // toast.error(t("vsx-saqlanmoqda"));
            }}
            fields={[
              {
                name: "image",
                value: get(valuesUpdate, "image")
                  ? get(valuesUpdate, "image")
                  : "",
                onSubmitValue: (value) => {
                  if (!value) return null;
                  return value;
                },
              },
              {
                name: "vsx",
                value: get(valuesUpdate, "vsx"),
                onSubmitValue: (value) => {
                  return userData?.vsx?.id;
                },
              },
              {
                name: "citizenship",
                validations: [{ type: "required" }],
                validationType: "object",
                value: get(valuesUpdate, "citizenship")
                  ? {
                      label: get(valuesUpdate, "citizenship.name"),
                      value: get(valuesUpdate, "citizenship.id"),
                    }
                  : "",
                onSubmitValue: (value) => value.value,
              },
              {
                name: "passport",
                validations: [{ type: "required" }],
                value: get(valuesUpdate, "passport"),
                onSubmitValue: (value) => {
                  return value;
                },
              },
              {
                name: "signatureSVG",
                value: get(valuesUpdate, "signatureSVG"),
                onSubmitValue: (value) => {
                  return signiture;
                },
              },
              {
                name: "inspetorSignatureSVG",
                value: get(valuesUpdate, "inspetorSignatureSVG"),
                onSubmitValue: (value) => {
                  return inspector;
                },
              },
              {
                name: "complaint",
                value: get(valuesUpdate, "complaint"),
                onSubmitValue: (value) => {
                  return value;
                },
              },
              {
                name: "isSigned",
                value: get(valuesUpdate, "isSigned"),
                onSubmitValue: (value) => {
                  return isChecked === false ? true : false;
                },
              },
              {
                name: "pinfl",
                validations: [{ type: "required" }],
                value: get(valuesUpdate, "pinfl"),
                onSubmitValue: (value) => {
                  return value;
                },
              },
              {
                name: "birthdate",
                validations: [{ type: "required" }],
                value: get(valuesUpdate, "birthdate"),
                onSubmitValue: (value) =>
                  time.timeFormater(value, "YYYY-MM-DD"),
              },
              {
                name: "arestTime",
                validations: [{ type: "required" }],
                value:
                  timeRadio === ""
                    ? get(valuesUpdate, "arestTime")
                    : get(valuesUpdate, "organizationArestTime"),
                onSubmitValue: (value) => {
                  if (value) {
                    return time.timeFormater(value, "YYYY-MM-DD HH:mm");
                  } else return null;
                },
              },
              {
                name: "crimeCaseNumber",
                value: get(valuesUpdate, "crimeCaseNumber"),
                onSubmitValue: (value) => {
                  return value;
                },
              },
              {
                name: "prosecutor",
                value: get(valuesUpdate, "prosecutor"),
                onSubmitValue: (value) => {
                  return value;
                },
              },
              {
                name: "prosecutorPhoneNumber",
                value: get(valuesUpdate, "prosecutorPhoneNumber"),
                onSubmitValue: (value) => {
                  return value;
                },
              },
              {
                name: "prosecutorCalledTime",
                value: get(valuesUpdate, "prosecutorCalledTime"),
                onSubmitValue: (value) => {
                  if (value) {
                    return time.timeFormater(value, "YYYY-MM-DD HH:mm");
                  } else return null;
                },
              },
              {
                name: "organizationArestTime",
                value:
                  timeRadio === ""
                    ? get(valuesUpdate, "organizationArestTime")
                    : get(valuesUpdate, "arestTime"),
                onSubmitValue: (value) => {
                  if (value) {
                    return time.timeFormater(value, "YYYY-MM-DD HH:mm");
                  } else return null;
                },
              },
              {
                name: "firstName",
                validations: [{ type: "required" }],
                value: get(valuesUpdate, "firstName"),
                onSubmitValue: (value) => value,
              },
              {
                name: "sureName",
                validations: [{ type: "required" }],
                value: get(valuesUpdate, "sureName"),
                onSubmitValue: (value) => value,
              },
              {
                name: "middleName",
                validations: [{ type: "required" }],
                value: get(valuesUpdate, "middleName"),
                onSubmitValue: (value) => value,
              },
              {
                name: "hasItems",
                value: get(valuesUpdate, "hasItems"),
                onSubmitValue: (value) => {
                  if (value === "Ha") return true;
                  return false;
                },
              },
              {
                name: "hasProhibitedItems",
                value: get(valuesUpdate, "hasProhibitedItems"),
                onSubmitValue: (value) => {
                  if (value === "Ha") return true;
                  return false;
                },
              },
              {
                name: "items",
                value: get(valuesUpdate, "items"),
                validationType: "array",
                onSubmitValue: (value) => {
                  if (value.length) return value.map((el) => el.name);
                  return null;
                },
              },
              {
                name: "prohibitedItems",
                value: get(valuesUpdate, "prohibitedItems"),
                validationType: "array",
                onSubmitValue: (value) => {
                  if (value.length)
                    return value.map((el) => ({
                      id: el.key.value,
                      amount: el.amount,
                    }));
                  return null;
                },
              },
              {
                name: "children",
                validationType: "array",
                value: [
                  {
                    fullName: "",
                    birthLisenc: "",
                    birthDate: "",
                    birthPlace: "",
                  },
                ],
                lazy: (validator, yup) =>
                  validator.of(
                    yup.object().shape({
                      fullName: yup.string(),
                      birthLisenc: yup.string(),
                      birthDate: yup.string(),
                      birthPlace: yup.string(),
                    })
                  ),
                onSubmitValue: (value) => value.map((item) => item),
              },
              {
                name: "birthAddress",
                validations: [{ type: "required" }],
                value: get(valuesUpdate, "birthAddress"),
                onSubmitValue: (value) => value,
              },
              {
                name: "address",
                // validations: [{ type: "required" }],
                value: get(valuesUpdate, "address"),
                onSubmitValue: (value) => value,
              },
              {
                name: "responsibleMedWorkers",
                // validations: [{type: "required"}],
                value: get(valuesUpdate, "responsibleMedWorkers"),
                onSubmitValue: (value) => value,
              },
              {
                name: "specialSigns",
                // validations: [{type: "required"}],
                // validationType: 'array',
                value: get(valuesUpdate, "specialSigns"),
                onSubmitValue: (value) => value,
              },
              {
                name: "medExamInfoDocument",
                validationType: "array",
                value: get(valuesUpdate, "medExamInfoDocument"),
                onSubmitValue: (value) => {
                  if (!value) return null;
                  return value;
                },
              },
              {
                name: "medExamDeedDocument",
                validationType: "array",
                value: get(valuesUpdate, "medExamDeedDocument"),
                onSubmitValue: (value) => {
                  if (!value) return null;
                  return value;
                },
              },
              {
                name: "images",
                validationType: "array",
                value: get(valuesUpdate, "images"),
                onSubmitValue: (value) => {
                  if (!value) return null;
                  return value;
                },
              },
              {
                name: "investigatorReport",
                validationType: "array",
                value: get(valuesUpdate, "investigatorReport"),
                onSubmitValue: (value) => {
                  if (!value) return null;
                  return value;
                },
              },
              {
                name: "livingAddress",
                value: get(valuesUpdate, "livingAddress"),
                onSubmitValue: (value) => value,
              },
              {
                name: "phsycoDispanser",
                // validations: [{type: "required"}],
                validationType: "object",
                value: !valuesUpdate?.psyco
                  ? get(valuesUpdate, "psyco")
                  : valuesUpdate?.psyco === "-"
                  ? {
                      label: "Mavjud emas",
                      value: 2,
                    }
                  : { label: "Mavjud", value: 1 },
                onSubmitValue: (value) => {
                  if (value) {
                    if (value.value) return value.value;
                  } else {
                    return null;
                  }
                },
              },
              {
                name: "narcoDispanser",
                // validations: [{type: "required"}],
                validationType: "object",
                value: !valuesUpdate?.narco
                  ? get(valuesUpdate, "narco")
                  : valuesUpdate?.narco === "-"
                  ? {
                      label: "Mavjud emas",
                      value: 2,
                    }
                  : { label: "Mavjud", value: 1 },

                onSubmitValue: (value) => {
                  if (value) {
                    if (value.value) return value.value;
                  } else {
                    return null;
                  }
                },
              },
              {
                name: "organizationOfficer",
                // validations: [{type: "required"}],
                value: get(valuesUpdate, "organizationOfficer"),
                onSubmitValue: (value) => {
                  return value;
                },
              },
              {
                name: "arestOrganizationOfficer",
                // validations: [{type: "required"}],
                value: get(valuesUpdate, "arestOrganizationOfficer"),
                onSubmitValue: (value) => {
                  return value;
                },
              },
              {
                name: "medExamDate",
                // validations: [{type: "required"}],
                value: get(valuesUpdate, "medExamDate"),
                onSubmitValue: (value) => {
                  if (value) return value;
                  return dayjs().format("YYYY-MM-DD HH:mm");
                },
              },
              {
                name: "medExamResponsibleOfficer",
                // validations: [{type: "required"}],
                value: get(valuesUpdate, "medExamResponsibleOfficer"),
                onSubmitValue: (value) => {
                  if (value) return value;
                  return JSON.parse(localStorage.getItem("userData")).id;
                },
              },
              {
                name: "responsibleOfficer",
                // validations: [{type: "required"}],
                value: get(valuesUpdate, "responsibleOfficer"),
                onSubmitValue: (value) => {
                  if (value) return value;
                  return JSON.parse(localStorage.getItem("userData")).id;
                },
              },
              {
                name: "isConvicted",
                validations: [{ type: "required" }],
                value: get(valuesUpdate, "isConvicted") ?? false,
                onSubmitValue: (value) => {
                  return value;
                },
              },
              {
                name: "reesterNumber",
                value: get(valuesUpdate, "reesterNumber"),
                onSubmitValue: (value) => {
                  return value;
                },
              },
              {
                name: "hasChild",
                validations: [{ type: "required" }],
                value: get(valuesUpdate, "hasChild") ?? false,
                onSubmitValue: (value) => {
                  return value;
                },
              },
              {
                name: "nationality",
                validations: [{ type: "required" }],
                validationType: "object",
                value: get(valuesUpdate, "nationality")
                  ? {
                      label: get(valuesUpdate, "nationality.name"),
                      value: get(valuesUpdate, "nationality.id"),
                    }
                  : "",
                onSubmitValue: (value) => value.value,
              },
              {
                name: "isInvalid",
                validations: [{ type: "required" }],
                value: get(valuesUpdate, "isInvalid") ?? false,
                onSubmitValue: (value) => {
                  return value;
                },
              },
              {
                name: "organizationPhone",
                value: get(valuesUpdate, "organizationPhone"),
                onSubmitValue: (value) => {
                  return value;
                },
              },
              {
                name: "prosecutorCalledTime",
                value: get(valuesUpdate, "prosecutorCalledTime"),
                onSubmitValue: (value) => {
                  if (value) {
                    return time.timeFormater(value, "YYYY-MM-DD HH:mm");
                  } else return null;
                },
              },
              {
                name: "gender",
                validations: [{ type: "required" }],
                validationType: "object",
                value: get(valuesUpdate, "gender")
                  ? {
                      label: get(valuesUpdate, "gender.name"),
                      value: get(valuesUpdate, "gender.id"),
                    }
                  : "",
                onSubmitValue: (value) => {
                  return value.value;
                },
              },
              {
                name: "basisDocumentPart",
                // validations: [{ type: "required" }],
                validationType: "object",
                value: get(valuesUpdate, "basisDocumentPart")
                  ? {
                      label: get(valuesUpdate, "basisDocumentPart.name"),
                      value: get(valuesUpdate, "basisDocumentPart.id"),
                    }
                  : "",
                onSubmitValue: (value) => {
                  return basicDocument;
                },
              },
              {
                name: "invalidType",
                // validations: [{type: "required"}],
                validationType: "object",
                value: get(valuesUpdate, "invalidType.id")
                  ? {
                      label: get(valuesUpdate, "invalidType.name"),
                      value: get(valuesUpdate, "invalidType.id"),
                    }
                  : "",
                onSubmitValue: (value) => {
                  return value.value;
                },
              },
              {
                name: "conviction",
                validations: [{ type: "required" }],
                validationType: "object",
                value: get(valuesUpdate, "conviction.id")
                  ? {
                      label: get(valuesUpdate, "conviction.name"),
                      value: get(valuesUpdate, "conviction.id"),
                    }
                  : "",
                onSubmitValue: (value) => {
                  return value.value;
                },
              },
              {
                name: "basisDocument",
                validations: [{ type: "required" }],
                validationType: "object",
                value: get(valuesUpdate, "basisDocument.id")
                  ? {
                      label: get(valuesUpdate, "basisDocument.name"),
                      value: get(valuesUpdate, "basisDocument.id"),
                    }
                  : "",
                onSubmitValue: (value) => {
                  return value.value;
                },
              },
              {
                name: "appearance",
                validationType: "object",
                value: get(valuesUpdate, "appearance.id")
                  ? {
                      label: get(valuesUpdate, "appearance.name"),
                      value: get(valuesUpdate, "appearance.id"),
                    }
                  : "",
                onSubmitValue: (value) => {
                  return value.value;
                },
              },
              {
                name: "organization",
                // validations: [{type: "required"}],
                validationType: "object",
                value: get(valuesUpdate, "organization.id")
                  ? {
                      label: get(valuesUpdate, "organization.name"),
                      value: get(valuesUpdate, "organization.id"),
                    }
                  : "",
                onSubmitValue: (value) => {
                  return value.value;
                },
              },
              {
                name: "isLGBT",
                // validations: [{type: "required"}],
                value: `${get(valuesUpdate, "isLGBT")}` ?? false,
                onSubmitValue: (value) => {
                  const _item = value === "Ha" ? true : false;
                  return _item;
                },
              },
            ]}
            customData={{ isFormCompleted: toSearch }}>
            {({ isSubmitting, values, setFieldValue, ...rest }) => {
              handleFormOptions(values, setFieldValue);
              setTimeRadio(values?.basisDocument);
              console.log(values);
              return (
                <>
                  <div className="row g-4">
                    <div
                      className="visitors-form-istablet"
                      style={{ display: "flex" }}>
                      <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                        <FastField
                          name="image"
                          component={AvatarUpload}
                          className="avatar-upload"
                          disabled={get(valuesUpdate, "disabled")}
                          getImage={(image) => {
                            return BaseUrl + get(image, "0.url");
                          }}
                        />
                      </div>
                      <div className="row">
                        <div className="col-4 col-sm-12 col-md-12 col-lg-4">
                          <FastField
                            name="sureName"
                            component={Fields.InputText}
                            isDisabled={get(valuesUpdate, "disabled")}
                            label={t("sure-name")}
                            placeholder={t("sure-name")}
                          />
                        </div>

                        {/*firstname*/}
                        <div className="col-4  col-sm-12 col-md-12 col-lg-4">
                          <FastField
                            name="firstName"
                            component={Fields.InputText}
                            isDisabled={get(valuesUpdate, "disabled")}
                            label={t("first-name")}
                            placeholder={t("first-name")}
                          />
                        </div>
                        {/*birthdate*/}

                        {/*passport*/}
                        <div className="col-4  col-sm-12 col-md-12 col-lg-4">
                          <FastField
                            name="middleName"
                            component={Fields.InputText}
                            isDisabled={get(valuesUpdate, "disabled")}
                            label={t("middle-name")}
                            placeholder={t("middle-name")}
                          />
                        </div>
                        <div className="col-4  mt_10 col-sm-12 col-md-12 col-lg-4">
                          <FastField
                            name="citizenship"
                            component={Fields.AsyncSelect}
                            loadOptionsUrl={"/citizenships"}
                            isDisabled={get(valuesUpdate, "disabled")}
                            loadOptionsKey={(data) =>
                              data?.data?.map((el) => ({
                                label: el.name,
                                value: el.id,
                              }))
                            }
                            label={t("citizenship") + "*"}
                            placeholder={t("select")}
                          />
                        </div>
                        {/*birthAddress*/}
                        <div className="col-4 mt_10 col-sm-12 col-md-12 col-lg-4">
                          <FastField
                            name="birthAddress"
                            component={Fields.InputText}
                            label={t("birthaddress")}
                            isDisabled={get(valuesUpdate, "disabled")}
                            placeholder={t("birthaddress")}
                          />
                        </div>
                        {/*address*/}
                        <div className="col-4  mt_10 col-sm-12 col-md-12 col-lg-4">
                          <FastField
                            name="address"
                            component={Fields.InputText}
                            label={t("address")}
                            // isDisabled={get(valuesUpdate, "disabled")}
                            placeholder={t("address")}
                          />
                        </div>

                        <div className="col-4 mt_10 col-sm-12 col-md-12 col-lg-4">
                          <FastField
                            name="birthdate"
                            component={Fields.DatePicker}
                            isDisabled={get(valuesUpdate, "disabled")}
                            hasTimeSelect
                            label={t("birthdate") + "*"}
                            placeholder={t("birthdate") + "*"}
                          />
                        </div>
                        <div className="col-4 mt_10 col-sm-12 col-md-12 col-lg-4">
                          <FastField
                            name="passport"
                            component={Fields.InputText}
                            isDisabled={get(valuesUpdate, "disabled")}
                            label={t("passport")}
                            placeholder={t("passport")}
                          />
                        </div>

                        <div className="col-4 mt_10 col-sm-12 col-md-12 col-lg-4">
                          <FastField
                            name="pinfl"
                            component={Fields.InputText}
                            label={t("pinfl-name")}
                            // isDisabled={get(valuesUpdate, "disabled")}
                            placeholder={t("pinfl-name")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12 col-lg-10 col-md-12 col-sm-12">
                      <div className="col-xl-12 col-lg-10 col-md-12 col-sm-12">
                        <div className="row g-4">
                          {/*sureName*/}

                          {/*livingAddress*/}
                          <div className="col-3 col-sm-12 col-md-12 col-lg-3">
                            <FastField
                              name="livingAddress"
                              component={Fields.InputText}
                              label={t("livingAddress")}
                              placeholder={t("livingAddress")}
                            />
                          </div>
                          {/*gender*/}
                          <div className="col-3 col-sm-12 col-md-12 col-lg-3">
                            <FastField
                              name="gender"
                              component={Fields.AsyncSelect}
                              loadOptionsUrl={"/genders"}
                              loadOptionsKey={(data) =>
                                data?.data?.map((el) => ({
                                  label: el.name,
                                  value: el.id,
                                }))
                              }
                              isDisabled={get(valuesUpdate, "disabled")}
                              label={t("gender")}
                              placeholder={t("select")}
                            />
                          </div>

                          {/* */}

                          {/*nationality*/}
                          <div className="col-3 col-sm-12 col-md-12 col-lg-3">
                            <FastField
                              name="nationality"
                              component={Fields.AsyncSelect}
                              isDisabled={get(valuesUpdate, "disabled")}
                              loadOptionsUrl={"/nationalities"}
                              is
                              loadOptionsKey={(data) =>
                                data?.data?.map((el) => ({
                                  label: el.name,
                                  value: el.id,
                                }))
                              }
                              label={t("nationality")}
                              placeholder={t("select")}
                            />
                          </div>
                          <div className="col-3 col-sm-12 col-md-12 col-lg-3">
                            <FastField
                              name="reesterNumber"
                              component={Fields.InputText}
                              label={t("reesterNumber")}
                              placeholder={t("reesterNumber")}
                            />
                          </div>
                          {/*conviction*/}
                          <div className="col-4 col-sm-12 col-md-12 col-lg-4">
                            <FastField
                              name="conviction"
                              component={Fields.AsyncSelect}
                              loadOptionsUrl={"/convictions"}
                              loadOptionsKey={(data) =>
                                data?.data?.map((el) => ({
                                  label: el.name,
                                  value: el.id,
                                }))
                              }
                              label={t("conviction")}
                              placeholder={t("select")}
                            />
                          </div>

                          <div className="col-2 col-sm-6 col-md-2">
                            <h3
                              style={{ color: "var(--text-color)" }}
                              className=" mb_20">
                              {t("child")}
                            </h3>
                            <FastField
                              name="hasChild"
                              component={Fields.CheckBox}
                            />
                          </div>
                          <div className="col-4  mt_20 col-sm-9 col-md-9 col-lg-4">
                            <FastField
                              name="invalidType"
                              component={Fields.AsyncSelect}
                              loadOptionsUrl={"/prisoner-invalid-types"}
                              loadOptionsKey={(data) =>
                                data?.data?.map((el) => ({
                                  label: el.name,
                                  value: el.id,
                                }))
                              }
                              label="Nogironligi *"
                              placeholder={t("select")}
                            />
                          </div>

                          {values.hasChild && (
                            <FieldArraysChilds
                              t={t}
                              products={
                                values?.children?.length
                                  ? values.children
                                  : [
                                      {
                                        fullName: "",
                                        birthLisenc: "",
                                        birthDate: "",
                                        birthPlace: "",
                                      },
                                    ]
                              }
                            />
                          )}
                          <div className="col-2 col-md-2 col-xl-2 col-lg-2">
                            <p
                              className="mb_20"
                              style={{
                                fontSize: 14 + "px",
                                color: "var(--text-color)",
                              }}>
                              {t("lgbt")}
                            </p>
                            <div className="d-flex">
                              <span>
                                <FastField
                                  name="isLGBT"
                                  component={Fields.RadioButton}
                                  label={t("yes")}
                                  value={t("yes")}
                                />
                              </span>
                              <span className="ml_10">
                                <FastField
                                  name="isLGBT"
                                  component={Fields.RadioButton}
                                  label={t("no")}
                                  value={t("no")}
                                />
                              </span>
                            </div>
                          </div>
                          {/*organization*/}

                          {values.isLGBT === t("yes") && (
                            <div className="col-4 col-sm-10 col-md-10 col-lg-4">
                              <FastField
                                name="appearance"
                                component={Fields.AsyncSelect}
                                loadOptionsUrl={"/genders"}
                                loadOptionsKey={(data) =>
                                  data?.data?.map((el) => ({
                                    label: el.name,
                                    value: el.id,
                                  }))
                                }
                                label={t("out-see")}
                                placeholder={t("select")}
                              />
                            </div>
                          )}

                          {/*organizationOfficer*/}

                          <div className="col-4 col-sm-12 col-md-12 col-lg-4 cursor_not">
                            <div className="row">
                              <div
                                className={
                                  valuesUpdate?.disabled
                                    ? "hideDiv col-10"
                                    : "col-10"
                                }>
                                <div
                                  className={
                                    valuesUpdate?.disabled ? "cursor_not" : ""
                                  }>
                                  <FastField
                                    name="narcoDispanser"
                                    component={Fields.AsyncSelect}
                                    loadOptionsUrl={"/prisoner-narco-selecteds"}
                                    loadOptionsKey={(data) =>
                                      data?.data?.map((el) => ({
                                        label: el.name,
                                        value: el.id,
                                      }))
                                    }
                                    label={t("narcoDispanser")}
                                    isDisabled={get(valuesUpdate, "disabled")}
                                    placeholder={t("select")}
                                  />
                                </div>
                              </div>
                              <div className="col-2 mt_25">
                                <button
                                  onClick={(event) => {
                                    event.preventDefault();
                                    hadleNarco(
                                      get(valuesUpdate, "narco"),
                                      get(valuesUpdate, "narco") === null
                                    );
                                  }}>
                                  <svg
                                    width="40px"
                                    height="40px"
                                    viewBox="0 0 48 48"
                                    version="1"
                                    enableBackground="new 0 0 48 48">
                                    <circle
                                      fill="#2196F3"
                                      cx="24"
                                      cy="24"
                                      r="21"
                                    />
                                    <rect
                                      x="22"
                                      y="22"
                                      fill="var(--white)"
                                      width="4"
                                      height="11"
                                    />
                                    <circle
                                      fill="var(--white)"
                                      cx="24"
                                      cy="16.5"
                                      r="2.5"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="col-4 col-sm-12 col-md-12 col-lg-4">
                            <div className="row">
                              <div
                                className={
                                  valuesUpdate?.disabled
                                    ? "hideDiv col-10"
                                    : "col-10"
                                }>
                                <div
                                  className={
                                    valuesUpdate?.disabled ? "cursor_not" : ""
                                  }>
                                  <FastField
                                    name="phsycoDispanser"
                                    component={Fields.AsyncSelect}
                                    isDisabled={get(valuesUpdate, "disabled")}
                                    loadOptionsUrl={
                                      "/prisoner-psycho-selecteds"
                                    }
                                    loadOptionsKey={(data) =>
                                      data?.data?.map((el) => ({
                                        label: el.name,
                                        value: el.id,
                                      }))
                                    }
                                    label={t("phsycoDispanser")}
                                    placeholder={t("select")}
                                  />
                                </div>
                              </div>
                              <div className="col-2 mt_25">
                                <button
                                  onClick={(event) => {
                                    event.preventDefault();
                                    hadlePhcy(
                                      get(valuesUpdate, "psyco"),
                                      get(valuesUpdate, "psyco") === null
                                    );
                                  }}>
                                  <svg
                                    width="40px"
                                    height="40px"
                                    viewBox="0 0 48 48"
                                    version="1"
                                    enableBackground="new 0 0 48 48">
                                    <circle
                                      fill="#2196F3"
                                      cx="24"
                                      cy="24"
                                      r="21"
                                    />
                                    <rect
                                      x="22"
                                      y="22"
                                      fill="var(--white)"
                                      width="4"
                                      height="11"
                                    />
                                    <circle
                                      fill="var(--white)"
                                      cx="24"
                                      cy="16.5"
                                      r="2.5"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                          <hr style={{ marginBottom: 20 + "px" }} />
                          <div className="row">
                            <div className="col-4 col-sm-12 col-md-12 col-lg-4">
                              <FastField
                                name="basisDocument"
                                component={Fields.AsyncSelect}
                                filters={{ id: { $ne: 7 } }}
                                loadOptionsUrl={"/prisoner-basis-documents"}
                                loadOptionsKey={(data) =>
                                  data?.data?.map((el) => ({
                                    label: el.name,
                                    value: el.id,
                                  }))
                                }
                                loadOptionsParams={(searchText) => ({
                                  sort: {
                                    createdAt: "asc",
                                  },
                                })}
                                label={t("jk-role")}
                                placeholder={t("select")}
                              />
                            </div>
                            <div className="col-3 col-sm-12 col-md-12 col-lg-4">
                              <FastField
                                name="arestTime"
                                component={Fields.DatePicker}
                                hasTimeSelect
                                maskformat={"####-##-## ##:##"}
                                format={"YYYY-MM-DD HH:mm"}
                                label={t("arest-time")}
                                placeholder={t("arest-time")}
                              />
                            </div>
                            <div className="col-3 col-sm-12 col-md-12 col-lg-4">
                              <FastField
                                name="crimeCaseNumber"
                                component={Fields.InputText}
                                label={t("crimeCaseNumber")}
                                placeholder={t("crimeCaseNumber")}
                              />
                            </div>

                            <div className="col-12">
                              {values.basisDocument.value && (
                                <div className="row mt_10">
                                  <div className="col-6 col-sm-12 col-md-12 col-lg-6 mt_20">
                                    <b style={{ color: "var(--text-color)" }}>
                                      {" "}
                                      {t("jk-role-section")}
                                    </b>
                                    <AsyncPaginate
                                      loadOptions={loadOptions}
                                      styles={{
                                        width: "100%",
                                        border: "2px solid var(--input-border)",
                                      }}
                                      name="basisDocumentPart"
                                      required
                                      placeholder={t("select")}
                                      getOptionValue={(option) => {}}
                                      getOptionLabel={(option) => option.label}
                                      isSearchable={true}
                                      additional={{
                                        page: 1,
                                      }}
                                      onChange={(e) =>
                                        setBasicDocument(e?.value)
                                      }
                                    />
                                  </div>
                                  <div className="col-6 col-md-12 col-sm-12 col-lg-6 col-xl-6 mt_10">
                                    <FastField
                                      name="arestOrganizationOfficer"
                                      component={Fields.InputText}
                                      label={t("arest-organization-officer")}
                                      placeholder={t(
                                        "arest-organization-officer"
                                      )}
                                    />
                                  </div>
                                  <div className="col-6 col-md-12 col-sm-12 col-lg-6 col-xl-6 mt_10">
                                    <FastField
                                      name="organizationPhone"
                                      component={Fields.InputText}
                                      label={t("phone")}
                                      placeholder={t("phone")}
                                    />
                                  </div>
                                  <div className="col-6 col-md-12 col-sm-12 col-lg-6 col-xl-6 mt_10">
                                    <h2
                                      style={{ color: "var(--text-color)" }}
                                      className="search_label">
                                      {t("bay-qaror")}
                                    </h2>
                                    <div className="file-upload">
                                      <FastField
                                        name="investigatorReport"
                                        title={t("file")}
                                        component={AttachFile}
                                        isMultiple
                                        label={t("bay-qaror")}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-6 col-md-12 col-sm-12 col-lg-6 col-xl-6 mt_10">
                                    <FastField
                                      name="organizationOfficer"
                                      component={Fields.InputText}
                                      label={t("service-employees")}
                                      placeholder={t("service-employees")}
                                    />
                                  </div>

                                  <div className="col-6 col-md-12 col-sm-12 col-lg-6 col-xl-6 mt_10">
                                    <FastField
                                      name="organization"
                                      loadOptionsUrl={"/prisoner-organizations"}
                                      component={Fields.AsyncSelect}
                                      loadOptionsKey={({ data }) =>
                                        data?.map((el) => ({
                                          label: el.name,
                                          value: el.id,
                                        }))
                                      }
                                      label={t("where-service-employees")}
                                      placeholder={t("select")}
                                    />
                                  </div>
                                  <div className="col-6 col-md-12 col-sm-12 col-lg-6 col-xl-6 mt_10">
                                    <FastField
                                      name="organizationArestTime"
                                      component={Fields.DatePicker}
                                      maskformat={"####-##-## ##:##"}
                                      format={"YYYY-MM-DD HH:mm"}
                                      hasTimeSelect
                                      label={t("organization-arest-time")}
                                      placeholder={t("organization-arest-time")}
                                    />
                                  </div>

                                  {/*col-4 col-sm-6 col-md-4*/}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {toSearch && (
                        <Fragment>
                          <div className="col-12 mt_20">
                            <h2
                              style={{ color: "var(--text-color)" }}
                              className="title">
                              {t("search-to")}
                            </h2>
                            <div className="row align-items-center">
                              <div className="col-5 col-md-6 col-lg-4 col-xl-5 col-lg-3 col-sm-6">
                                <Info
                                  className="w_full"
                                  label={t("search-data")}
                                  icon
                                  data={dayjs().format("DD.MM.YYYY HH:mm")}
                                />
                              </div>
                              <div className="col-5 col-md-6 col-lg-4 col-xl-5 col-lg-3 col-sm-6">
                                <Info
                                  label={t("respon-employess")}
                                  data={
                                    JSON.parse(localStorage.getItem("userData"))
                                      .username
                                  }
                                />
                              </div>
                              <br />
                              <div className="col-4 col-md-2 col-lg-4 col-xl-4">
                                <p
                                  className="mb_20 mt_20"
                                  style={{
                                    fontSize: 16 + "px",
                                    fontWeight: 600,
                                    color: "var(--text-color)",
                                  }}>
                                  {t("has-product")}
                                </p>

                                <div className="d-flex">
                                  <span>
                                    <FastField
                                      name="hasItems"
                                      component={Fields.RadioButton}
                                      label={t("yes")}
                                      value="Ha"
                                    />
                                  </span>
                                  <span className="ml_10">
                                    <FastField
                                      name="hasItems"
                                      component={Fields.RadioButton}
                                      label={t("no")}
                                      value="No"
                                    />
                                  </span>
                                </div>
                              </div>
                              {values.hasItems === "Ha" && (
                                <div className="col-6 col-md-6 col-sm-6 col-lg-2 col-xl-2">
                                  <p
                                    className="mb_20 mt_20"
                                    style={{
                                      fontSize: 16 + "px",
                                      fontWeight: 600,
                                      color: "var(--text-color)",
                                    }}>
                                    {t("proxibid-product")}
                                  </p>
                                  <div className="d-flex">
                                    <span>
                                      <FastField
                                        name="hasProhibitedItems"
                                        component={Fields.RadioButton}
                                        label={t("yes")}
                                        value="Ha"
                                      />
                                    </span>
                                    <span className="ml_10">
                                      <FastField
                                        name="hasProhibitedItems"
                                        component={Fields.RadioButton}
                                        label={t("no")}
                                        value="No"
                                      />
                                    </span>
                                  </div>
                                </div>
                              )}{" "}
                              <div className="row">
                                {values.hasItems === "Ha" && (
                                  <div className="col-4">
                                    <FieldArraysItems
                                      t={t}
                                      products={
                                        values.items.length
                                          ? values.items
                                          : [{ name: "" }]
                                      }
                                    />
                                  </div>
                                )}
                                {values.hasProhibitedItems === "Ha" && (
                                  <div className="col-6">
                                    <FieldArrays
                                      t={t}
                                      products={
                                        values?.prohibitedItems?.length
                                          ? values.prohibitedItems
                                          : [{ key: [], amount: "" }]
                                      }></FieldArrays>
                                  </div>
                                )}
                              </div>
                            </div>
                            {values.hasItems === "Ha" && (
                              <>
                                <div className="col-12 mt_20 d-flex align-items-center">
                                  <span
                                    onClick={(event) => {
                                      event.preventDefault();
                                      addItem.handleOverlayOpen();
                                    }}
                                    style={{
                                      color: "#fff !important",
                                    }}
                                    className="prisoner_yes">
                                    {t("imzolash")}
                                  </span>
                                  <label
                                    className="d-flex m-5 align-items-center"
                                    style={{
                                      gap: "10px",
                                      cursor: "pointer",
                                      color: "var(--text-color)",
                                    }}
                                    htmlFor="isSigned">
                                    <input
                                      id="isSigned"
                                      type="checkbox"
                                      className="advokat_input-checkbox"
                                      checked={isChecked}
                                      onChange={handleCheckboxChange}
                                    />
                                    Imzo chekishdan bosh tortdi
                                  </label>
                                  <span
                                    onClick={(event) => {
                                      event.preventDefault();
                                      addInspector.handleOverlayOpen();
                                    }}
                                    style={{ color: "var(--text-color)" }}
                                    className="prisoner_yes">
                                    Inspektor imzosi
                                  </span>
                                </div>
                                <div className="col-sm-8 col-lg-4">
                                  <p
                                    className="mb_20 mt_20"
                                    style={{
                                      fontSize: 16 + "px",
                                      fontWeight: 600,
                                      color: "var(--text-color)",
                                    }}>
                                    Tintuvdagi e'tiroz
                                  </p>
                                  <div className="d-flex">
                                    <span
                                      style={{
                                        color: `${
                                          complaint === "Ha"
                                            ? "rgb(13,71,161)"
                                            : "var(--text-color)"
                                        }`,
                                      }}
                                      onClick={(event) => {
                                        event.preventDefault();
                                        setComplaint("Ha");
                                      }}
                                      className="btn">
                                      {t("yes")}
                                    </span>
                                    <span
                                      onClick={(event) => {
                                        event.preventDefault();
                                        setComplaint("No");
                                      }}
                                      style={{
                                        color: `${
                                          complaint === "No"
                                            ? "rgb(13,71,161)"
                                            : "var(--text-color)"
                                        }`,
                                      }}
                                      className="btn">
                                      {t("no")}
                                    </span>
                                  </div>
                                  {complaint === "Ha" ? (
                                    <FastField
                                      name="complaint"
                                      component={Fields.InputText}
                                      placeholder="Tintuvdagi e'tiroz..."
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </>
                            )}
                          </div>

                          <div className="col-12">
                            <h2
                              style={{ color: "var(--text-color)" }}
                              className="title">
                              {t("body-to-search")}
                            </h2>

                            <div className="row">
                              <div className="col-3 col-md-6 col-lg-4 col-xl-4 col-lg-3 col-sm-6">
                                <FastField
                                  name="medExamDate"
                                  component={Info}
                                  label={t("search-medical-time")}
                                  data={dayjs().format(
                                    "DD.MM.YYYY HH:mm"
                                  )}></FastField>
                              </div>
                              {/*<div className="col-2 col-md-4 col-lg-2"><Info label={'Тинтув вақти'} icon data={dayjs().format('HH:mm')}/></div>*/}
                              <div className="col-3 col-md-6 col-lg-4 col-xl-4 col-lg-3 col-sm-6">
                                <FastField
                                  name="medExamResponsibleOfficer"
                                  component={Info}
                                  label={t("respon-employess")}
                                  data={
                                    JSON.parse(localStorage.getItem("userData"))
                                      .username
                                  }></FastField>
                              </div>
                              {/* <div className="col-3">hello world</div> */}
                              <div className="col-3 col-md-6 col-lg-2 col-xl-2 col-lg-2 col-sm-6">
                                <h2
                                  style={{ color: "var(--text-color)" }}
                                  className="search_label">
                                  {t("medical-data")}
                                </h2>
                                <div className="file-upload">
                                  <FastField
                                    name="medExamInfoDocument"
                                    title={t("file")}
                                    isMultiple
                                    component={AttachFile}
                                  />
                                </div>
                              </div>
                              <div className="col-3 col-md-6 col-lg-2 col-xl-2 col-lg-2 col-sm-6">
                                <h2
                                  style={{ color: "var(--text-color)" }}
                                  className="search_label">
                                  {t("act")}
                                </h2>
                                <div className="file-upload">
                                  <FastField
                                    name="medExamDeedDocument"
                                    title={t("file")}
                                    isMultiple
                                    component={AttachFile}
                                  />
                                </div>
                              </div>

                              <div className="col-4 col-sm-12 mt_10 col-md-12">
                                <FastField
                                  name="responsibleMedWorkers"
                                  component={Fields.InputText}
                                  label={t("med-user")}
                                  placeholder={t("med-user")}
                                />
                              </div>
                              <div className="col-4 col-sm-12 mt_10 col-md-12">
                                <FastField
                                  name="specialSigns"
                                  component={Fields.InputText}
                                  label={t("special-signs")}
                                  placeholder={t("special-signs")}
                                />
                              </div>
                              <div className="col-4 col-md-6 col-sm-6 col-xl-4 col-lg-4 mt_10">
                                <p
                                  className="mb_20 control__label "
                                  style={{
                                    fontSize: 14 + "px",
                                    fontWeight: 600,
                                    color: "var(--text-color)",
                                  }}>
                                  {t("injuries-has")}
                                </p>
                                <div className="d-flex align-items-center">
                                  <span>
                                    <FastField
                                      name="isInjured"
                                      component={Fields.RadioButton}
                                      label={t("yes")}
                                      value="Ha"
                                    />
                                  </span>
                                  <span className="ml_10">
                                    <FastField
                                      name="isInjured"
                                      component={Fields.RadioButton}
                                      label={t("no")}
                                      value="No"
                                    />
                                  </span>
                                </div>
                              </div>
                              <div className="row mt_10">
                                <div className="col-3 col-md-6 col-lg-2 col-xl-2 col-lg-2 col-sm-6">
                                  <h2
                                    style={{ color: "var(--text-color)" }}
                                    className="search_label">
                                    {t("Fotosurat yuklash")}
                                  </h2>
                                  <div className="file-upload">
                                    <FastField
                                      name="images"
                                      title={t("file")}
                                      isMultiple
                                      component={AttachFile}
                                    />
                                  </div>
                                </div>
                                {values.isInjured === "Ha" && (
                                  <div className="col-4 mt_20 col-sm-6 col-md-6 ">
                                    <FastField
                                      name="additionalNotes"
                                      component={Fields.Textarea}
                                      type={""}
                                      // label="Qo'shimcha qaydlar"
                                      placeholder={t("additional-notes")}
                                    />
                                  </div>
                                )}
                              </div>
                              {values.isInjured === "Ha" && (
                                <div className="row mt_10">
                                  <div className="col-4 col-sm-12 mt_15 col-md-12">
                                    <FastField
                                      name="prosecutor"
                                      component={Fields.InputText}
                                      label={t("prosecutor")}
                                      placeholder={t("prosecutor")}
                                    />
                                  </div>
                                  <div className="col-4 col-sm-12 mt_15 col-md-12">
                                    <FastField
                                      name="prosecutorPhoneNumber"
                                      component={Fields.InputText}
                                      label={t("prosecutorPhoneNumber")}
                                      placeholder={t("prosecutorPhoneNumber")}
                                    />
                                  </div>
                                  <div className="col-4 col-sm-12 mt_15 col-md-12">
                                    <FastField
                                      name="prosecutorCalledTime"
                                      component={Fields.DatePicker}
                                      maskformat={"####-##-## ##:##"}
                                      format={"YYYY-MM-DD HH:mm"}
                                      hasTimeSelect
                                      label={t("prosecutorCalledTime")}
                                      placeholder={t("prosecutorCalledTime")}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </Fragment>
                      )}
                    </div>
                    <div className="d-flex mb_40 justify-content-end prisoners-button-list">
                      <Button
                        design="cancel"
                        style={{ borderRadius: 15 }}
                        className="modal-btn-end fz_16 btn mt_40 visitors-button"
                        text={t("cansel")}
                        onClick={() => navLink(-1)}
                        isLoading={isSubmitting}
                      />
                      <Button
                        design="primary"
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          setToSearch((old) => !old);
                          setToastify(true);
                        }}
                        className="modal-btn-end fz_16 btn mt_40 visitors-button"
                        text={t("search-and-medcal-add")}
                        isLoading={isSubmitting}
                      />{" "}
                      <Button
                        design="primary"
                        type="submit"
                        className="modal-btn-end fz_16 btn mt_40 visitors-button"
                        text={get(values, "id") ? t("updata") : t("save")}
                        isLoading={isSubmitting}
                      />
                    </div>
                  </div>
                </>
              );
            }}
          </Containers.Form>
        </div>
      </div>
    </Fragment>
  );
};
