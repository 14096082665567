import React from "react";
import "../../modules/Prisoners/styles/tintuv.scss";
export const Info = ({ icon, data, label }) => {
  return (
    <div className="search">
      <h2 className="search_label">{label}</h2>
      <div className="search_data">
        <span>{data}</span>{" "}
        <span>
          {icon && (
            <svg
              width="15"
              height="15"
              viewBox="0 0 18 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16 2.3027H15V1.31029C15 0.764459 14.55 0.317871 14 0.317871C13.45 0.317871 13 0.764459 13 1.31029V2.3027H5V1.31029C5 0.764459 4.55 0.317871 4 0.317871C3.45 0.317871 3 0.764459 3 1.31029V2.3027H2C0.89 2.3027 0.00999999 3.19588 0.00999999 4.28754L0 18.1814C0 19.273 0.89 20.1662 2 20.1662H16C17.1 20.1662 18 19.273 18 18.1814V4.28754C18 3.19588 17.1 2.3027 16 2.3027ZM16 17.189C16 17.7348 15.55 18.1814 15 18.1814H3C2.45 18.1814 2 17.7348 2 17.189V7.26479H16V17.189ZM4 9.24962H6V11.2345H4V9.24962ZM8 9.24962H10V11.2345H8V9.24962ZM12 9.24962H14V11.2345H12V9.24962Z"
                fill="#414141"
              />
            </svg>
          )}
        </span>
      </div>
    </div>
  );
};
