import React from "react";
import {useNavigate} from "react-router-dom";

export const IsInvalid =(item, link) => {
    const  Link= useNavigate()
    if(link) {
      return  <div style={{cursor: "pointer"}} onClick={() => Link(link)} className={(item ? 'seccess':'error')}></div>
    }
    else {
        return <div className={(item ? 'seccess':'error')}></div>
    }
}

