import React, { Fragment, useState } from "react";
import Containers from "../../containers";
import { FastField } from "formik";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { Search } from "react-feather";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Fields } from "components";
import { useLocation } from "react-router-dom";

export const SearchToPinfl = (props) => {
  const [reesterNumber, setReesterNumber] = useState("");
  const { setValue, url, isResetForm, isData, valuesUpdate } = props;
  const { t } = useTranslation();
  const location = useLocation().pathname;

  return (
    <div className="visitors-form-istablet" style={{ display: "flex", width: "100%", marginBottom: "20px" }}>
      {location === "/prisoner-add" ? (
        <div style={{ width: "100%" }}>
          <Containers.Form
            isResetForm={isResetForm}
            method={"post"}
            url={url}
            fields={[
              {
                name: "reesterNumber",
                onSubmitValue: (value) => {
                  return value;
                },
              },
            ]}
            onSuccess={(data, formValue) => {
              toast.success(t("search-success"));
              setValue({ ...data.data, ...formValue, disabled: true });
            }}
            onError={(data) => {
              toast.error(t("data-not"));
            }}>
            {({ isSubmitting, values }) => (
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-8 col-md-10">
                      <FastField
                        name="reesterNumber"
                        component={Fields.InputText}
                        label={t("reesterNumber")}
                        placeholder={t("reesterNumber")}
                      />
                    </div>
                    <div className="col-1">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="fz_16 btn mt_30 btn__primary d-flex gap_12">
                        {isSubmitting && <span className="btn__spinner"></span>}
                        <Search />
                      </button>
                    </div>

                    {valuesUpdate?.hadDangerousItemsBefore && (
                      <span style={{ color: "red" }}>
                        {" "}
                        {t("had-dangerous-items-before")}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Containers.Form>
        </div>
      ) : (
        ""
      )}
      <div style={{ width: "100%", marginRight: "60px" }}>
        <Containers.Form
          isResetForm={isResetForm}
          method={"post"}
          url={url}
          fields={[
            {
              name: "passport",
              validations: [{ type: "required" }],
              onSubmitValue: (value) => {
                return value;
              },
            },
            {
              name: "pinfl",
              validations: [{ type: "required" }],
              onSubmitValue: (value) => {
                if (!value) return null;
                return value;
              },
            },
          ]}
          onSuccess={(data, formValue) => {
            if (data?.success === true) {
              toast.success(t("search-success"));
            }
            setValue({ ...data.data, ...formValue, disabled: true });
          }}
          onError={(data) => {
            // console.log(data);
            toast.error(t("data-not"));
          }}>
          {({ isSubmitting, values }) => (
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-5 col-sm-5 col-md-5">
                    <FastField
                      name="passport"
                      component={Fields.InputText}
                      label={t("passport")}
                      placeholder={t("passport-seria-number")}
                    />
                  </div>
                  <div className="col-5 col-sm-5 col-md-5">
                    <FastField
                      name="pinfl"
                      component={Fields.InputText}
                      label={t("pinfl-name")}
                      placeholder={t("jshisht")}
                    />
                  </div>
                  <div className="col-1">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="fz_16 btn mt_30 btn__primary d-flex gap_12">
                      {isSubmitting && <span className="btn__spinner"></span>}
                      <Search />
                    </button>
                  </div>
                  {valuesUpdate?.hadDangerousItemsBefore && (
                    <span style={{ color: "red" }}>
                      {" "}
                      {t("had-dangerous-items-before")}
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
        </Containers.Form>
      </div>
    </div>
  );
};
