import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import { isFunction, get } from "lodash";

import { httpClient, utils, queryBuilder } from "services";
import { useGetLanguage, useNotification } from "hooks";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const FormContainer = ({
  url,
  params,
  method = "post",
  children,
  isFormData = false,
  fields = [],
  normalizeData,
  axiosConfig = {},
  onSuccess = () => {},
  onError = () => {},
  onFinal = () => {},
  customData = {},
  isResetForm = true,
  onSubmit,
  isData = true,
  validateOnMount = false,
  parentForm,
  validate,
  ...formProps
}) => {
  const { languages } = useGetLanguage();
  const notifier = useNotification();
  const navLink = useNavigate();
  const { t } = useTranslation();
  const { initialValues, validationSchema } =
    utils.formHelpers.createFormSchema(fields, languages);
  const handleSubmit = (values, formHelpers) => {
    const formValues = utils.formHelpers.getFormValues(
      values,
      fields,
      isFormData,
      normalizeData,
      languages
    );
    const requestUrl = params ? queryBuilder(url, params) : url;
    httpClient[method](
      requestUrl,
      isData
        ? { data: { ...formValues, ...customData } }
        : { ...formValues, ...customData },
      axiosConfig
    )
      .then(({ data }) => {
        if (isResetForm) {
          formHelpers.resetForm();
        }
        onSuccess(data, formValues);
        notifier.success(t("success-text"));
      })
      .catch(({ error }) => {
        onError(error);
        console.log(error);
      })
      .finally(() => {
        formHelpers.setSubmitting(false);
        onFinal();
      });
  };

  return (
    <Formik
      form={parentForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={validateOnMount}
      validate={validate}
      onSubmit={(value, formHelpers) => {
        // console.log(value, formHelpers)
        isFunction(onSubmit)
          ? onSubmit(value, formHelpers)
          : handleSubmit(value, formHelpers);
      }}
      enableReinitialize={true}>
      {(formik) => {
        return <Form {...formProps}>{children(formik)}</Form>;
      }}
    </Formik>
  );
};

FormContainer.propTypes = {
  url: PropTypes.string,
  method: PropTypes.oneOf(["post", "put"]),
  children: PropTypes.func,
  isFormData: PropTypes.bool,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.any,
      validationType: PropTypes.string,
      validations: PropTypes.array,
      lazy: PropTypes.func,
      submitKey: PropTypes.string,
      onSubmitValue: PropTypes.func,
      isLanguageSchema: PropTypes.bool,
    })
  ),
  axiosConfig: PropTypes.object,
  normalizeData: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onFinal: PropTypes.func,
};

/*

    yupValidation = string | number | boolean | date | object | array

    Field Object Structure
        name: String,
        value: Any,
        validationType: yupValidation,
        validations: [{type: yupValidation, params: Any}]
        onSubmitKey: String
        onSubmitValue: Function

*/
