import {lazy} from "react";

const VSXList = lazy(() => import("../VsxList/page/VSXLIst"));
const RoomList = lazy(() => import("../VsxList/page/RoomsList"));
const VSXFomrs = lazy(() => import("../VsxList/sub-pages/VSXForms"));
export const VsxRouter =[
    {
        id:12,
        path: "/vsx",
        // element: <CashboxStatistics />,
        element: <><VSXList/></>,
        roles: new Set(["Superadmin"])
    },
    {
        id:13,
        path: "/vsx/:id",
        // element: <CashboxStatistics />,
        element:<VSXFomrs/>,
        roles: new Set(["Superadmin"])
    } ,
    {
        id:13533545354,
        path: "/vsx/rooms/:id",
        element:<RoomList/>,
        roles: new Set(["Superadmin"])
    },
]
