import { Button } from "components";
import dayjs from "dayjs";
import { useFetchList } from "hooks";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { httpClient } from "services";

function Inspection() {
  const { t } = useTranslation();
  const { id } = useParams();
  const vsxCheckData = useFetchList({
    url: `/vsx-checks/${id}`,
  });

  const handleClickInspection = async () => {
    try {
      const response = await httpClient.put(`/vsx-checks/${id}`, {
        isManagerConfirmed: true,
      });
      if (response.status === 200) {
        toast.success(t("Muvaffaqiyatli tasdiqlandi!"));
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="mt_40">
      <div style={{ gap: 10 }} className="d-flex align-items-center">
        <h2 style={{ fontSize: "22px", color: "var(--text-color)" }}>
          {t("inspection-person")}:
        </h2>
        <h3 style={{ fontSize: "18px", color: "var(--text-color)" }}>
          {vsxCheckData?.data?.data?.fullName}
        </h3>
      </div>
      <div style={{ gap: 10 }} className="d-flex align-items-center mt_20">
        <h2 style={{ fontSize: "22px", color: "var(--text-color)" }}>
          {t("inspection-position")}:
        </h2>
        <h3 style={{ fontSize: "18px", color: "var(--text-color)" }}>
          {vsxCheckData?.data?.data?.position}
        </h3>
      </div>
      <div style={{ gap: 10 }} className="d-flex align-items-center mt_20">
        <h2 style={{ fontSize: "22px", color: "var(--text-color)" }}>
          {t("rank")}:
        </h2>
        <h3 style={{ fontSize: "18px", color: "var(--text-color)" }}>
          {vsxCheckData?.data?.data?.rank}
        </h3>
      </div>
      <div style={{ gap: 10 }} className="d-flex align-items-center mt_20">
        <h2 style={{ fontSize: "22px", color: "var(--text-color)" }}>
          {t("check-change-date")}:
        </h2>
        <h3 style={{ fontSize: "18px", color: "var(--text-color)" }}>
          {"  "}{" "}
          {dayjs(vsxCheckData?.data?.data?.date).format("YYYY-MM-DD HH:mm")}
        </h3>
      </div>
      <div style={{ gap: 10 }} className="d-flex align-items-center mt_20">
        <h2 style={{ fontSize: "22px", color: "var(--text-color)" }}>
          {t("check-main")}:
        </h2>
        <h3 style={{ fontSize: "18px", color: "var(--text-color)" }}>
          {"  "}
          {vsxCheckData?.data?.data?.organization?.name ?? "--"}
        </h3>
      </div>
      <div style={{ gap: 10 }} className="d-flex align-items-center mt_20">
        <h2 style={{ fontSize: "22px", color: "var(--text-color)" }}>
          {t("check-results")}:
        </h2>
        <h3 style={{ fontSize: "18px", color: "var(--text-color)" }}>
          {vsxCheckData?.data?.data?.organization?.results ?? "--"}
        </h3>
      </div>
      <div className="d-flex justify-content-end mt_20">
        <Button
          onClick={handleClickInspection}
          className="btn mt_20"
          design="primary"
          text={t("Tasdiqlash")}
        />
      </div>
    </div>
  );
}

export default Inspection;
