import React from "react";
import Containers from "../../containers";
import { FastField } from "formik";
import { Fields } from "../../components";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { useTranslation } from "react-i18next";

export const SearchStatus = ({ setValues, label, placeholder, name }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Containers.Form
        method={"post"}
        url={"/prisoner"}
        onSuccess={({ data }) => {
          setValues(data);
        }}
        onError={() => {}}
        fields={[
          {
            name,
            validations: [{ type: "required" }],
            onSubmitValue: (value) => value,
          },
        ]}>
        {({ isSubmitting, values, setFieldValue, ...rest }) => {
          return (
            <>
              <div className="row">
                <div className={isSubmitting ? "col-9" : "col-10"}>
                  <FastField
                    name={name}
                    component={Fields.InputText}
                    label={label}
                    placeholder={placeholder}
                  />
                </div>
                <div className="col-2">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="fz_16 btn mt_25 btn__primary d-flex gap_12">
                    {isSubmitting && <span className="btn__spinner"></span>}
                    <SearchIcon />
                  </button>
                </div>
              </div>
            </>
          );
        }}
      </Containers.Form>
    </div>
  );
};
