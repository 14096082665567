import CameraTable from "./page/CameraTable";

export const PrisonersCameraRouter = [
  {
    id: 5,
    path: "/prisoner-camera",
    // element: <Debtors />,
    element: <CameraTable />,
    roles: new Set([
      "Superadmin",
      "VsxManager",
      "RegionalManager",
      "VsxInspector",
      "SuperDuperAdmin",
      "SpecialVsxInspector",
      "SpecialVsxManager",
    ]),
  },
];
