import {lazy} from "react";

const PrisonersTopSecrer = lazy(() => import("../PrisonersTopSecrer/page/PrisonersTopSecrer"));
const TopInspectorPrisonerForms = lazy(() => import("./sub-pages/INspektorTopPrisonerForms"))
const TopForms = lazy(() => import("./sub-pages/TopForms"))
export const PrisonerTopSecr = [
    {
        id:4,
        path: '/prisoner-add-topSecret',
        element: <TopForms/>,
        roles: new Set(['Superadmin','VsxManager', 'VsxInspector', 'SuperDuperAdmin'])
    },
    {
        id:4,
        path: '/prisoner-add-topSecret-search/:id',
        element: <TopInspectorPrisonerForms/>,
        roles: new Set(['Superadmin','VsxManager', 'VsxInspector', 'SuperDuperAdmin'])
    },
    {
        id:4,
        path: '/prisoner-add-topSecret-list',
        element: <PrisonersTopSecrer/>,
        roles: new Set(['Superadmin','VsxManager', 'VsxInspector', 'SuperDuperAdmin'])
    },
]
