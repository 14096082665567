import { lazy } from "react";

const Reports = lazy(() => import("../Reports/page/Reports"));
const VsxReports = lazy(() => import("../Reports/page/VsxReports"));
const ReportsState = lazy(() => import("../Reports/page/ReportsState"));
const ReportsFh = lazy(() => import("../Reports/page/ReportsFh"));
const ReportsMq = lazy(() => import("../Reports/page/ReportsMq"));
const ReportsHals = lazy(() => import("../Reports/page/Hals-report"));
const ReportsJqb = lazy(() => import("../Reports/page/JQB-reports"));
const ReportsFood = lazy(() => import("../Reports/page/Food-reports"));

export const RepoerRouter = [
  {
    id: 69,
    path: "/reports",
    // element: <Outgo />,
    element: (
      <>
        <Reports />
      </>
    ),
    roles: new Set(["Superadmin", "RegionalManager"]),
  },
  {
    id: 69,
    path: "/reports-vsx",
    // element: <Outgo />,
    // element: <><VsxReports/></>,
    element: (
      <>
        <VsxReports />
      </>
    ),
    roles: new Set(["Superadmin", "RegionalManager"]),
  },
  {
    id: 69,
    path: "/reports-mq",
    // element: <Outgo />,
    // element: <><ReportsMq/></>,
    element: (
      <>
        <ReportsMq />
      </>
    ),
     roles: new Set(["Superadmin", "RegionalManager"]),
  },
  {
    id: 69,
    path: "/reports-state",
    // element: <Outgo />,
    // element: <><ReportsState/></>,
    element: (
      <>
        <ReportsState />
      </>
    ),
     roles: new Set(["Superadmin", "RegionalManager"]),
  },
  {
    id: 69,
    path: "/reports-fh",
    // element: <Outgo />,
    // element: <><ReportsFh/></>,
    element: (
      <>
        <ReportsFh />
      </>
    ),
     roles: new Set(["Superadmin", "RegionalManager"]),
  },
  {
    id: 69,
    path: "/hand-report",
    // element: <Outgo />,
    // element: <><ReportsFh/></>,
    element: (
      <>
        <ReportsHals />
      </>
    ),
     roles: new Set(["Superadmin", "RegionalManager"]),
  },
  {
    id: 69,
    path: "/jqb-report",
    // element: <Outgo />,
    // element: <><ReportsFh/></>,
    element: (
      <>
        <ReportsJqb />
      </>
    ),
     roles: new Set(["Superadmin", "RegionalManager"]),
  },
  {
    id: 69,
    path: "/food-report",
    // element: <Outgo />,
    // element: <><ReportsFh/></>,
    element: (
      <>
        <ReportsFood />
      </>
    ),
     roles: new Set(["Superadmin", "RegionalManager"]),
  },
];
