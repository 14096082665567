import React from 'react';
import {debounce} from "lodash";
import {ReactComponent as SearchIcon} from "../../../assets/icons/search.svg";
import '../styles/searchInput.scss'
export const  SearchInput = (props)  =>{
    const {handleSearch, placeholder} = props;
    return (
        <div>
            <div className='searchInput'>
                <input onChange={debounce(handleSearch, 1500)} type="text" placeholder={placeholder} className="" />
                <SearchIcon className='icon'/>
            </div>
        </div>
    );
}
