import React, { Fragment, useState } from "react";

export const TextResize = ({ url, text, style }) => {
  const [isText, setText] = useState(true);
  return (
    <Fragment>
      <span
        onClick={(e) => {
          e.stopPropagation();
          setText((old) => !old);
        }}
        className={
          isText ? "text-resize link-text-resize" : "link-text-resize"
        }>
        <span className={isText ? "" : "hello"} style={style}>
          {text}
        </span>
      </span>
      {url && (
        <a
          style={{ color: "blue" }}
          href={process.env.REACT_APP_IMAGE_BASE_URL + url}
          target={"_blank"}>
          Hujjat...
        </a>
      )}
    </Fragment>
  );
};
