import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import "./index.scss";
import { debounce, } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "../../Fields";
export const InputSearch = (props) => {
  const {
    text,
    isInput,
    value,
    setValue,
    children,
    time = 2000,
    placeholder = "search",
    splaceholder,
    items,
    isSelect,
  } = props;
  const [values, setValues] = useState({
    name: undefined,
    value: undefined,
  });
  const { t } = useTranslation();
  const handleFilter = (item) => {
    setValues(item);
    if (item?.name) {
      setValues({ name: item.name });
      setValue({ name: item.name });
    } else if (item?.id) {
      setValues({ value: item });
      setValue({ value: item.id });
    } else if (!item?.name) {
      setValues({ name: undefined });
      setValue({ name: undefined });
    } else if (!item?.id) {
      setValues({ value: undefined });
      setValue({ value: undefined });
    } else {
      setValues({
        name: undefined,
        value: undefined,
      });
    }
  };

  return (
    <div className="header__search">
      {text && (
        <div className="header__text">
          <h2
            style={{ fontSize: "18px", color: "var(--table-color)" }}
            className="mr_20">
            {" "}
            {text}
          </h2>
        </div>
      )}
      {
        <div className="d-flex gap_12">
          {children}
          {isSelect && (
            <Select
              isClearable
              value={values.value}
              onValueChange={(data) => {
                if (data) {
                  handleFilter(data);
                } else {
                  handleFilter(data);
                }
              }}
              options={items}
              placeholder={splaceholder}
            />
          )}
          {isInput && (
            <div className="header__input">
              <input
                onChange={debounce(
                  (event) => handleFilter({ name: event.target.value }),
                  1500
                )}
                type="text"
                placeholder={placeholder}
                className="header__search__input"
              />
              <SearchIcon className="icon" />
            </div>
          )}
        </div>
      }
    </div>
  );
};
