import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Containers from "../../../../containers";
import { get } from "lodash";
import { time } from "../../../../services";
import { ErrorMessage, FastField, Field, FieldArray } from "formik";
import { AttachFile, Button, Fields } from "../../../../components";
import dayjs from "dayjs";
import "../../../Prisoners/styles/tintuv.scss";
import { ReactComponent as IconDelete } from "../../../../assets/icons/delete.svg";
const FieldArrays = ({ name, products, t }) => {
  // console.log(products, '===>>>>>')
  return (
    <FieldArray name={"convoyOfficer"}>
      {({ insert, remove, push }) => (
        <>
          {products.map((product, index, arr) => (
            <div className="row" key={index}>
              <div
                className="col-12 col-md-12 col-sm-12 mt_20 d-flex align-items-center "
                key={index}>
                {/*<div className="d-flex">*/}
                <div className="col-12">
                  <Field
                    name={`convoyOfficer.${index}.name`}
                    component={Fields.InputText}
                    label={t("san-user")}
                    placeholder={t("san-user")}
                  />
                </div>
                <div
                  style={{ width: 120 + "px" }}
                  className="col-4 w_full"></div>
              </div>
              <ErrorMessage
                name={`convoyOfficer.${index}.name`}
                component="span"
                className="field-error"
              />{" "}
              <br />
              <div className="ml_10 mt_10">
                {index + 1 === arr?.length && index !== 0 && (
                  <Button
                    className="color_primary-red product__btn"
                    onClick={() => remove(index)}>
                    <IconDelete />
                  </Button>
                )}
              </div>
              {/*</div>*/}
              {/*</div>*/}
            </div>
          ))}
          <Button
            className="w_30 mt_20 "
            style={{ padding: 0, borderRadius: 5 + "px" }}
            design={"primary"}
            onClick={() => push({ name: "" })}>
            +
          </Button>
        </>
      )}
    </FieldArray>
  );
};
function Sanksiya(props) {
  const navLink = useNavigate();
  const { values, customData, method, title } = props;
  const { t } = useTranslation();
  const Info = ({ icon, data, label }) => {
    return (
      <div className="search">
        <h2 className="search_label">{label}</h2>
        <div className="search_data">
          <span>{data}</span>{" "}
          <span>
            {icon && (
              <svg
                width="15"
                height="15"
                viewBox="0 0 18 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16 2.3027H15V1.31029C15 0.764459 14.55 0.317871 14 0.317871C13.45 0.317871 13 0.764459 13 1.31029V2.3027H5V1.31029C5 0.764459 4.55 0.317871 4 0.317871C3.45 0.317871 3 0.764459 3 1.31029V2.3027H2C0.89 2.3027 0.00999999 3.19588 0.00999999 4.28754L0 18.1814C0 19.273 0.89 20.1662 2 20.1662H16C17.1 20.1662 18 19.273 18 18.1814V4.28754C18 3.19588 17.1 2.3027 16 2.3027ZM16 17.189C16 17.7348 15.55 18.1814 15 18.1814H3C2.45 18.1814 2 17.7348 2 17.189V7.26479H16V17.189ZM4 9.24962H6V11.2345H4V9.24962ZM8 9.24962H10V11.2345H8V9.24962ZM12 9.24962H14V11.2345H12V9.24962Z"
                  fill="#414141"
                />
              </svg>
            )}
          </span>
        </div>
      </div>
    );
  };
  const userData = JSON.parse(localStorage.getItem("userData"));
  const filters = {};
  if (userData.region) {
    filters["region"] = userData.region.id;
  }
  if (userData.vsx) {
    filters["vsx"] = userData.vsx.id;
  }
  return (
    <div>
      <Containers.Form
        customData={customData}
        validate={(event) => {
          console.log(event);
        }}
        method={"post"}
        url={"/prisoner/leave-room"}
        onSuccess={({ data }) => {
          method();
        }}
        onError={() => {
          method();
        }}
        fields={[
          {
            name: "time",

            value: get(values, "birthdate"),
            onSubmitValue: (value) => dayjs(dayjs()).format("YYYY-MM-DD HH:mm"),
          },

          {
            name: "investigatingOrganization",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "investigatingOrganization") ?? "",
            onSubmitValue: (value) => value.value,
          },
          {
            name: "responsibleOfficer",
            // validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "nationality") ?? "",
            onSubmitValue: (value) => userData.id,
          },
          {
            name: "injures",
            value: get(values, "injures"),
            validations: [{ type: "required" }],
            onSubmitValue: (value) => value,
          },
          {
            name: "document",
            value: get(values, "document") ?? "",
            validationType: "array",
            onSubmitValue: (value) => {
              if (!value) return null;
              return value;
            },
          },
          {
            name: "convoyOfficer",
            // validations: [{ type: "required" }],
            validationType: "array",
            value: get(values, "worker") ?? "",
            onSubmitValue: (value) => value.map((el) => el.name),
          },
        ]}>
        {({ isSubmitting, values, setFieldValue, ...rest }) => {
          return (
            <>
              <div className="row g-4">
                <div className="col-12">
                  <div className="row g-4">
                    {/*birthdate*/}
                    <div className="col-12">
                      <Info
                        label={t("san-out-employees")}
                        data={
                          userData?.firstName + "" + " " + userData?.sureName
                        }
                      />
                    </div>
                    <div className="col-12">
                      <FastField
                        name="investigatingOrganization"
                        component={Fields.AsyncSelect}
                        loadOptionsUrl={"/investigating-organizations"}
                        loadOptionsKey={(data) =>
                          data?.data?.map((el) => ({
                            label: el.name,
                            value: el.id,
                          }))
                        }
                        label={t("san-or")}
                        // placeholder={t("select-reason")}
                      />
                    </div>

                    <div className="col-12">
                      <FastField
                        name="injures"
                        style={{ height: 65 + "px" }}
                        component={Fields.Textarea}
                        hasTimeSelect
                        label={t("san-or-user")}
                        // placeholder={t("reason")}
                      />
                    </div>
                    <div className="col-12">
                      <FieldArrays
                        t={t}
                        products={
                          values?.convoyOfficer?.length
                            ? values.convoyOfficer
                            : [{ name: "" }]
                        }
                      />
                    </div>
                    {/*nationality*/}
                    <div className="col-12">
                      <h2 className="search_label">{t("file")}</h2>
                      <div className="file-upload">
                        <FastField
                          name="document"
                          title={t("file")}
                          isMultiple
                          component={AttachFile}
                          label={t("file")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  design="primary"
                  type="submit"
                  className="modal-btn-end fz_16 btn mt_40 w_full"
                  text={title.name}
                  isLoading={isSubmitting}
                />
              </div>
            </>
          );
        }}
      </Containers.Form>
    </div>
  );
}

export default Sanksiya;
