import React, { createContext, useContext, useState } from "react";

const RegionIdContext = createContext();

const RegionIdProvider = ({ children }) => {
  const [regionId, setRegionId] = useState(null);
  const toggleRegionId = (id) => {
    setRegionId(id);
  };

  return (
    <RegionIdContext.Provider value={{ regionId, toggleRegionId }}>
      {children}
    </RegionIdContext.Provider>
  );
};

const useRegionId = () => {
  const context = useContext(RegionIdContext);
  if (!context) {
    throw new Error("useDarkMode must be used within a DarkModeProvider");
  }
  return context;
};

export { RegionIdProvider, useRegionId };
