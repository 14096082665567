import { CameraRouter } from "./Camera/routes";
import { DashboardRouter } from "./Dashboard/router";
import { Employees } from "./Employees/router";
import { InspectionRouter } from "./Inspection/router";
import { RepoerRouter } from "./Reports/router";
import { SanitainRoute } from "./Sanitaion/router";
import { ArxivRouter } from "./Arxiv/routes";
import { SettingRouter } from "./Settings/router";
export { AuthRoutes } from "./Authorization/routes";
import { routeProfile } from "./Profile/route";
import { PrisonerTablet } from "./PrisonersTablet/router";
import { SpecialnoticeRouter } from "./Specialnotice/router";
import { PrisonersRouter } from "./Prisoners/router";
import { VisitorRoter } from "./Visitors/router";
import { VsxRouter } from "./VsxList/router";
import { PrisonersCameraRouter } from "./PrisonerCamera/router";
import { PrisonerTopSecr } from "./PrisonersTopSecrer/router";
import { EmergencyRouter } from "./Emergency/router";
import { CameraEditRouter } from "./CameraEdit/router";
import { EmployeesHistory } from "./EmployeeHistory/router";

export const MainRouter = [
  ...CameraRouter,
  ...DashboardRouter,
  ...Employees,
  ...InspectionRouter,
  ...RepoerRouter,
  ...SanitainRoute,
  ...ArxivRouter,
  ...CameraEditRouter,
  ...PrisonersRouter,
  ...PrisonersCameraRouter,
  ...SettingRouter,
  ...VisitorRoter,
  ...VsxRouter,
  ...PrisonerTopSecr,
  ...EmergencyRouter,
  ...SpecialnoticeRouter,
  ...PrisonerTablet,
  ...routeProfile,
  ...EmployeesHistory,
  {
    id: 34566,
    path: "*",
    element: <>404</>,
    roles: new Set(["Superadmin"]),
  },
];
