import React, { useMemo, useState } from "react";
import { ErrorMessage, FastField, Field, FieldArray } from "formik";
import { useSelector } from "react-redux";
import { get } from "lodash";

import { httpClient, queryBuilder, time } from "services";
import "../../Prisoners/styles/tintuv.scss";
import { ReactComponent as IconDelete } from "../../../assets/icons/delete.svg";
import Containers from "containers";
const FieldArraysItems = ({ name, products, t }) => {
  console.log(products, "===>>>>>");
  return (
    <FieldArray name={"items"}>
      {({ insert, remove, push }) => (
        <>
          {products.map((product, index, arr) => (
            <div className="row" key={index}>
              <div className="d-flex align-items-center mt_10" key={index}>
                <div style={{ width: 160 + "px" }} className="w_full">
                  <Field
                    name={`items.${index}.name`}
                    component={Fields.InputText}
                    // placeholder={}
                  />
                </div>
                {index + 1 === arr.length && index !== 0 && (
                  <Button
                    className="color_primary-red product__btn ml_20"
                    onClick={() => remove(index)}>
                    <IconDelete />
                  </Button>
                )}
              </div>
              <ErrorMessage
                name={`items.${index}.name`}
                component="span"
                className="field-error"
              />
              {/*</div>*/}
              {/*</div>*/}
            </div>
          ))}
          <Button
            className="w_30 mt_20 "
            style={{ padding: 0, borderRadius: 5 + "px" }}
            design={"primary"}
            onClick={() => push({ name: "" })}
            onKeyDown={() => push({ name: "" })}>
            +
          </Button>
        </>
      )}
    </FieldArray>
  );
};
const FieldArrays = ({ name, products, t }) => {
  // console.log(products, '===>>>>>')
  return (
    <FieldArray name={"prohibitedItems"}>
      {({ insert, remove, push }) => (
        <>
          {products.map((product, index, arr) => (
            <div className="row" key={index}>
              <div
                className="col-12 col-md-12 col-sm-12 mt_20 d-flex align-items-center "
                key={index}>
                {/*<div className="d-flex">*/}
                <div className="col-8">
                  <FastField
                    name={`prohibitedItems.${index}.key`}
                    component={Fields.AsyncSelect}
                    isPagination
                    loadOptionsUrl={"/prohibited-items"}
                    // loadOptionsUrl={"/users"}
                    loadOptionsKey={(data) => {
                      return data?.data?.map((el) => ({
                        label: el.name,
                        value: el.id,
                      }));
                    }}
                    // label={t("proxibid-product")}
                    // placeholder={t('medical-diagnosis')}
                  />
                </div>
                <div style={{ width: 120 + "px" }} className="col-4 w_full">
                  <Field
                    name={`prohibitedItems.${index}.amount`}
                    component={Fields.InputText}
                    // placeholder={}
                  />
                </div>
              </div>
              <ErrorMessage
                name={`prohibitedItems.${index}.amount`}
                component="span"
                className="field-error"
              />{" "}
              <br />
              <div className="ml_10 mt_10">
                {index + 1 === arr.length && index !== 0 && (
                  <Button
                    className="color_primary-red product__btn"
                    onClick={() => remove(index)}>
                    <IconDelete />
                  </Button>
                )}
              </div>
              {/*</div>*/}
              {/*</div>*/}
            </div>
          ))}
          <Button
            className="w_30 mt_20 "
            style={{ padding: 0, borderRadius: 5 + "px" }}
            design={"primary"}
            onClick={() => push({ key: [], amount: "" })}>
            +
          </Button>
        </>
      )}
    </FieldArray>
  );
};
import { Fields, Button, AvatarUpload, AttachFile } from "components";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
export const TForms = (props) => {
  const { values } = props;
  const navLink = useNavigate();
  const { t } = useTranslation();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const filter = {};
  if (userData.region) {
    filter["region"] = userData.region.id;
  }
  if (userData.vsx) {
    filter["vsx"] = userData.vsx.id;
  }
  const CustomSelect = React.memo(({ filters }) => {
    return (
      <>
        <div className="col-6 col-md-12 col-sm-6 col-lg-6 col-xl-6">
          <FastField
            name="basisDocumentPart"
            component={Fields.AsyncSelect}
            loadOptionsUrl={`/prisoner-basis-document-parts`}
            filters={filters}
            loadOptionsKey={(data) =>
              data?.data?.map((el) => ({
                label: el.name,
                value: el.id,
              }))
            }
            label={t("jk-role-section")}
            placeholder={t("select")}
          />
        </div>
      </>
    );
  });
  const [childpartId, setPartId] = useState(null);
  const handleFormOptions = (values, setFields) => {
    if (values.basisDocument?.value) {
      setPartId(values.basisDocument.value);
    }
  };
  return (
    <>
      <Containers.Form
        validate={(event) => {}}
        method={get(values, "id") ? "put" : "post"}
        url={
          get(values, "id")
            ? `/prisoner/create-prisoner/${get(values, "id")}`
            : "/prisoner/create-prisoner"
        }
        onError={(err) => {
          console.log(err)
        }}
        // onSuccess={({data}) => {
        //     get(values, "id") ? navLink(-1) : navLink(`/prisoner/medical/${data.id}/create`)
        // }}
        fields={[
          {
            name: "citizenship",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "citizenship") ?? "",
            onSubmitValue: (value) => value.value,
          },
          {
            name: "vsx",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "vsx") ?? "",
            onSubmitValue: (value) => value.value,
          },
          {
            name: "passport",
            value: get(values, "passport"),
            onSubmitValue: (value) => {
              return value;
            },
          },
          {
            name: "birthdate",
            validations: [{ type: "required" }],
            value: get(values, "birthdate"),
            onSubmitValue: (value) => time.timeFormater(value, "YYYY-MM-DD"),
          },
          // {
          //     name: "arestTime",
          //     // validations: [{type: "required"}],
          //     value: get(values, 'arestTime'),
          //     onSubmitValue: (value) => time.timeFormater(value, 'YYYY-MM-DD'),
          // },
          // {
          //     name: "organizationArestTime",
          //     // validations: [{type: "required"}],
          //     value: get(values, 'organizationArestTime'),
          //     onSubmitValue: (value) => time.timeFormater(value, 'YYYY-MM-DD'),
          // },
          {
            name: "firstName",
            validations: [{ type: "required" }],
            value: get(values, "firstName"),
            onSubmitValue: (value) => value,
          },
          {
            name: "sureName",
            validations: [{ type: "required" }],
            value: get(values, "sureName"),
            onSubmitValue: (value) => value,
          },
          {
            name: "middleName",
            validations: [{ type: "required" }],
            value: get(values, "middleName"),
            onSubmitValue: (value) => value,
          },
          // {
          //     name: "hasItems",
          //     value: get(values, 'hasItems'),
          //     onSubmitValue: (value) => {
          //         return value || null
          //     }
          // },
          // {
          //     name: "children",
          //     validationType: "array",
          //     value: [{fullName: '', birthLisenc: "", birthDate: "", birthPlace: ""}],
          //     lazy: (validator, yup) => validator.of(
          //             yup.object().shape({
          //                 fullName: yup.string(),
          //                 birthLisenc: yup.string(),
          //                 birthDate: yup.string(),
          //                 birthPlace: yup.string(),
          //             })
          //         ),
          //     onSubmitValue: (value) => value.map((item) => (item)),
          // },
          {
            name: "birthAddress",
            validations: [{ type: "required" }],
            value: get(values, "birthAddress"),
            onSubmitValue: (value) => value,
          },
          {
            name: "address",
            validations: [{ type: "required" }],
            value: get(values, "address"),
            onSubmitValue: (value) => value,
          },
          // {
          //     name: "responsibleMedWorkers",
          //     validations: [{type: "required"}],
          //     value: get(values, 'responsibleMedWorkers'),
          //     onSubmitValue: (value) => value,
          // },
          {
            name: "searchDocument",
            // validations: [{type: "required"}],
            validationType: "array",
            value: get(values, "searchDocument"),
            onSubmitValue: (value) => {
              if (!value) return null;
              return value;
            },
          },
          // {
          //     name: "medExamInfoDocument",
          //     // validations: [{type: "required"}],
          //     value: get(values, 'medExamInfoDocument'),
          //     onSubmitValue: (value) => {
          //         if(!value) return null;
          //         return  value;
          //     },
          // },
          // {
          //     name: "medExamDeedDocument",
          //     validationType: 'array',
          //     value: get(values, 'medExamDeedDocument'),
          //     onSubmitValue: (value) => {
          //         if(!value) return null;
          //         return  value
          //     },
          // },
          {
            name: "livingAddress",
            validations: [{ type: "required" }],
            value: get(values, "livingAddress"),
            onSubmitValue: (value) => value,
          },
          {
            name: "organizationOfficer",
            validations: [{ type: "required" }],
            value: get(values, "organizationOfficer"),
            onSubmitValue: (value) => value.value,
          },
          // {
          //     name: "arestOrganizationOfficer",
          //     // validations: [{type: "required"}],
          //     value: get(values, 'arestOrganizationOfficer'),
          //     onSubmitValue: (value) => value.value,
          // },
          // {
          //     name: "medExamDate",
          //     // validations: [{type: "required"}],
          //     value: get(values, 'medExamDate'),
          //     onSubmitValue: (value) => {
          //         if(value) return value;
          //         return  dayjs().format('YYYY-MM-DD HH:mm')
          //     },
          // },
          // {
          //     name: "medExamResponsibleOfficer",
          //     // validations: [{type: "required"}],
          //     value: get(values, 'medExamResponsibleOfficer'),
          //     onSubmitValue: (value) => {
          //         if(value) return value;
          //         return  JSON.parse(localStorage.getItem('userData')).id
          //     },
          // },
          {
            name: "isConvicted",
            validations: [{ type: "required" }],
            value: get(values, "isConvicted") ?? false,
            onSubmitValue: (value) => {
              return value;
            },
          },
          {
            name: "isTopSecret",
            value: get(values, "isTopSecret"),
            onSubmitValue: (value) => {
              return true;
            },
          },
          // {
          //     name: "hasChild",
          //     validations: [{type: "required"}],
          //     value: get(values, 'hasChild') ?? false,
          //     onSubmitValue: (value) => {
          //         return value
          //     },
          // },
          {
            name: "nationality",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "nationality") ?? "",
            onSubmitValue: (value) => value.value,
          },
          {
            name: "isInvalid",
            validations: [{ type: "required" }],
            value: get(values, "isInvalid") ?? false,
            onSubmitValue: (value) => {
              return value;
            },
          },
          // {
          //     name: "organizationPhone",
          //     value: get(values, 'organizationPhone'),
          //     onSubmitValue: (value) => {
          //         return value
          //     },
          // },
          {
            name: "gender",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "gender.data.id")
              ? {
                  label: get(values, "gender.data.name"),
                  value: get(values, "gender.data.id"),
                }
              : "",
            onSubmitValue: (value) => {
              return value.value;
            },
          },
          // {
          //     name: "basisDocumentPart",
          //     validations: [{type: "required"}],
          //     validationType: "object",
          //     value: get(values, 'gender.data.id') ? {
          //         label: get(values, 'gender.data.name'),
          //         value: get(values, 'gender.data.id')
          //     } : '',
          //     onSubmitValue: (value) => {
          //         return value.value
          //     }
          // },
          // {
          //     name: "invalidType",
          //     validations: [{type: "required"}],
          //     validationType: "object",
          //     value: get(values, 'gender.data.id') ? {
          //         label: get(values, 'gender.data.name'),
          //         value: get(values, 'gender.data.id')
          //     } : '',
          //     onSubmitValue: (value) => {
          //         return value.value
          //     }
          // },
          {
            name: "conviction",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "conviction.id")
              ? {
                  label: get(values, "conviction..name"),
                  value: get(values, "conviction.id"),
                }
              : "",
            onSubmitValue: (value) => {
              return value.value;
            },
          },
          // {
          //     name: "basisDocument",
          //     validations: [{type: "required"}],
          //     validationType: "object",
          //     value: get(values, 'basisDocument.id') ? {
          //         label: get(values, 'basisDocument.name'),
          //         value: get(values, 'basisDocument.id')
          //     } : '',
          //     onSubmitValue: (value) => {
          //         return value.value
          //     }
          // },
          {
            name: "appearance",
            validationType: "object",
            value: get(values, "appearance.id")
              ? {
                  label: get(values, "appearance.name"),
                  value: get(values, "appearance.id"),
                }
              : "",
            onSubmitValue: (value) => {
              return value.value;
            },
          },
          {
            name: "organization",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "gender.data.id")
              ? {
                  label: get(values, "gender.data.attributes.name"),
                  value: get(values, "gender.data.id"),
                }
              : "",
            onSubmitValue: (value) => {
              return value.value;
            },
          },
          {
            name: "items",
            value: get(values, "items"),
            validationType: "array",
            onSubmitValue: (value) => {
              if (value) return value.map((el) => el.name);
              return null;
            },
          },

          {
            name: "isLGBT",
            // validations: [{type: "required"}],
            value: `${get(values, "isLGBT")}` ?? false,
            onSubmitValue: (value) => {
              // console.log(value)
              const _item = value === "Ha" ? true : false;
              return _item;
            },
          },
        ]}>
        {({ isSubmitting, values, setFieldValue, ...rest }) => {
          handleFormOptions(values, setFieldValue);
          console.log(rest)
          return (
            <>
              <div className="row g-4">
                <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12">
                  <div className="row g-4">
                    <div className="col-4 col-sm-6 col-md-4">
                      <FastField
                        name="citizenship"
                        component={Fields.AsyncSelect}
                        loadOptionsUrl={"/citizenships"}
                        loadOptionsKey={(data) =>
                          data?.data?.map((el) => ({
                            label: el.name,
                            value: el.id,
                          }))
                        }
                        label={t("citizenship") + "*"}
                        placeholder={t("select")}
                      />
                    </div>{" "}
                    <div className="col-4 col-sm-6 col-md-4">
                      <FastField
                        name="vsx"
                        component={Fields.AsyncSelect}
                        loadOptionsUrl={"/vsxes"}
                        loadOptionsKey={(data) =>
                          data?.data?.map((el) => ({
                            label: el.name,
                            value: el.id,
                          }))
                        }
                        loadOptionsParams={(searchText) => ({
                          filters: {
                            region: filter.region,
                            id: filter.vsx,
                          },
                        })}
                        label={t("vsx") + "*"}
                        placeholder={t("select")}
                      />
                    </div>
                    {/*sureName*/}
                    <div className="col-4 col-sm-6 col-md-4">
                      <FastField
                        name="sureName"
                        component={Fields.InputText}
                        label={t("sure-name")}
                        placeholder={t("sure-name")}
                      />
                    </div>
                    {/*firstname*/}
                    <div className="col-4 col-sm-6 col-md-4">
                      <FastField
                        name="firstName"
                        component={Fields.InputText}
                        label={t("first-name")}
                        placeholder={t("first-name")}
                      />
                    </div>
                    {/*birthdate*/}
                    {/*<div className="col-4 col-sm-6 col-md-4"></div>*/}
                    <div className="col-4 col-sm-6 col-md-4">
                      <FastField
                        name="birthdate"
                        component={Fields.DatePicker}
                        hasTimeSelect
                        label={t("birthdate") + "*"}
                        placeholder={t("birthdate") + "*"}
                      />
                    </div>
                    {/*passport*/}
                    <div className="col-4 col-sm-6 col-md-4">
                      <FastField
                        name="passport"
                        component={Fields.InputText}
                        label={t("passport")}
                        placeholder={t("passport")}
                      />
                    </div>
                    {/*<div className="col-4 col-sm-6 col-md-4">*/}
                    {/*    <Button*/}
                    {/*        className="btn btn-primary mt_20"*/}
                    {/*        design="primary"*/}
                    {/*        style={{width: "100%"}}*/}
                    {/*        text={t('search')}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    {/*middlename*/}
                    <div className="col-4 col-sm-6 col-md-4">
                      <FastField
                        name="middleName"
                        component={Fields.InputText}
                        label={t("middle-name")}
                        placeholder={t("middle-name")}
                      />
                    </div>
                    {/*birthAddress*/}
                    <div className="col-4 col-sm-6 col-md-4">
                      <FastField
                        name="birthAddress"
                        component={Fields.InputText}
                        label={t("birthaddress")}
                        placeholder={t("birthaddress")}
                      />
                    </div>
                    {/*address*/}
                    <div className="col-4 col-sm-6 col-md-4">
                      <FastField
                        name="address"
                        component={Fields.InputText}
                        label={t("address")}
                        placeholder={t("address")}
                      />
                    </div>
                    {/*livingAddress*/}
                    <div className="col-4 col-sm-6 col-md-4">
                      <FastField
                        name="livingAddress"
                        component={Fields.InputText}
                        label={t("livingAddress")}
                        placeholder={t("livingAddress")}
                      />
                    </div>
                    {/*conviction*/}
                    <div className="col-4 col-sm-6 col-md-4">
                      <FastField
                        name="conviction"
                        component={Fields.AsyncSelect}
                        loadOptionsUrl={"/convictions"}
                        loadOptionsKey={(data) =>
                          data?.data?.map((el) => ({
                            label: el.name,
                            value: el.id,
                          }))
                        }
                        label={t("conviction")}
                        placeholder={t("select")}
                      />
                    </div>
                    {/*nationality*/}
                    <div className="col-4 col-sm-6 col-md-4">
                      <FastField
                        name="nationality"
                        component={Fields.AsyncSelect}
                        loadOptionsUrl={"/nationalities"}
                        loadOptionsKey={(data) =>
                          data?.data?.map((el) => ({
                            label: el.name,
                            value: el.id,
                          }))
                        }
                        label={t("nationality")}
                        placeholder={t("select")}
                      />
                    </div>
                    {/*gender*/}
                    <div className="col-4 col-sm-6 col-md-4">
                      <FastField
                        name="gender"
                        component={Fields.AsyncSelect}
                        loadOptionsUrl={"/genders"}
                        loadOptionsKey={(data) =>
                          data?.data?.map((el) => ({
                            label: el.name,
                            value: el.id,
                          }))
                        }
                        label={t("gender")}
                        placeholder={t("select")}
                      />
                    </div>
                    {/*organization*/}
                    <div className="col-4 col-sm-6 col-md-4">
                      <FastField
                        name="organization"
                        loadOptionsUrl={"/prisoner-organizations"}
                        component={Fields.AsyncSelect}
                        loadOptionsKey={({ data }) =>
                          data?.map((el) => ({
                            label: el.name,
                            value: el.id,
                          }))
                        }
                        label={t("where-service-employees")}
                        placeholder={t("select")}
                      />
                    </div>
                    {/*organizationOfficer*/}
                    <div className="col-4 col-sm-6 col-md-4">
                      <FastField
                        name="organizationOfficer"
                        component={Fields.InputText}
                        label={t("service-employees")}
                        placeholder={t("service-employees")}
                      />
                    </div>
                    <h2>{t("place-asign-data")}</h2>
                    <hr style={{ marginBottom: 20 + "px" }} />
                    <div className="row">
                      <div className="col-4 col-sm-6 col-md-4">
                        <h2 className="search_label">{t("minutes")}</h2>
                        <div className="file-upload">
                          <FastField
                            isMultiple
                            name="searchDocument"
                            title={t("file")}
                            component={AttachFile}
                            label={t("minutes")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  design="primary"
                  type="submit"
                  className="modal-btn-end fz_16 btn mt_40"
                  text={get(values, "id") ? t("updata") : t("save")}
                  isLoading={isSubmitting}
                />
              </div>
            </>
          );
        }}
      </Containers.Form>
    </>
  );
};
