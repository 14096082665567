import React, { useState } from "react";
import {
  Table,
  Pagination,
  CountUp,
  TextResize,
  InputSearch,
} from "components";
import { useTranslation } from "react-i18next";
import { useFetchList } from "hooks";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { time } from "../../../services/time";
import dayjs from "dayjs";
import { Span } from "modules/Prisoners/components";
import { Select } from "components/Fields";
import { useVsxMqMode } from "context/VsxMqContext";

function CameraTable() {
  const { t } = useTranslation();
  const navLink = useNavigate();
  const { vsxMode } = useVsxMqMode();
  const [searchParams, setSearchParams] = useSearchParams();
  const paginationData = searchParams.get("page");
  const [page, setPage] = useState({
    page: paginationData ?? paginationData + 1,
    pageSize: 10,
  });
  const userData = JSON.parse(localStorage.getItem("userData"));
  const isRole = localStorage.getItem("roleName") === "Superadmin";
  const roleName = localStorage.getItem("roleName") === "RegionalManager";
  const filter = {
    room: {
      vsx: {
        id: userData?.vsx?.id,
        label: "Markaziy VSX",
      },
    },
  };
  if (userData.region) {
    filter["region"] = userData.region.id;
  }
  const [search, setSearch] = useState({
    region: undefined,
    vsx: undefined,
  });
  const [filters, setFilters] = useState({
    name: undefined,
    value: undefined,
  });
  const prisonerList = useFetchList({
    url: "/prisoners",
    urlSearchParams: {
      pagination: {
        ...page,
      },
      filters: {
        isRegistered: true,
        room: {
          id: {
            $null: true,
          },
        },
        vsx: {
          id: filters.value ?? filter.room.vsx.id,
        },
        isLeftVsx: false,
      },
      populate: {
        person: "true",
        room: "true",
        search: "true",
        medExam: "true",
        vsx: "true",
      },
    },
  });

  const regionList = isRole
    ? useFetchList({
        url: "/regions",
        urlSearchParams: {
          pagination: {
            page: 1,
            pageSize: 1000,
          },

          populate: "*",
        },
      })
    : () => false;

  const vsxList = isRole
    ? useFetchList({
        url: "/vsxes",
        urlSearchParams: {
          pagination: {
            page: 1,
            pageSize: 1000,
          },
          populate: "*",
          filters: {
            region: search?.region?.id,
            isSpecial: vsxMode,
          },
        },
      })
    : () => false;

  const vsxListData = roleName
    ? useFetchList({
        url: "/vsxes",
        urlSearchParams: {
          pagination: {
            page: 1,
            pageSize: 1000,
          },
          populate: "*",
          filters: {
            region: userData?.region?.id,
            isSpecial: vsxMode,
          },
        },
      })
    : () => false;

  return (
    <>
      {isRole && (
        <InputSearch
          isInput
          splaceholder={t("select")}
          isSelect={isRole}
          value={filters}
          children={
            <>
              <Select
                value={search?.region}
                isClearable={true}
                options={regionList?.data?.map((el) => ({
                  id: el.id,
                  label: el.name,
                }))}
                placeholder={t("region-name-search")}
                onValueChange={(data) => {
                  data
                    ? setSearch((old) => ({ ...old, region: data }))
                    : setSearch((old) => ({ ...old, region: undefined }));
                }}
              />
            </>
          }
          items={vsxList?.data?.map((el) => ({ id: el.id, label: el.name }))}
          setValue={(data) => {
            if (data.name) {
              setFilters((old) => ({ ...old, name: data.name }));
            } else if (data.value) {
              setFilters((old) => ({ ...old, value: data.value }));
            } else {
              setFilters({
                name: undefined,
                value: undefined,
              });
            }
          }}
          placeholder={t("search")}
          text={
            t("camera-inspector") + "-" + prisonerList?.meta?.pagination?.total
          }
        />
      )}

      {roleName && (
        <InputSearch
          isInput
          splaceholder={t("select")}
          isSelect={roleName}
          value={filters}
          items={vsxListData?.data?.map((el) => ({
            id: el.id,
            label: el.name,
          }))}
          setValue={(data) => {
            if (data.name) {
              setFilters((old) => ({ ...old, name: data.name }));
            } else if (data.value) {
              setFilters((old) => ({ ...old, value: data.value }));
            } else {
              setFilters({
                name: undefined,
                value: undefined,
              });
            }
          }}
          placeholder={t("search")}
          text={
            t("prisoners-list-get") +
            "-" +
            prisonerList?.meta?.pagination?.total
          }
        />
      )}
      <Table
        emptyUiText={t("list-emptey")}
        isLoading={prisonerList.isLoading}
        className="prisoners-table-list"
        onRowClick={(row) => {
          row?.search && row?.medExam
            ? navLink(`/prisoner/medical/${row?.id}`)
            : navLink(`/prisoner-add-tablet-search/${row?.id}`);
        }}
        columns={[
          {
            title: t("number"),
            dataKey: "id",
            render: (value, item, index) => {
              return page.page * page.pageSize + index + 1 - page.pageSize;
            },
          },
          {
            title: t("reester"),
            dataKey: "id",
            render: (value, item, index) => {
              if (item) return <span>{item?.reesterNumber ?? "--"}</span>;
              return "--";
            },
          },
          {
            title: t("room"),
            dataKey: "id",
            render: (value, item, index) => {
              return item?.room?.name;
            },
          },

          {
            title: t("VSX"),
            dataKey: "vsx",
            render: (value, item, index) => {
              if (isRole) {
                return item?.vsx?.name;
              } else return "";
            },
          },

          {
            title: t("prisoner-time-name"),
            className: "white-space_no-wrap",
            dataKey: "sureName",
            render: (value, item) => {
              return Span(item);
            },
          },
          {
            title: t("birthdate"),
            dataKey: "amount",
            className: "white-space_no-wrap",
            render: (value, items) =>
              items?.person?.birthdate
                ? time.timeFormater(items?.person?.birthdate, "YYYY-MM-DD")
                : "--",
          },
          {
            title: t("catch-time"),
            dataKey: "amount",
            className: "white-space_no-wrap",
            render: (value, items) =>
              items?.organizationArestTime
                ? time.timeFormater(
                    items?.organizationArestTime,
                    "YYYY-MM-DD HH:mm"
                  )
                : "--",
          },

          {
            title: t("ever-day"),
            dataKey: "currency",
            className: "white-space_no-wrap",
            render: (value, items) =>
              items?.arestTime
                ? time.timeFormater(items?.arestTime, "YYYY-MM-DD HH:mm")
                : "-",
          },
          {
            title: t("main"),
            dataKey: "patient",
            className: "white-space_normal",
            render: (value, item) => (
              <TextResize
                text={item?.basisDocument?.name}
                url={item?.investigatorReport ? item?.investigatorReport : ""}
              />
            ),
          },
          {
            title: t("Asos ilovasi"),
            dataKey: "patient",
            className: "white-space_no-wrap prisoner__download",
            render: (value, item) => <></>,
          },
          {
            title: t("time-before"),
            dataKey: "patient",
            className: "white-space_no-wrap",
            render: (value, item) => item?.basisDocument?.period ?? "--",
          },

          {
            title: t("to-account"),
            dataKey: "patient",
            className: "white-space_no-wrap",
            render: (value, item) => item?.basisDocumentPart?.name,
          },
          {
            title: t("kimga-teg"),
            dataKey: "patient",
            className: "white-space_no-wrap",
            render: (value, item) => <span>{item?.organization?.name}</span>,
          },
          {
            title: t("respon-employess"),
            dataKey: "patient",
            className: "white-space_no-wrap",
            render: (value, item) =>
              item?.responsibleOfficer ? (
                <span>
                  {item?.responsibleOfficer?.sureName +
                    item?.responsibleOfficer?.firstName}
                </span>
              ) : (
                "--"
              ),
          },
          {
            title: t("eml-phone"),
            dataKey: "patient",
            className: "white-space_no-wrap",
            render: (value, item) => (
              <span>{item?.organizationPhone ?? "--"}</span>
            ),
          },

          {
            title: t("time-check"),
            dataKey: "patient",
            className: "white-space_no-wrap",
            render: (value, item, index) => {
              const nowDate = dayjs();
              const fotmat = dayjs(item.arestTime);
              return item.arestTime ? (
                <CountUp
                  timeou={item?.basisDocument?.period}
                  time={nowDate.diff(fotmat, "second")}
                />
              ) : (
                "--"
              );
            },
          },
        ]}
        items={prisonerList.data}
      />

      <Pagination
        currentPage={prisonerList?.meta?.pagination?.page}
        pageCount={prisonerList?.meta?.pagination?.pageCount}
        onPageChange={(newPage) => {
          setPage((old) => ({ ...old, page: newPage + 1 }));
          setSearchParams(createSearchParams({ page: newPage + 1 }));
        }}
      />
    </>
  );
}

export default CameraTable;
