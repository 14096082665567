import { useDarkMode } from "context/DarkModeContext";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./DarkMode.scss";
import Moon from "./Moon";
import Sun from "./Sun";
import { useVsxMqMode } from "context/VsxMqContext";

function DarkMode() {
  const { darkMode, toggleDarkMode } = useDarkMode();
  const { vsxMode, toggleVsxMq } = useVsxMqMode();

  const roleName = localStorage.getItem("roleName");
  const { t } = useTranslation();
  const handleClick = () => {
    toggleDarkMode();
  };

  const handleClickVsx = () => {
    toggleVsxMq();
  };


  return (
    <>
      {/* {roleName === "Superadmin" || roleName === "RegionalManager" ? (
        <div className="dark_mode">
          <input
            className="dark_mode_input"
            type="checkbox"
            id="dark_mode_input_vsx"
          />
          <label
            className="dark_mode_label vsx_mode-label"
            onClick={handleClickVsx}
            style={{ display: "flex" }}
            htmlFor="dark_mode_input_vsx">
            <p>VSX</p> <p>MQ</p>
          </label>
        </div>
      ) : (
        ""
      )} */}
      <div className="dark_mode">
        <input
          className="dark_mode_input"
          type="checkbox"
          id="darkmode-toggle"
        />
        {darkMode === true ? (
          <label
            onClick={handleClick}
            className="dark_mode_label"
            htmlFor="darkmode-toggle">
            <Sun /> <Moon />
          </label>
        ) : (
          <label
            onClick={handleClick}
            className="dark_mode_label"
            htmlFor="darkmode-toggle">
            <Moon /> <Sun />
          </label>
        )}
      </div>
    </>
  );
}

export default DarkMode;
