import React, { useState } from "react";
import { FastField } from "formik";
import { get } from "lodash";

import Containers from "containers";
import { ModalDefault, Fields, Button } from "components";
import { useTranslation } from "react-i18next";
import { useFetchList } from "hooks";
import { Select } from "components/Fields";

export const AddRoomsModal = ({
  isOpen,
  handleModalClose,
  onSuccess,
  values,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState({
    region: undefined,
    vsx: undefined,
  });
  const userData = JSON.parse(localStorage.getItem("userData"));

  const filters = {};
  if (userData.region) {
    filters["region"] = userData.region.id;
  }
  if (userData.vsx) {
    filters["vsx"] = userData.vsx.id;
  }
  const isRole = localStorage.getItem("roleName") === "Superadmin";
  const roleName = localStorage.getItem("roleName");

  const regionList = isRole
    ? useFetchList({
        url: "/regions",
        urlSearchParams: {
          pagination: {
            page: 1,
            pageSize: 1000,
          },

          populate: "*",
        },
      })
    : () => false;

  const vsxList = useFetchList({
    url: "/vsxes",
    urlSearchParams: {
      pagination: {
        page: 1,
        pageSize: 1000,
      },
      populate: "*",
      filters: {
        region: search?.region?.id,
      },
    },
  });

  return (
    <ModalDefault
      isOpen={isOpen}
      handleModalClose={handleModalClose}
      title={t("camera-add")}
      innerClass="max-width_500">
      <Containers.Form
        url="/rooms"
        method={"post"}
        onSuccess={() => {
          handleModalClose();
          onSuccess();
        }}
        fields={[
          {
            name: "name",
            validations: [{ type: "required" }],
            onSubmitValue: (value) => {
              return value;
            },
          },
          {
            name: "stage",
            validations: [{ type: "required" }],
            onSubmitValue: (value) => {
              return value;
            },
          },
          {
            name: "capacity",
            validations: [{ type: "required" }],
            onSubmitValue: (value) => {
              return Number(value);
            },
          },
          {
            name: "vsx",
            validationType: "object",
            onSubmitValue: (value) => {
              return search?.vsx?.id ?? userData?.vsx?.id;
            },
          },
          {
            name: "region",
            validationType: "object",
            onSubmitValue: (value) => {
              return search?.region?.id ?? userData?.region?.id;
            },
          },
          {
            name: "responsibleUser",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "responsibleUser.data.id")
              ? {
                  label: get(
                    values,
                    "responsibleUser.data.attributes.username"
                  ),
                  value: get(values, "responsibleUser.data.id"),
                }
              : "",
            onSubmitValue: (value) => {
              return value.value;
            },
          },
        ]}>
        {({ isSubmitting, values, ...rest }) => {
          // console.log(rest)
          return (
            <>
              <div className="row g-4">
                <div className="col-12">
                  <FastField
                    name="name"
                    component={Fields.InputText}
                    label={t("room-name")}
                    placeholder={t("room-name")}
                  />
                </div>
                <div className="col-12">
                  <FastField
                    name="stage"
                    component={Fields.InputText}
                    label={t("stage-name")}
                    type={"number"}
                  />
                </div>
                <div className="col-12">
                  <FastField
                    name="capacity"
                    component={Fields.InputText}
                    label={t("room-size")}
                    placeholder={t("room-size")}
                  />
                </div>
                {roleName === "VsxManager" ? (
                  ""
                ) : (
                  <div className="col-12">
                    <Select
                      value={search?.region}
                      isClearable={true}
                      options={regionList?.data?.map((el) => ({
                        id: el.id,
                        label: el.name,
                      }))}
                      label={t("region-name-search")}
                      onValueChange={(data) => {
                        data
                          ? setSearch((old) => ({ ...old, region: data }))
                          : setSearch((old) => ({ ...old, region: undefined }));
                      }}
                    />
                  </div>
                )}
                {roleName === "VsxManager" ? (
                  ""
                ) : (
                  <div className="col-12">
                    <Select
                      value={search?.vsx}
                      options={vsxList?.data?.map((el) => ({
                        id: el.id,
                        label: el.name,
                      }))}
                      label={t("VSX-name")}
                      onValueChange={(data) => {
                        data
                          ? setSearch((old) => ({ ...old, vsx: data }))
                          : setSearch((old) => ({ ...old, vsx: undefined }));
                      }}
                    />
                  </div>
                )}
                <div className="col-12">
                  <FastField
                    name="responsibleUser"
                    loadOptionsUrl={"/users"}
                    loadOptionsParams={(searchText) => ({
                      filters: { ...filters },
                    })}
                    component={Fields.AsyncSelect}
                    loadOptionsKey={(data) =>
                      data?.map((el) => ({ label: el.username, value: el.id }))
                    }
                    label={t("responsible-employees")}
                    placeholder={t("select")}
                  />
                </div>
              </div>
              <Button
                design="primary"
                type="submit"
                className="modal-btn fz_16 btn mt_40"
                text={t("camera-add")}
                isLoading={isSubmitting}
              />
            </>
          );
        }}
      </Containers.Form>
    </ModalDefault>
  );
};
