import React from "react";
import { ErrorMessage, FastField, Field, FieldArray } from "formik";
import { get } from "lodash";

import Containers from "containers";
import {
  ModalDefault,
  Fields,
  Button,
  FileUpload,
  AvatarUpload,
  AttachFile,
  Info,
} from "components";
import { UploadBase } from "components/Upload/UploadBase";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { ReactComponent as IconDelete } from "../../../assets/icons/delete.svg";
const FieldArrays = ({ name, products, t }) => {
  console.log(products, "===>>>>>");
  return (
    <FieldArray name={name}>
      {({ insert, remove, push }) => (
        <>
          {products.map((product, index, arr) => (
            <div className="row" key={index}>
              <div
                className="col-12 col-md-12 col-sm-12 mt_20 d-flex align-items-center "
                key={index}>
                {/*<div className="d-flex">*/}
                <div className="col-12">
                  <FastField
                    name={`prohibitedItems.${index}.amount`}
                    component={Fields.AsyncSelect}
                    isPagination
                    loadOptionsUrl={"/prohibited-items"}
                    // loadOptionsUrl={"/users"}
                    loadOptionsKey={(data) => {
                      console.log(data, "========>>>>");
                      return data?.data?.map((el) => ({
                        label: el.name,
                        value: el.id,
                      }));
                    }}
                    // label={t("proxibid-product")}
                    // placeholder={t('medical-diagnosis')}
                  />
                </div>
                {/*<div style={{width: 120+"px"}} className='col-4 w_full'>*/}
                {/*    <Field*/}
                {/*        name={`prohibitedItems.${index}.amount`}*/}
                {/*        component={Fields.InputText}*/}
                {/*        // placeholder={}*/}

                {/*    />*/}
                {/*</div>*/}
              </div>
              <ErrorMessage
                name={`prohibitedItems.${index}.amount`}
                component="span"
                className="field-error"
              />{" "}
              <br />
              <div className="ml_10 mt_10">
                {index + 1 === arr.length && index !== 0 && (
                  <Button
                    className="color_primary-red product__btn"
                    onClick={() => remove(index)}>
                    <IconDelete />
                  </Button>
                )}
              </div>
              {/*</div>*/}
              {/*</div>*/}
            </div>
          ))}
          <Button
            className="w_30 mt_20 "
            style={{ padding: 0, borderRadius: 5 + "px" }}
            design={"primary"}
            onClick={() => push({ key: [], amount: "" })}>
            +
          </Button>
        </>
      )}
    </FieldArray>
  );
};
export const SearchModal = ({
  isOpen,
  handleOverlayClose,
  values,
  refetch,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const filters = {};
  if (userData.region) {
    filters["region"] = userData.region.id;
  }
  if (userData.vsx) {
    filters["vsx"] = userData.vsx.id;
  }
  return (
    <ModalDefault
      isOpen={isOpen}
      handleModalClose={handleOverlayClose}
      title={t("search-to")}
      innerClass="max-width_500">
      <div>
        <Containers.Form
          customData={{ prisoner: Number(id) }}
          validate={(event) => {
            //   console.log(event);
          }}
          method={"post"}
          url={"/searches"}
          onSuccess={({ data }) => {
            // method();
            handleOverlayClose();
          }}
          onError={() => {
            handleOverlayClose();
            // method();
          }}
          fields={[
            {
              name: "searchDate",
              // validations: [{ type: "required" }],
              value: get(values, "time"),
              onSubmitValue: (value) => dayjs().format("YYYY-MM-DD HH:mm"),
            },
            {
              name: "prisoner",
              value: get(values, "id"),
              onSubmitValue: (value) => id,
            },
            {
              name: "searchResponsibleOfficer",
              // validations: [{ type: "required" }],
              validationType: "object",
              value: get(values, "nationality") ?? "",
              onSubmitValue: (value) => userData.id,
            },
            // {
            //     name: "injuresFile",
            //     value: get(values, 'injures'),
            //     onSubmitValue: (value) => {
            //         if(value) return value;
            //         return  null
            //     },
            // },
            {
              name: "prohibitedItems",
              // validations: [{ type: "required" }],
              validationType: "array",
              value: get(values, "prohibitedItems"),
              onSubmitValue: (value) => {
                if (value.length) return value.map((el) => el.amount.value);
              },
            },
            {
              name: "items",
              value: get(values, "items"),
              onSubmitValue: (value) => value,
            },

            // {
            //     name: "isInjured",
            //     // validations: [{type: "required"}],
            //     value: `${get(values, 'isLGBT')}` ?? false,
            //     onSubmitValue: (value) => {
            //         // console.log(value)
            //         const _item = value === 'Ha' ? true : false
            //         return _item;
            //     }
            // },
            // {
            //     name: "hasItems",
            //     // validations: [{type: "required"}],
            //     value: `${get(values, 'hasItems')}` ?? false,
            //     onSubmitValue: (value) => {
            //         // console.log(value)
            //         const _item = value === 'Ha' ? true : false
            //         return _item;
            //     }
            // },
            {
              name: "hasProhibitedItems",
              // validations: [{type: "required"}],
              value: `${get(values, "hasItems")}` ?? false,
              onSubmitValue: (value) => {
                // console.log(value)
                const _item = value === "Ha" ? true : false;
                return _item;
              },
            },
          ]}>
          {({ isSubmitting, values, setFieldValue, ...rest }) => {
            console.log(values, rest);
            return (
              <>
                <div className="row g-4">
                  <Info
                    label={t("get-emp")}
                    data={userData?.firstName + " " + userData?.sureName}
                  />
                  {values.isInjured === "Ha" && (
                    <div className="col-12">
                      <FastField
                        name="items"
                        component={Fields.InputText}
                        label={t("injuries-has")}
                        // placeholder="Фамилия"
                      />
                    </div>
                  )}
                  {values.isInjured === "Ha" && (
                    <div className="col-12">
                      <h2 className="search_label">{t("file")}</h2>
                      <div className="file-upload">
                        <FastField
                          name="injuresFile"
                          title={t("file")}
                          component={AttachFile}
                          label={t("minutes")}
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-12">
                    <p
                      className="mb_20 control__label "
                      style={{ fontSize: 14 + "px" }}>
                      {t("search-to")}
                    </p>
                    <div className="d-flex">
                      <span>
                        <FastField
                          name="hasProhibitedItems"
                          component={Fields.RadioButton}
                          label={t("yes")}
                          value={t("yes")}
                        />
                      </span>
                      <span className="ml_10">
                        <FastField
                          name="hasProhibitedItems"
                          component={Fields.RadioButton}
                          label={t("no")}
                          value={t("no")}
                        />
                      </span>
                    </div>
                  </div>

                  {values.hasProhibitedItems === "Ha" && (
                    <div className="col-12">
                      <FieldArrays
                        name={"prohibitedItems"}
                        products={
                          values?.prohibitedItems?.length
                            ? values.prohibitedItems
                            : [{ amount: [] }]
                        }
                        t={t}
                      />
                    </div>
                  )}
                </div>
                {/*</div>*/}
                {/*</div>*/}
                <div className="d-flex justify-content-end">
                  <Button
                    design="primary"
                    type="submit"
                    className="modal-btn-end fz_16 btn mt_40 w_full"
                    text={t("save")}
                    isLoading={isSubmitting}
                  />
                </div>
              </>
            );
          }}
        </Containers.Form>
      </div>
    </ModalDefault>
  );
};
