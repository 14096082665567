import { Button } from "components";
import dayjs from "dayjs";
import { useFetchList } from "hooks";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { httpClient, time } from "services";

function Sanitation() {
  const { t } = useTranslation();
  const { id } = useParams();
  const sanitationData = useFetchList({
    url: `/sanitations/${id}`,
    urlSearchParams: {
      populate: "type",
    },
  });

  const handleClickSanitation = async () => {
    try {
      const response = await httpClient.put(`/sanitations/${id}`, {
        isManagerConfirmed: true,
      });
      if (response.status === 200) {
        toast.success(t("Muvaffaqiyatli tasdiqlandi!"));
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="mt_40">
      <div style={{ gap: 10 }} className="d-flex align-items-center">
        <h2 style={{ fontSize: "22px", color: "var(--text-color)" }}>
          {t("event-name")}:
        </h2>
        <h3 style={{ fontSize: "18px", color: "var(--text-color)" }}>
          {sanitationData?.data?.data?.type?.name}
        </h3>
      </div>
      <div style={{ gap: 10 }} className="d-flex align-items-center mt_20">
        <h2 style={{ fontSize: "22px", color: "var(--text-color)" }}>
          {t("event-user")}:
        </h2>
        <h3 style={{ fontSize: "18px", color: "var(--text-color)" }}>
          {sanitationData?.data?.data?.organizationNickname || "__"}
        </h3>
      </div>
      <div style={{ gap: 10 }} className="d-flex align-items-center mt_20">
        <h2 style={{ fontSize: "22px", color: "var(--text-color)" }}>
          {t("event-time-date")}:
        </h2>
        <h3 style={{ fontSize: "18px", color: "var(--text-color)" }}>
          {time.timeFormater(sanitationData?.data?.data?.date, "YYYY-MM-DD")}
        </h3>
      </div>
      <div style={{ gap: 10 }} className="d-flex align-items-center mt_20">
        <h2 style={{ fontSize: "22px", color: "var(--text-color)" }}>
          {t("next-event")}:
        </h2>
        <h3 style={{ fontSize: "18px", color: "var(--text-color)" }}>
          {sanitationData?.data?.data?.nextSanitationDate}
        </h3>
      </div>
      <div className="d-flex justify-content-end mt_20">
        <Button
          onClick={handleClickSanitation}
          className="btn mt_20"
          design="primary"
          text={t("Tasdiqlash")}
        />
      </div>
    </div>
  );
}

export default Sanitation;
