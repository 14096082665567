import { Button, ModalDefault } from "components";
import Containers from "containers";
import React, { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import { useTranslation } from "react-i18next";

function InspectorModal({ handleModalClose, isOpen, setInspector }) {
  const { t } = useTranslation();
  const inspectorRef = useRef(null);

  const saveAsSVG = () => {
    if (inspectorRef.current) {
      const svgData = inspectorRef.current.toDataURL("image/svg+xml");
      setInspector(svgData);
    }
    handleModalClose();
  };

  const clearCanvas = () => {
    if (inspectorRef.current) {
      inspectorRef.current.clear();
    }
  };
  return (
    <ModalDefault
      isOpen={isOpen}
      handleModalClose={handleModalClose}
      title={t("imzolash")}
      innerClass="max-width_500">
      <div>
        <Containers.Form
          validate={(event) => {}}
          onSuccess={({ data }) => {
            handleModalClose();
          }}
          onError={() => {
            handleModalClose();
          }}
          fields={[]}>
          {({ isSubmitting, values, setFieldValue, ...rest }) => {
            return (
              <>
                <SignatureCanvas
                  penColor="#0d47a1"
                  ref={inspectorRef}
                  canvasProps={{
                    width: 500,
                    height: 200,
                    className: "sigCanvas",
                  }}
                />
                <div className="d-flex justify-content-end">
                  <Button
                    design="cancel"
                    className="modal-btn-end fz_16 btn mt_40 w_full mr-3"
                    text={t("clear")}
                    onClick={(event) => {
                      event.preventDefault();
                      clearCanvas();
                    }}
                  />
                  <Button
                    design="primary"
                    className="modal-btn-end fz_16 btn mt_40 w_full"
                    text={t("save")}
                    onClick={(event) => {
                      event.preventDefault();
                      saveAsSVG();
                    }}
                  />
                </div>
              </>
            );
          }}
        </Containers.Form>
      </div>
    </ModalDefault>
  );
}

export default InspectorModal;
