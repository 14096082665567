import React, { Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { constants } from "services";

import { MainLayout, AuthLayout } from "layouts";
import { Spinner } from "components";
import { AuthRoutes, MainRouter } from "modules";


const appRoutes = [
  {
    path: "/",
    layout: <AuthLayout />,
    subRoutes: [...AuthRoutes],
    access: constants.UNAUTHORIZED,
  },
  {
    path: "/",
    layout: <MainLayout />,
    subRoutes: [...MainRouter],
    access: constants.AUTHORIZED,
  },
];

const MainRoutes = ({ routes }) => (
  <Routes>
    {routes.map((route, index) => (
      <React.Fragment key={index}>
        {route.layout ? (
          <Route path={route.path} element={route.layout}>
            {route?.subRoutes?.map((subRoute, innerIndex) => {
              return (
                <Route
                  index={subRoute.index}
                  key={innerIndex}
                  {...subRoute}
                  element={<Suspense>{subRoute.element}</Suspense>}
                />
              );
            })}
            <Route path="*" element={<>404</>} />
          </Route>
        ) : (
          <Route {...route} />
        )}
      </React.Fragment>
    ))}
  </Routes>
);
const AuhtRoutes = ({ routes }) => (
  <Routes>
    {routes.map((route, index) => (
      <React.Fragment key={index}>
        {route.layout ? (
          <Route path={route.path} element={route.layout}>
            {route?.subRoutes?.map((subRoute, innerIndex) => (
              <Route
                index={subRoute.index}
                key={innerIndex}
                {...subRoute}
                element={
                  <Suspense fallback={<Spinner />}>{subRoute.element}</Suspense>
                }
              />
            ))}
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        ) : (
          <Route {...route} />
        )}
      </React.Fragment>
    ))}

  </Routes>
);

const authorizedRoutes = appRoutes.filter(
  (item) => item.access === constants.AUTHORIZED
);
export const AuthorizedRoutes = () => <MainRoutes routes={authorizedRoutes} />;
const unAuthorizedRoutes = appRoutes.filter(
  (item) => item.access === constants.UNAUTHORIZED
);
export const UnAuthorizedRoutes = () => (
  <AuhtRoutes routes={unAuthorizedRoutes} />
);
