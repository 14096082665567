import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import dayjs from "dayjs";
import store from "store";
import "services/i18n";
import { App } from "App";
import { Overlay } from "layouts";

Number.prototype.toCountFormat = function () {
  const formattedNumber = this.toFixed(2).replace(
    /\d(?=(\d{3})+\.)|(\.00$)/g,
    "$&,"
  );
  return formattedNumber.split(".")[0];
};

import "assets/styles/all.scss";
import { DarkModeProvider } from "context/DarkModeContext";
import { VsxMqContextProvider } from "context/VsxMqContext";
import { RegionIdProvider } from "context/RegionIdContext";
import { VSXIdProvider } from "context/VsxIdContext";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
    mutations: {
      retry: false,
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <Provider store={store}>
      <RegionIdProvider>
        <VSXIdProvider>
          <VsxMqContextProvider>
            <DarkModeProvider>
              <QueryClientProvider client={queryClient}>
                <App />
                <Overlay />
              </QueryClientProvider>
            </DarkModeProvider>
          </VsxMqContextProvider>
        </VSXIdProvider>
      </RegionIdProvider>
    </Provider>
  </Router>
);
