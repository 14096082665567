import EmployeeHistory from "./page/EmployeeHistory";

export const EmployeesHistory = [
  {
    id: 5,
    path: "/employees-history",
    // element: <Outgo />,
    element: <EmployeeHistory />,
    roles: new Set(["Superadmin"]),
  },
];
