import React from "react";
import { isFunction } from "lodash";

import { Button, DropDown, Fields } from "components";
import { Breadcrumb, Statistics } from "./components";
import { utils } from "../../services";
import "./PageHeading.scss";
import { NavLink } from "react-router-dom";

export const PageHeading = (props) => {
  const {
    links,
    btnText,
    cameraText,
    mainAction,
    cameraEditPage,
    headerTile,
    statistics,
    isFilter,
    isRegion,
    isDisabled,
    NavLink,
    navText,
    style,
  } = props;
  return (
    <div style={style} className="page-heading">
      <div className="page-heading__inner">
        {/*<Breadcrumb links={links} />*/}
        <h1 className="page-header__inner__title">{headerTile}</h1>

        <div className="page-heading__button-right">
          {isFunction(NavLink) && (
            <Button
              className="btn page-heading__btn"
              design="primary"
              text={navText}
              onClick={NavLink}
            />
          )}
          {isFunction(cameraEditPage) && (
            <Button
              className="btn page-heading__btn"
              design="primary"
              text={cameraText}
              isDisabled={isDisabled}
              onClick={cameraEditPage}
            />
          )}
          {isFunction(mainAction) && (
            <Button
              className="btn page-heading__btn"
              design="primary"
              text={btnText}
              isDisabled={isDisabled}
              onClick={mainAction}
            />
          )}
        </div>
        {isFilter && (
          <Fields.RangePicker
            size="xsm"
            className="filter__control mr_15"
            onDateChange={(date) => {
              const { start_at, end_at } = utils.formatters.getRange(date);
              setFieldValue("range", date);
              setFilter((prev) => ({
                ...prev,
                start_at,
                end_at,
              }));
            }}
          />
        )}
      </div>

      {/*<Statistics statistics={statistics} />*/}
    </div>
  );
};
