import React, { useState } from "react";
import { Avatar, Pagination, Table } from "../../../components";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Span } from "./Span";
import { useFetchList } from "../../../hooks";
import { useParams } from "react-router-dom";

export const PrisonerEventHistory = (props) => {
  const { t } = useTranslation();
  const { region, id } = useParams();
  const [page, setPage] = useState({ page: 1, pageSize: 10 });
  const visitor = useFetchList({
    url: `/prisoner-room-leaves`,
    urlSearchParams: {
      pagination: {
        ...page,
      },
      filters: {
        reason: {
          id: {
            $ne: 10,
          },
        },
        prisoner: {
          id,
        },
      },
      populate:
        "responsibleOfficer,visitor,visitor.image,vsx,prisoner,prisoner.person,prisoner.room, reason",
    },
  });

  return (
    <div>
      <>
        <Table
          emptyUiText={t("list-emptey")}
          className="table-prisoner-event-history"
          isLoading={visitor.isLoading}
          columns={[
            {
              title: t("number"),
              dataKey: "id",
              render: (value, item, index) => {
                return index + 1;
              },
            },
            {
              title: t("where-name"),
              className: "white-space_no-wrap",
              dataKey: "attributes.sureName",
              render: (value, item) => {
                return item?.reason?.name;
              },
            },
            {
              title: t("who-user"),
              dataKey: "currency",
              render: (value, item) =>
                item.responsibleOfficer
                  ? `${
                      item?.responsibleOfficer?.sureName +
                      " " +
                      item?.responsibleOfficer?.firstName
                    }`
                  : "--",
            },
            {
              title: t("where-time"),
              dataKey: "patient",
              className: "white-space_no-wrap",
              render: (value, item) => {
                if (item?.time)
                  return dayjs(item?.time).format("YYYY-MM-DD HH:mm");
                return "--";
              },
            },
            {
              title: t("get-to-room"),
              dataKey: "patient",
              className: "white-space_no-wrap",
              render: (value, item) => {
                if (item?.time)
                  return dayjs(item?.endTime ?? dayjs()).format(
                    "YYYY-MM-DD HH:mm"
                  );
                return "--";
              },
            },
          ]}
          items={visitor?.data?.length ? visitor?.data : []}
        />
        <Pagination
          currentPage={visitor.meta?.pagination?.page}
          pageCount={visitor.meta?.pagination?.pageCount}
          onPageChange={(newPage) =>
            setPage((old) => ({ ...old, page: newPage + 1 }))
          }
        />
      </>
    </div>
  );
};
