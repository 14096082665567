import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Containers from "../../../../containers";
import { get } from "lodash";
import { time } from "../../../../services";
import { AttachFile, Button, Fields } from "../../../../components";
import { FastField, FieldArray } from "formik";
import dayjs from "dayjs";
import { useFetchList } from "hooks";
import { AsyncPaginate } from "react-select-async-paginate";

function FormFood(props) {
  const navLink = useNavigate();
  const { values, customData, method, title } = props;
  const { t } = useTranslation();
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedIds, setSelectedIds] = useState([1]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const filters = {};
  if (userData.region) {
    filters["region"] = userData.region.id;
  }
  if (userData.vsx) {
    filters["vsx"] = userData.vsx.id;
  }
  const prisonerList = useFetchList({
    url: "/prisoner-illnesses",
  });

  const loadOptions = async () => {
    try {
      if (!prisonerList || !prisonerList.data) {
        throw new Error("Prisoner list is not available");
      }
      const data = prisonerList?.data;
      const formattedOptions = data?.map((prisoner) => ({
        value: prisoner.id,
        label: `${prisoner.name}`,
      }));

      return {
        options: formattedOptions,
      };
    } catch (error) {
      console.error("Error fetching options:", error);
      return { options: [] };
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      const optionsArray = Array.isArray(selectedOption)
        ? selectedOption
        : [selectedOption];
      const ids = optionsArray.map((option) => option.value);
      setSelectedIds((prevSelectedIds) => [...prevSelectedIds, ...ids]);
    }
  };
  return (
    <div>
      <Containers.Form
        customData={customData}
        validate={(event) => {
          //   console.log(event);
        }}
        method={"post"}
        url={"/prisoner/leave-room"}
        onSuccess={({ data }) => {
          method();
        }}
        onError={() => {
          method();
        }}
        fields={[
          {
            name: "time",
            // validations: [{ type: "required" }],
            value: get(values, "time"),
            onSubmitValue: (value) => dayjs(dayjs()).format("YYYY-MM-DD HH:mm"),
          },
          {
            name: "medArriveTime",
            validations: [{ type: "required" }],
            value: get(values, "medArriveTime"),
            onSubmitValue: (value) => dayjs().format("YYYY-MM-DD HH:mm"),
          },
          {
            name: "medCallTime",
            validations: [{ type: "required" }],
            value: get(values, "time"),
            onSubmitValue: (value) => dayjs().format("YYYY-MM-DD HH:mm"),
          },
          {
            name: "worker",
            validations: [{ type: "required" }],
            value: get(values, "worker"),
            onSubmitValue: (value) => value,
          },

          {
            name: "illnesses",
            validations: [{ type: "required" }],
            validationType: "array",
            value: get(values, "illnesses") ?? selectedIds,
            onSubmitValue: (value) => selectedIds?.slice(1),
          },
          {
            name: "takenMeasures",
            validations: [{ type: "required" }],
            value: get(values, "medicalDiagnosis"),
            onSubmitValue: (value) => value,
          },
          {
            name: "responsibleOfficer",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "nationality") ?? "",
            onSubmitValue: (value) => value.value,
          },
          {
            name: "helperOfficer",
            // validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "helperOfficer") ?? "",
            onSubmitValue: (value) => value.value,
          },

          {
            name: "isInjured",
            value: get(values, "isInjured") ?? false,
            onSubmitValue: (value) => {
              return value;
            },
          },
        ]}>
        {({ isSubmitting, values, setFieldValue, ...rest }) => {
          // console.log(values, rest)
          return (
            <>
              <div className="row g-4">
                <div className="col-12">
                  <div className="row g-4">
                    {/*birthdate*/}
                    <div className="col-12">
                      <FastField
                        name="worker"
                        component={Fields.InputText}
                        label={t("medical-name-fullname")}
                        // placeholder={t('medical-name')}
                      />
                    </div>
                    <div className="col-12">
                      <FieldArray
                        name="illnesses"
                        render={(arrayHelpers) => (
                          <div style={{ width: "100%" }}>
                            {values.illnesses.map((ro, index) => (
                              <div
                                style={{ width: "100%", gap: "10px" }}
                                key={index}>
                                <AsyncPaginate
                                  loadOptions={loadOptions}
                                  placeholder={t("medical-diagnosis")}
                                  styles={{ width: "100%" }}
                                  getOptionValue={(option) => {}}
                                  getOptionLabel={(option) => option.label}
                                  isSearchable={false}
                                  additional={{
                                    page: 1,
                                  }}
                                  onChange={handleSelectChange}
                                />
                                <button
                                  type="button"
                                  style={{
                                    fontSize: "18px",
                                    background: "var(--stroke-input)",
                                    padding: "3px 10px",
                                    color: "#fff",
                                    margin: "5px 0",
                                  }}
                                  onClick={() => {
                                    arrayHelpers.remove(index);
                                    const updatedUserIds = [...selectedUserIds];
                                    updatedUserIds.splice(index, 1);
                                    setSelectedUserIds(updatedUserIds);
                                  }}>
                                  -
                                </button>
                              </div>
                            ))}
                            <button
                              type="button"
                              style={{
                                fontSize: "18px",
                                background: "var(--stroke-input)",
                                padding: "3px 8px",
                                color: "#fff",
                              }}
                              onClick={() => {
                                if (
                                  values.illnesses.every((ro) => ro && ro.label)
                                ) {
                                  arrayHelpers.push({});
                                  setSelectedUserIds([...selectedUserIds, ""]);
                                } else {
                                  arrayHelpers.push({});
                                }
                              }}>
                              +
                            </button>
                          </div>
                        )}
                      />
                    </div>

                    <div className="col-12">
                      <FastField
                        name="takenMeasures"
                        style={{ height: 100 + "px" }}
                        component={Fields.Textarea}
                        label={t("taken-measures")}
                        // placeholder={t('medical-diagnosis')}
                      />
                    </div>
                    <div className="col-12">
                      <FastField
                        name="medCallTime"
                        component={Fields.DatePicker}
                        hasTimeSelect
                        maskformat={"####-##-## ##:##"}
                        format={"YYYY-MM-DD HH:mm"}
                        label={t("med-call-time") + "*"}
                        placeholder={t("med-call-time")}
                      />
                    </div>
                    <div className="col-12">
                      <FastField
                        name="medArriveTime"
                        component={Fields.DatePicker}
                        hasTimeSelect
                        maskformat={"####-##-## ##:##"}
                        format={"YYYY-MM-DD HH:mm"}
                        label={t("med-arrive-time") + "*"}
                        placeholder={t("med-arrive-time")}
                      />
                    </div>
                    <div className="col-12">
                      <FastField
                        name="responsibleOfficer"
                        component={Fields.AsyncSelect}
                        loadOptionsUrl={"/users"}
                        loadOptionsKey={(data) =>
                          data?.map((el) => ({
                            label: el.username,
                            value: el.id,
                          }))
                        }
                        loadOptionsParams={(searchText) => ({
                          filters: { ...filters },
                        })}
                        label={t("check-evnet-out-empl")}
                        placeholder={t("select")}
                      />
                    </div>
                    <div className="col-12">
                      <h3 className="mb_20">{t("mode-send")}</h3>
                      <FastField
                        name="isInjured"
                        component={Fields.CheckBox}
                        // placeholder="Отасининг исми"
                      />
                    </div>
                    {values.isInjured && (
                      <div className="col-12">
                        <FastField
                          name="helperOfficer"
                          component={Fields.AsyncSelect}
                          loadOptionsUrl={"/users"}
                          loadOptionsKey={(data) =>
                            data?.map((el) => ({
                              label: el.username,
                              value: el.id,
                            }))
                          }
                          loadOptionsParams={(searchText) => ({
                            filters: { ...filters },
                          })}
                          label={t("protection-out-user")}
                          placeholder={t("select")}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <Button
                  design="primary"
                  type="submit"
                  className="modal-btn-end fz_16 btn mt_40 w_full"
                  text={title.name}
                  isLoading={isSubmitting}
                />
              </div>
            </>
          );
        }}
      </Containers.Form>
    </div>
  );
}

export default FormFood;
