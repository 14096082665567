import { InputSearch, TabBase } from "components";
import { Select } from "components/Fields";
import { useVsxMqMode } from "context/VsxMqContext";
import { useFetchList } from "hooks";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import EmployeeHistoryTable from "../components/Table";
import { AsyncPaginate } from "react-select-async-paginate";
import { httpClient } from "services";

const loadOptions = async (search) => {
  const filters = search
    ? `filters[$or][1][firstName][$containsi]=${search}`
    : "";
  try {
    const jkUrl = `/users-permissions/get-users-list?${filters}&pagination[pageSize]=1000`;
    const jkModdaList = await httpClient.get(jkUrl);
    const data = jkModdaList?.data?.data?.results;
    const formattedOptions = data?.map((prisoner) => ({
      value: prisoner.id,
      label: `${prisoner.firstName} ${prisoner.sureName}  ${prisoner.middleName}`,
    }));

    return {
      options: formattedOptions,
    };
  } catch (error) {
    console.error("Error fetching options:", error);
    return { options: [] };
  }
};

function EmployeeHistory() {
  const { t } = useTranslation();
  const { vsxMode } = useVsxMqMode();
  const [historyId, setHistoryId] = useState("1");
  const [employeeId, setEmployeeId] = useState();
  let list = [];
  const isRole = localStorage.getItem("roleName") === "Superadmin";
  const [currentTabName, SetCurrentTabName] = useState(
    "Kameradan olib chiqish"
  );
  const [search, setSearch] = useState({
    region: undefined,
    vsx: undefined,
  });

  const [filters, setFilters] = useState({
    name: undefined,
    value: undefined,
  });

  const employeeMenuList = useFetchList({
    url: "/officer-action-types",
    urlSearchParams: {
      populate: "*",
    },
  });

  const handleTabe = (even) => {
    SetCurrentTabName(even.name);
  };

  useEffect(() => {}, [employeeMenuList.data]);

  const regionList = isRole
    ? useFetchList({
        url: "/regions",
        urlSearchParams: {
          pagination: {
            page: 1,
            pageSize: 1000,
          },
          populate: "*",
        },
      })
    : () => false;

  const vsxList = isRole
    ? useFetchList({
        url: "/vsxes",
        urlSearchParams: {
          pagination: {
            page: 1,
            pageSize: 1000,
          },
          populate: "*",
          filters: {
            region: search?.region?.id ?? 15,
            isSpecial: vsxMode,
          },
        },
      })
    : () => false;
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
        }}
        className="mb_20">
        <AsyncPaginate
          loadOptions={loadOptions}
          styles={{
            width: "100%",
            border: "2px solid var(--input-border)",
          }}
          name="basisDocumentPart"
          className="mt_20 w_340 mr_15"
          required
          placeholder={t("select")}
          getOptionValue={(option) => {}}
          getOptionLabel={(option) => option.label}
          isSearchable={true}
          additional={{
            page: 1,
          }}
          onChange={(e) => setEmployeeId(e?.value)}
        />
        {isRole && (
          <InputSearch
            splaceholder={t("select")}
            isSelect={isRole}
            value={filters}
            children={
              <>
                <Select
                  value={search?.region}
                  isClearable={true}
                  options={regionList?.data?.map((el) => ({
                    id: el.id,
                    label: el.name,
                  }))}
                  placeholder={t("region-name-search")}
                  onValueChange={(data) => {
                    data
                      ? setSearch((old) => ({ ...old, region: data }))
                      : setSearch((old) => ({ ...old, region: undefined }));
                  }}
                />
              </>
            }
            items={vsxList?.data?.map((el) => ({ id: el.id, label: el.name }))}
            setValue={(data) => {
              if (data.name) {
                setFilters((old) => ({ ...old, name: data.name }));
              } else if (data.value) {
                setFilters((old) => ({ ...old, value: data.value }));
              } else {
                setFilters({
                  name: undefined,
                  value: undefined,
                });
              }
            }}
          />
        )}
      </div>
      <TabBase
        onPaneChange={(even) => {
          handleTabe(even);
          setHistoryId(even?.id);
        }}
        isObjeckt
        labels={employeeMenuList?.data?.filter((el) => !list.includes(el.id))}
        currentLabel={currentTabName}
      />
      <EmployeeHistoryTable employeeId={employeeId} historyId={historyId} />
    </>
  );
}

export default EmployeeHistory;
