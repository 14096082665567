import { lazy } from "react";
import DashboardInfo from "./sub-pages/dashboardInfo";

const Dashboard = lazy(() => import("../Dashboard/page/Dashboard"));
const DashboardEmployee = lazy(() =>
  import("../Dashboard/page/DashboardEmployee")
);
const DashboardItemInfo = lazy(() =>
  import("../Dashboard/sub-pages/dashboardItemInfo")
);
const DashboardMain = lazy(() => import("../Dashboard/page/DashboardMain"));
export const DashboardRouter = [
  {
    id: 23342343,
    path: "/dashboard",
    // element: <Income />,
    element: <DashboardMain />,
    roles: new Set([
      "RegionalManager",
      "VsxManager",
      "VsxInspector",
      "SuperDuperAdmin",
    ]),
  },
  {
    id: 23342343,
    path: "/dashboard-item-info/:name/:populate",
    // element: <Income />,
    element: <DashboardItemInfo />,
    roles: new Set([
      "RegionalManager",
      "VsxManager",
      "VsxInspector",
      "SuperDuperAdmin",
    ]),
  },
  {
    id: 23342343,
    path: "/dashboard-iteminfo/:name/:populate",
    // element: <Income />,
    element: <DashboardInfo />,
    roles: new Set([
      "RegionalManager",
      "VsxManager",
      "VsxInspector",
      "SuperDuperAdmin",
    ]),
  },
];
