import React, { useState } from "react";
import { FastField } from "formik";
import { Search } from "react-feather";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Fields } from "components";
import Containers from "containers";

function SearchAdvokat(props) {
  const [reesterNumber, setReesterNumber] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const { setValue, isResetForm, valuesUpdate, setAdvocat, setWorkers } = props;
  const { t } = useTranslation();

  return (
    <>
      <Containers.Form
        isResetForm={isResetForm}
        method={"get"}
        url={`/v2/vsx/get-advocate-data-from-adliya-by-order-number-and-certificate-number?orderNumber=${orderNumber}&certificateNumber=${reesterNumber}`}
        fields={[
          {
            name: "orderNumber",
            validations: [{ type: "required" }],
            onSubmitValue: (value) => {
              return value;
            },
          },
          {
            name: "certificateNumber",
            validations: [{ type: "required" }],
            onSubmitValue: (value) => {
              return value;
            },
          },
        ]}
        onSuccess={(data, formValue) => {
          const advocate = data?.data;
          advocate.isChecked = true;
          setAdvocat((prevAdvocat) => [...prevAdvocat, advocate]);
          setWorkers((prevAdvocat) => [...prevAdvocat, advocate]);
          setValue({ ...data.data, ...formValue, disabled: true });
        }}
        onError={(data) => {}}>
        {({ isSubmitting, values }) => (
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <FastField
                    name="orderNumber"
                    component={Fields.InputText}
                    label="Order raqami"
                    onChange={(e) => setOrderNumber(e.target.value)}
                  />
                </div>
                <div className="col-10 mt_10">
                  <FastField
                    name="certificateNumber"
                    component={Fields.InputText}
                    label="Guvohnoma raqami"
                    onChange={(e) => setReesterNumber(e.target.value)}
                  />
                </div>
                <div className="col-1 mt_20">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="fz_16 btn btn__primary d-flex mt_20">
                    {/* {isSubmitting && <span className="btn__spinner"></span>} */}
                    <Search />
                  </button>
                </div>
                {valuesUpdate?.hadDangerousItemsBefore && (
                  <span style={{ color: "red" }}>
                    {" "}
                    {t("had-dangerous-items-before")}
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
      </Containers.Form>
    </>
  );
}

export default SearchAdvokat;
