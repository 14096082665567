import CameraEditTable from "./page/CameraTable";

export const CameraEditRouter = [
  {
    id: 7,
    path: "/camera-edit",
    element: <CameraEditTable />,
    roles: new Set([
      "RegionalManager",
      "SuperDuperAdmin",
      "VsxManager",
      "SpecialVsxManager",
      "Superadmin",
    ]),
  },
];
