import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { serialize } from "object-to-formdata";
import { get, isFunction } from "lodash";

import { httpClient } from "services";

import { Typography } from "components";
import { UploadBase } from "./UploadBase";
import { ReactComponent as AttachIcon } from "assets/icons/attach.svg";
let list = [];
const openToNewTab = (subUrl) => {
  const url = process.env.REACT_APP_IMAGE_BASE_URL;
  const a_tag = document.createElement("a");
  a_tag.setAttribute("href", url + subUrl);
  a_tag.setAttribute("target", "_blank");
  a_tag.click();
};
export const AttachFile = ({
  title = "Прикрепить файл",
  accept = ".png,.jpg,.jpeg,.pdf",
  getFile,
  isMultiple = false,
  form,
  field,
}) => {
  const [file, setFile] = useState();
  const [files, setFiles] = useState([]);
  // console.log(field, form)
  const handleImageUpload = (event) => {
    // const files = serialize({ files: event.target.files });

    const image = event?.target?.files[0];
    const formdata = new FormData();
    formdata.append("files", image);
    httpClient
      .post("/upload", formdata)
      .then(async ({ data }) => {
        list.push(data[0]);
        setFiles((old) => list);
        if (isMultiple) {
          form.setFieldValue(
            field.name,
            list.map((el) => el.id)
          );
        } else {
          form.setFieldValue(field.name, data[0].id);
          setFile(event.target.files[0]);
        }
      })
      .catch((error) => {
        form.setFieldValue(field.name, null);
        setFiles([]);
      });
  };
  // isDisabled={file}
  useEffect(() => {
    if (form?.values[field.name] === "") {
      list = [];
      setFile(null);
      // console.log()
    }
  }, [form?.values[field.name]]);
  return (
    <div>
      <div>
        {isMultiple &&
          form?.values[field.name] &&
          files.length > 0 &&
          files.map((el, index) => {
            return (
              <div key={index}>
                <a
                  href={process.env.REACT_APP_IMAGE_BASE_URL + el.url}
                  target={"_blank"}>
                  {el.name}
                </a>{" "}
                <br />
              </div>
            );
          })}
      </div>
      {Boolean(files?.length) && <br />}
      <UploadBase
        className="d-flex align-items-center justify-content-center"
        accept={accept}
        isMultiple={isMultiple}
        onFileUpload={handleImageUpload}>
        <AttachIcon className="mr_10" />
        {/*<span className="loader"></span>*/}
        <Typography
          Type="span"
          className="color_txt-secondary fz_18"
          text={file ? file.name : title}
        />
      </UploadBase>
    </div>
  );
};

AttachFile.propTypes = {
  title: PropTypes.string,
  accept: PropTypes.string,
  getFile: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};
