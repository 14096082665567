import React, { useState } from "react";
import { ErrorMessage, FastField, Field, FieldArray } from "formik";
import { useSelector } from "react-redux";
import { get, isFunction, isObject } from "lodash";

import {
  useNotification,
  useOverlay,
} from "hooks";
import { time } from "services";
import Containers from "containers";
import { Fields, Button, AvatarUpload, AttachFile } from "components";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { ControlLabel } from "../../../components/Common";
import { toast } from "react-toastify";
export const EForms = (props) => {
  const { values, onSuccessValue, isRoute = true, isData } = props;
  const navLink = useNavigate();
  const notifier = useNotification();
  const { region } = useParams();
  const { t } = useTranslation();
  const BaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  const [childpartId, setPartId] = useState(null);
  const handleFormOptions = (values, setFields) => {
    if (values.basisDocument?.value) {
      setPartId(values.basisDocument.value);
    }
  };
  return (
    <>
      <Containers.Form
        isData={isData}
        validate={(event) => {}}
        method={get(values, "id") ? "put" : "post"}
        url={
          get(values, "id")
            ? `/users/${get(values, "id")}`
            : "/users-permissions/create-user"
        }
        onSuccess={(data) => {
          // console.log(data)
          isFunction(onSuccessValue) && onSuccessValue(data);
          get(values, "id")
            ? notifier.success(t("user-updata"))
            : notifier.success(t("employees-success-add"));
          isRoute && navLink(-1);
        }}
        onError={(err) => {
          if (err.status === 400) {
            toast.error("Bu xodim avval qo'shilgan!");
          }
        }}
        fields={[
          {
            name: "image",
            value: get(values, "image") ? get(values, "image") : "",
            onSubmitValue: (value) => {
              if (!value) return null;
              return value;
            },
          },
          {
            name: "passport",
            validations: [{ type: "required" }],
            value: get(values, "passport") ?? "",
            onSubmitValue: (value) => {
              return value;
            },
          },
          {
            name: "birthDate",
            validations: [{ type: "required" }],
            value: get(values, "birthDate"),
            onSubmitValue: (value) => time.timeFormater(value, "YYYY-MM-DD"),
          },
          {
            name: "jobStartDate",
            validations: [{ type: "required" }],
            value: get(values, "jobStartDate"),
            onSubmitValue: (value) => time.timeFormater(value, "YYYY-MM-DD"),
          },
          {
            name: "comeFrom",
            validations: [{ type: "required" }],
            value: get(values, "comeFrom"),
            onSubmitValue: (value) => value,
          },
          {
            name: "firstName",
            validations: [{ type: "required" }],
            value: get(values, "firstName"),
            onSubmitValue: (value) => value,
          },
          {
            name: "sureName",
            validations: [{ type: "required" }],
            value: get(values, "sureName"),
            onSubmitValue: (value) => value,
          },
          {
            name: "positionAssign",
            validations: [{ type: "required" }],
            value: get(values, "positionAssign"),
            onSubmitValue: (value) => value,
          },
          {
            name: "pinfl",
            validations: [{ type: "required" }],
            value: get(values, "pinfl"),
            onSubmitValue: (value) => {
              return value;
            },
          },
          {
            name: "middleName",
            validations: [{ type: "required" }],
            value: get(values, "middleName"),
            onSubmitValue: (value) => value,
          },
          {
            name: "birthAddress",
            validations: [{ type: "required" }],
            value: get(values, "birthAddress"),
            onSubmitValue: (value) => value,
          },
          {
            name: "address",
            validations: [{ type: "required" }],
            value: get(values, "address"),
            onSubmitValue: (value) => value,
          },
          {
            name: "livingAddress",
            validations: [{ type: "required" }],
            value: get(values, "livingAddress"),
            onSubmitValue: (value) => value,
          },
          {
            name: "carInfo",
            // validations: [{type: "required"}],
            value: get(values, "livingAddress"),
            onSubmitValue: (value) => {
              if (!value) return null;
              return value;
            },
          },
          {
            name: "childrenCount",
            // validations: [{type: "required"}],
            value: get(values, "childrenCount"),
            onSubmitValue: (value) => {
              if (!value) return 0;
              return Number(value);
            },
          },
          {
            name: "anotherReasonText",
            // validations: [{type: "required"}],
            value: get(values, "anotherReasonText") ?? "",
            onSubmitValue: (value) => {
              if (!value) return null;
              return value;
            },
          },
          {
            name: "hasChild",
            validations: [{ type: "required" }],
            value: get(values, "hasChild") ?? false,
            onSubmitValue: (value) => {
              return value;
            },
          },
          {
            name: "nationality",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "nationality")
              ? {
                  label: get(values, "nationality.name"),
                  value: get(values, "nationality.id"),
                }
              : "",
            onSubmitValue: (value) => value.value,
          },

          {
            name: "gender",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "gender")
              ? {
                  label: get(values, "gender.name"),
                  value: get(values, "gender.id"),
                }
              : "",
            onSubmitValue: (value) => {
              return value.value;
            },
          },
          {
            name: "reason",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "reason.id")
              ? {
                  label: get(values, "reason.name"),
                  value: get(values, "reason.id"),
                }
              : "",
            onSubmitValue: (value) => {
              return value.value;
            },
          },
          {
            name: "rank",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "rank.id")
              ? {
                  label: get(values, "rank.name"),
                  value: get(values, "rank.id"),
                }
              : "",
            onSubmitValue: (value) => {
              return value.value;
            },
          },
          {
            name: "position",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "position.id")
              ? {
                  label: get(values, "position.name"),
                  value: get(values, "position.id"),
                }
              : "",
            onSubmitValue: (value) => {
              return value.value;
            },
          },
          {
            name: "graduation",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "graduation.id")
              ? {
                  label: get(values, "graduation.name"),
                  value: get(values, "graduation.id"),
                }
              : "",
            onSubmitValue: (value) => {
              return value.value;
            },
          },
          {
            name: "familyStatus",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "familyStatus.id")
              ? {
                  label: get(values, "familyStatus.name"),
                  value: get(values, "familyStatus.id"),
                }
              : "",
            onSubmitValue: (value) => {
              delete value.label;
              return { id: value.value, hasChild: value.hasChild };
            },
          },
          {
            name: "hasCar",
            value: `${get(values, "hasCar")}` ?? false,
            onSubmitValue: (value) => {
              const _item = value === "Ha" ? true : false;
              return _item;
            },
          },
        ]}>
        {({ isSubmitting, values, setFieldValue, ...rest }) => {
          handleFormOptions(values, setFieldValue);
          return (
            <>
              <div className="row g-4">
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                  <FastField
                    name="image"
                    component={AvatarUpload}
                    className="avatar-upload"
                    getImage={(image) => {
                      return BaseUrl + get(image, "0.url");
                    }}
                  />
                </div>
                <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12">
                  <div className="row g-4">
                    {/*sureName*/}
                    <div className="col-4 col-sm-6 col-md-4">
                      <FastField
                        name="sureName"
                        component={Fields.InputText}
                        label={t("sure-name")}
                        placeholder={t("sure-name")}
                      />
                    </div>
                    {/*firstname*/}
                    <div className="col-4 col-sm-6 col-md-4">
                      <FastField
                        name="firstName"
                        component={Fields.InputText}
                        label={t("first-name")}
                        placeholder={t("first-name")}
                      />
                    </div>
                    {/*birthDate*/}
                    <div className="col-4 col-sm-6 col-md-4">
                      <FastField
                        name="middleName"
                        component={Fields.InputText}
                        label={t("middle-name")}
                        placeholder={t("middle-name")}
                      />
                    </div>
                    {/*passport*/}
                    <div className="col-4 col-sm-6 col-md-4">
                      <FastField
                        name="passport"
                        component={Fields.InputText}
                        label={t("passport")}
                        placeholder={t("passport")}
                        required
                      />
                    </div>
                    <div className="col-4 col-sm-6 col-md-4">
                      <FastField
                        name="pinfl"
                        component={Fields.InputText}
                        label={t("pinfl-name")}
                        placeholder={t("pinfl-name")}
                        required
                      />
                    </div>
                    {/*middlename*/}
                    <div className="col-4 col-sm-6 col-md-4">
                      <FastField
                        name="birthDate"
                        component={Fields.DatePicker}
                        hasTimeSelect
                        label={t("birthdate") + "*"}
                        placeholder={t("birthdate") + "*"}
                      />
                    </div>
                    {/*birthAddress*/}
                    <div className="col-4 col-sm-6 col-md-4">
                      <FastField
                        name="birthAddress"
                        component={Fields.InputText}
                        label={t("birthaddress")}
                        placeholder={t("birthaddress")}
                      />
                    </div>
                    {/*address*/}
                    <div className="col-4 col-sm-6 col-md-4">
                      <FastField
                        name="address"
                        component={Fields.InputText}
                        label={t("address")}
                        placeholder={t("address")}
                      />
                    </div>
                    {/*livingAddress*/}
                    <div className="col-4 col-sm-6 col-md-4">
                      <FastField
                        name="livingAddress"
                        component={Fields.InputText}
                        label={t("livingAddress")}
                        placeholder={t("livingAddress")}
                      />
                    </div>
                    {/*nationality*/}
                    <div className="col-4 col-sm-6 col-md-4">
                      <FastField
                        name="nationality"
                        component={Fields.AsyncSelect}
                        loadOptionsUrl={"/nationalities"}
                        loadOptionsKey={(data) =>
                          data?.data?.map((el) => ({
                            label: el.name,
                            value: el.id,
                          }))
                        }
                        label={t("nationality")}
                        placeholder={t("select")}
                      />
                    </div>
                    {/*gender*/}
                    <div className="col-4 col-sm-6 col-md-4">
                      <FastField
                        name="gender"
                        component={Fields.AsyncSelect}
                        loadOptionsUrl={"/genders"}
                        loadOptionsKey={(data) =>
                          data?.data?.map((el) => ({
                            label: el.name,
                            value: el.id,
                          }))
                        }
                        label={t("gender")}
                        placeholder={t("select")}
                      />
                    </div>
                    <div className="col-4 col-md-6 col-xl-4 col-lg-4">
                      <ControlLabel label={t("personal-car")} /> <br />
                      <div className="d-flex">
                        <span>
                          <FastField
                            name="hasCar"
                            component={Fields.RadioButton}
                            label={t("yes")}
                            value={t("yes")}
                          />
                        </span>
                        <span className="ml_10">
                          <FastField
                            name="hasCar"
                            component={Fields.RadioButton}
                            label={t("no")}
                            value={t("no")}
                          />
                        </span>
                      </div>
                    </div>
                    {values.hasCar === t("yes") && (
                      <div className="col-4 col-sm-6 col-md-4">
                        <FastField
                          name="carInfo"
                          component={Fields.InputText}
                          label={t("car-number-data")}
                          placeholder={t("car-number-data")}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="row g-4">
                    {/*graduation*/}
                    <div className="col-3 col-md-4 col-lg-4 col-xl-4 col-lg-3 col-sm-6">
                      <FastField
                        name="graduation"
                        component={Fields.AsyncSelect}
                        loadOptionsUrl={"/user-graduations"}
                        loadOptionsKey={(data) =>
                          data?.data?.map((el) => ({
                            label: el.name,
                            value: el.id,
                          }))
                        }
                        label={t("graduation")}
                        placeholder={t("select")}
                      />
                    </div>
                    {/*rank*/}
                    <div className="col-3 col-md-4 col-lg-4 col-xl-4 col-lg-3 col-sm-6">
                      <FastField
                        name="rank"
                        component={Fields.AsyncSelect}
                        loadOptionsUrl={"/user-ranks"}
                        loadOptionsKey={(data) =>
                          data?.data?.map((el) => ({
                            label: el.name,
                            value: el.id,
                          }))
                        }
                        label={t("rank")}
                        placeholder={t("select")}
                      />
                    </div>
                    {/*position*/}
                    <div className="col-3 col-md-4 col-lg-4 col-xl-4 col-lg-3 col-sm-6">
                      <FastField
                        name="position"
                        component={Fields.AsyncSelect}
                        loadOptionsUrl={"/user-positions"}
                        loadOptionsKey={(data) =>
                          data?.data?.map((el) => ({
                            label: el.name,
                            value: el.id,
                          }))
                        }
                        label={t("positions")}
                        placeholder={t("select")}
                      />
                    </div>
                    {/*familyStatus*/}
                    <div className="col-3 col-md-4 col-lg-4 col-xl-4 col-lg-3 col-sm-6">
                      <FastField
                        name="familyStatus"
                        component={Fields.AsyncSelect}
                        loadOptionsUrl={"/user-family-statuses"}
                        loadOptionsKey={(data) =>
                          data?.data?.map((el) => ({
                            label: el.name,
                            value: el.id,
                            hasChild: el.hasChild,
                          }))
                        }
                        label={t("family-status")}
                        placeholder={t("select")}
                      />
                    </div>
                    {values?.familyStatus?.hasChild && (
                      <div className="col-3 col-md-4 col-lg-4 col-xl-4 col-lg-3 col-sm-6">
                        <FastField
                          name="childrenCount"
                          component={Fields.InputText}
                          label={t("child-count")}
                          placeholder={t("child-count")}
                        />
                      </div>
                    )}
                    {/*jobStartDate*/}
                    <div className="col-3 col-md-4 col-lg-4 col-xl-4 col-lg-3 col-sm-6">
                      <FastField
                        name="jobStartDate"
                        component={Fields.DatePicker}
                        label={t("working-time")}
                        placeholder={t("working-time")}
                      />
                    </div>
                    {/*positionAssign*/}
                    <div className="col-3 col-md-4 col-lg-4 col-xl-4 col-lg-3 col-sm-6">
                      <FastField
                        name="positionAssign"
                        component={Fields.InputText}
                        label={t("positions-assign")}
                        placeholder={t("positions-assign")}
                      />
                    </div>
                    {/*comeFrom*/}
                    <div className="col-3 col-md-4 col-lg-4 col-xl-4 col-lg-3 col-sm-6">
                      <FastField
                        name="comeFrom"
                        component={Fields.InputText}
                        label={t("field-get")}
                        placeholder={t("field-get")}
                      />
                    </div>
                    {/*reason*/}
                    <div className="col-3 col-md-4 col-lg-4 col-xl-4 col-lg-3 col-sm-6">
                      <FastField
                        name="reason"
                        component={Fields.AsyncSelect}
                        loadOptionsUrl={"/user-reasons"}
                        loadOptionsKey={(data) =>
                          data?.data?.map((el) => ({
                            label: el.name,
                            value: el.id,
                            list: el.isDiff,
                          }))
                        }
                        label={t("user-reason")}
                        placeholder={t("select")}
                      />
                    </div>
                    {/*anotherReasonText*/}
                    {values.reason.list && (
                      <div className="col-3 col-md-4 col-lg-4 col-xl-4 col-lg-3 col-sm-6">
                        <FastField
                          name="anotherReasonText"
                          component={Fields.InputText}
                          label={t("reason")}
                          placeholder={t("reason")}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  design="cancel"
                  type="submit"
                  className="modal-btn-end fz_16 btn mt_40"
                  text={t("cansel")}
                  isLoading={isSubmitting}
                />{" "}
                <Button
                  design="primary"
                  type="submit"
                  className="modal-btn-end fz_16 btn mt_40"
                  text={get(values, "id") ? t("updata") : t("save")}
                  isLoading={isSubmitting}
                />
              </div>
            </>
          );
        }}
      </Containers.Form>
    </>
  );
};
