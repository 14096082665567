import React, {useState} from 'react';
import *as XLSX from 'xlsx';
export const ArrayBuffer = ({ arrayBuffer }) => {
    const [data, setData] = useState(null);
    const handleRender = () => {
        try {
            const arrayBufferView = new Uint8Array(arrayBuffer);
            const blob = new Blob([arrayBufferView], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const workbook = XLSX.read(blob, { type: 'blob' });

            // Assuming there is only one sheet, you may need to adjust based on your use case
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            // Convert sheet data to JSON
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            setData(jsonData);
        } catch (error) {
            console.error('Error rendering Excel:', error.message);
        }
    };
    return (
        <div>
            <button onClick={handleRender}>Render Excel</button>
            {data && (
                <table>
                    <thead>
                    <tr>
                        {data[0].map((cell, index) => (
                            <th key={index}>{cell}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {data.slice(1).map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((cell, cellIndex) => (
                                <td key={cellIndex}>{cell}</td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}
