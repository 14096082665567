import React, { useState, useEffect } from "react";
import Containers from "../../../../containers";
import { get } from "lodash";
import { FieldArray } from "formik";
import { Button } from "../../../../components";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../../Prisoners/styles/tintuv.scss";
import { AsyncPaginate } from "react-select-async-paginate";
import { useFetchList } from "hooks";

function FormSayirga(props) {
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const navLink = useNavigate();
  const { values, customData, method, title, isTitle } = props;
  const { t } = useTranslation();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const Info = ({ icon, data, label }) => {
    return (
      <div className="search">
        <h2 className="search_label">{label}</h2>
        <div className="search_data">
          <span>{data}</span>{" "}
          <span>
            {icon && (
              <svg
                width="15"
                height="15"
                viewBox="0 0 18 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16 2.3027H15V1.31029C15 0.764459 14.55 0.317871 14 0.317871C13.45 0.317871 13 0.764459 13 1.31029V2.3027H5V1.31029C5 0.764459 4.55 0.317871 4 0.317871C3.45 0.317871 3 0.764459 3 1.31029V2.3027H2C0.89 2.3027 0.00999999 3.19588 0.00999999 4.28754L0 18.1814C0 19.273 0.89 20.1662 2 20.1662H16C17.1 20.1662 18 19.273 18 18.1814V4.28754C18 3.19588 17.1 2.3027 16 2.3027ZM16 17.189C16 17.7348 15.55 18.1814 15 18.1814H3C2.45 18.1814 2 17.7348 2 17.189V7.26479H16V17.189ZM4 9.24962H6V11.2345H4V9.24962ZM8 9.24962H10V11.2345H8V9.24962ZM12 9.24962H14V11.2345H12V9.24962Z"
                  fill="#414141"
                />
              </svg>
            )}
          </span>
        </div>
      </div>
    );
  };
  const [regionName, setRegionName] = useState(null);
  const [selectedIds, setSelectedIds] = useState([1]);
  useEffect(() => {
    setRegionName(props.regionName);
  }, [props.regionName]);

  const filters = {};
  if (userData.region) {
    filters["region"] = userData.region.id;
  }
  if (userData.vsx) {
    filters["vsx"] = userData.vsx.id;
  }
  const prisonerList = useFetchList({
    url: "/users",
    urlSearchParams: {
      filters: {
        ...filters,
        id: {
          $ne: userData.id,
        },
      },
    },
  });

  const loadOptions = async (search, loadedOptions, { page }) => {
    try {
      if (!prisonerList || !prisonerList.data) {
        throw new Error("Prisoner list is not available");
      }

      const data = prisonerList.data;
      const formattedOptions = data.map((prisoner) => ({
        value: prisoner.id,
        label: `${prisoner.firstName} ${prisoner.sureName} ${prisoner.middleName}`,
      }));

      return {
        options: formattedOptions,
        hasMore: false, // yoki boshqa qo'shimcha parametrlar
      };
    } catch (error) {
      console.error("Error fetching options:", error);
      return { options: [] };
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      const optionsArray = Array.isArray(selectedOption)
        ? selectedOption
        : [selectedOption];
      const ids = optionsArray.map((option) => option.value);
      setSelectedIds((prevSelectedIds) => [...prevSelectedIds, ...ids]);
    }
  };

  return (
    <div>
      <Containers.Form
        customData={customData}
        validate={(event) => {
          console.log(event);
          //   console.log(event);
        }}
        method={"post"}
        url={"/prisoner/leave-room"}
        onSuccess={({ data }) => {
          method();
        }}
        onError={(err) => {
          console.log(err);
          method();
        }}
        fields={[
          {
            name: "time",
            onSubmitValue: (value) => dayjs(dayjs()).format("YYYY-MM-DD HH:mm"),
          },
          {
            name: "responsibleOfficer",
            value: get(values, "responsibleOfficer") ?? "",
            onSubmitValue: (value) => {
              return userData?.id;
            },
          },
          {
            name: "responsibleOfficers",
            validations: [{ type: "required" }],
            validationType: "array",
            value: get(values, "responsibleOfficers") ?? selectedIds,
            onSubmitValue: (value) => selectedIds?.slice(1),
          },
        ]}>
        {({ isSubmitting, values, setFieldValue, ...rest }) => {
          return (
            <>
              <div className="row g-4">
                <div className="col-12">
                  <Info
                    className="w_full"
                    label={t("fullname")}
                    data={userData?.firstName + " " + userData?.sureName}
                  />
                </div>
                <div className="col-12">
                  <FieldArray
                    name="responsibleOfficers"
                    render={(arrayHelpers) => (
                      <div style={{ width: "100%" }}>
                        {values?.responsibleOfficers?.map((ro, index) => (
                          <div
                            className="responsibleOfficers-select"
                            style={{ width: "100%", gap: "10px" }}
                            key={index}>
                            <AsyncPaginate
                              loadOptions={loadOptions}
                              styles={{ width: "100%" }}
                              getOptionValue={(option) => {}}
                              getOptionLabel={(option) => option.label}
                              isSearchable={false}
                              additional={{
                                page: 1,
                              }}
                              onChange={handleSelectChange}
                            />
                            <button
                              type="button"
                              style={{
                                fontSize: "18px",
                                background: "var(--stroke-input)",
                                padding: "3px 10px",
                                color: "#fff",
                                margin: "5px 0",
                              }}
                              onClick={() => {
                                arrayHelpers.remove(index);
                                const updatedUserIds = [...selectedUserIds];
                                updatedUserIds.splice(index, 1);
                                setSelectedUserIds(updatedUserIds);
                              }}>
                              -
                            </button>
                          </div>
                        ))}
                        <button
                          type="button"
                          style={{
                            fontSize: "18px",
                            background: "var(--stroke-input)",
                            padding: "3px 8px",
                            color: "#fff",
                          }}
                          onClick={() => {
                            if (
                              values.responsibleOfficers.every(
                                (ro) => ro && ro.label
                              )
                            ) {
                              arrayHelpers.push({});
                              setSelectedUserIds([...selectedUserIds, ""]);
                            } else {
                              arrayHelpers.push({});
                            }
                          }}>
                          +
                        </button>
                      </div>
                    )}
                  />
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-xl-12 col-l12-12">
                  <Info
                    className="w_full"
                    label={t("time")}
                    data={dayjs().format("YYYY-MM-MM HH:mm")}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  design="primary"
                  type="submit"
                  className="modal-btn-end fz_16 btn mt_40 w_full"
                  text={title.name}
                  isLoading={isSubmitting}
                />
              </div>
            </>
          );
        }}
      </Containers.Form>
    </div>
  );
}

export default FormSayirga;
