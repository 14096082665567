import React from "react";
import "./styles/userInfo.scss";
import { ReactComponent as UserInfoIcon } from "../../../assets/icons/userInfo.svg";
import { ReactComponent as BirthdayIcon } from "../../../assets/icons/birthdayIcon.svg";
import { ReactComponent as Gendor } from "../../../assets/icons/gendor.svg";
import { ReactComponent as Nation } from "../../../assets/icons/nation.svg";
import { ReactComponent as Passport } from "../../../assets/icons/passport.svg";
import { useTranslation } from "react-i18next";

const iconList = {
  user: <UserInfoIcon />,
  birthday: <BirthdayIcon />,
  nation: <Nation />,
  passpor: <Passport />,
  gendor: <Gendor />,
};
const UserInfo = (props) => {
  const { t } = useTranslation();
  const { list, title, item } = props;

  return (
    <div className="user">
      <div style={{ color: "var(--text-color)" }} className="user_title p_d">
        {title}
      </div>
      {item?.length &&
        item?.map((el, index) => (
          <div
            style={{ color: "var(--text-color)" }}
            className="user_item p_d"
            key={index}>
            <div className="item">
              <div className="user_item_text">
                <div
                  style={{ color: "var(--text-color)" }}
                  className="user_item_text_title">
                  {el.name}
                </div>
                <div
                  style={{ color: "var(--text-color)" }}
                  className="user_item_text_value">
                  {el.userName}
                </div>
              </div>
            </div>
          </div>
        ))}
      {list?.length &&
        list?.map((el, index) => (
          <div className="user_item p_d" key={el.key}>
            <div className="item">
              {iconList[el.icon]}
              <div className="user_item_text">
                <div
                  style={{ color: "var(--text-color)" }}
                  className="user_item_text_title">
                  {el.key}
                </div>
                <div
                  style={{ color: "var(--text-color)" }}
                  className="user_item_text_value">
                  {el.userName}
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default UserInfo;
