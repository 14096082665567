import React, { createContext, useContext, useState } from "react";

const VsxIdContext = createContext();

const VSXIdProvider = ({ children }) => {
  const [vsxId, setVsxId] = useState("");
  const toggleVsxId = (id) => {
    setVsxId(id);
  };

  return (
    <VsxIdContext.Provider value={{ vsxId, toggleVsxId }}>
      {children}
    </VsxIdContext.Provider>
  );
};

const useVsxId = () => {
  const context = useContext(VsxIdContext);
  if (!context) {
    throw new Error("useDarkMode must be used within a DarkModeProvider");
  }
  return context;
};

export { VSXIdProvider, useVsxId };
