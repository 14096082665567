import React from "react";
import UserInfo from "../../VSX/components/userInfo";
import { useTranslation } from "react-i18next";

export const DocumentsType = (props) => {
  const { t } = useTranslation();
  const { item } = props;
  return (
    <div className="col-3 col-md-6 col-sm-12 col-xl-4">
      <UserInfo item={item} title={t("catch-main")} />
    </div>
  );
};
