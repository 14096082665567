import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { Button } from "components";

import "./Tab.scss";

export const TabBase = ({
  onPaneChange,
  badge,
  className,
  labels = [],
  currentLabel,
  isObjeckt,
}) => {
  // console.log(Boolean(badge))
  return (
    <div className={cn("tab", className)}>
      {!isObjeckt &&
        labels?.map((tabLabel, index) => (
          <Button
            key={index}
            className={cn("tab__label", "mr_40", {
              tab__label_active: tabLabel === currentLabel,
            })}
            text={tabLabel}
            onClick={(event) => onPaneChange(tabLabel, event)}>
            {/*<span className='badge'>4</span>*/}
          </Button>
        ))}
      {isObjeckt &&
        labels?.map((tabLabel, index) => (
          <Button
            key={index}
            className={cn("tab__label", "mr_40", {
              tab__label_active: tabLabel.name === currentLabel,
            })}
            text={tabLabel.name}
            onClick={(event) => onPaneChange(tabLabel, event)}>
            {Boolean(badge) && (
              <span
                style={{
                  position: "absolute",
                  backgroundColor:
                    tabLabel?.badge > 0
                      ? "var(--primary-red)"
                      : "var(--brand-blue)",
                  color: "var(--white)",
                  height: "17px",
                  width: "17px",
                  borderRadius: "50%",
                  top: "-10px",
                  right: "-2px",
                  alignItems: "center",
                  fontSize: "14px",
                }}>
                {" "}
                {tabLabel?.badge}
              </span>
            )}
          </Button>
        ))}
    </div>
  );
};

TabBase.propTypes = {
  className: PropTypes.string,
  onPaneChange: PropTypes.func,
  labels: PropTypes.array,
  currentLabel: PropTypes.string,
};
