import { Pagination, Table } from "components";
import dayjs from "dayjs";
import { useFetchList } from "hooks";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, createSearchParams, useSearchParams } from "react-router-dom";

function EmployeeHistoryTable({ historyId, employeeId }) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const paginationData = searchParams.get("page");
  const [page, setPage] = useState({
    page: paginationData ?? paginationData + 1,
    pageSize: 10,
  });

  const employeeMenuList = useFetchList({
    url: "/officer-action-histories",
    urlSearchParams: {
      populate:
        "officer,date,prisoner.person,region,vsx,room, createdRoom,prisonerRoomLeave,prisonerRoomLeave.reason,fromRoom, toRoom, vsxCheck, performedOfficer",
      filters: {
        type: historyId,
        officer: employeeId,
        prisonerRoomLeave: {
          reason: {
            id: {
              $ne: 10,
            },
          },
        },
      },
      pagination: {
        ...page,
      },
    },
  });
  return (
    <div className="mt_20">
      <Table
        isLoading={employeeMenuList.isLoading}
        columns={[
          {
            title: t("number"),
            role: new Set([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13]),
            dataKey: "id",
            render: (value, item, index) => {
              return index + 1;
            },
          },
          {
            title: t("Xodim"),
            dataKey: "officer",
            role: new Set([1, 2, 3, 4, 5, 6, 7, 8, 9]),
            className: "white-space_no-wrap",
            render: (value, items) => {
              const _ = items?.officer
                ? items?.officer?.firstName +
                  " " +
                  items?.officer?.sureName +
                  " " +
                  items?.officer?.middleName
                : "-";
              return (
                <Link
                  style={{ borderBottom: "2px solid dodgerblue" }}
                  to={`/employees/detail/${items?.officer?.id}`}>
                  {_}
                </Link>
              );
            },
          },
          {
            title: t("reason-stutus"),
            dataKey: "prisonerRoomLeave",
            role: new Set([1]),
            className: "white-space_no-wrap",
            render: (value, items) => {
              const _ = items?.prisonerRoomLeave
                ? items?.prisonerRoomLeave.reason?.name
                : "-";
              return _;
            },
          },
          {
            title: t("where-time"),
            dataKey: "date",
            role: new Set([1]),
            className: "white-space_no-wrap",
            render: (value, items) => {
              if (items.prisonerRoomLeave)
                return dayjs(items.prisonerRoomLeave.time).format(
                  "YYYY-MM-DD HH:mm"
                );
              return "--";
            },
          },
          {
            title: t("Qaytarilgan vaqt"),
            dataKey: "date",
            role: new Set([1]),
            className: "white-space_no-wrap",
            render: (value, items) => {
              if (items.prisonerRoomLeave && items.prisonerRoomLeave.endTime)
                return dayjs(items.prisonerRoomLeave.endTime).format(
                  "YYYY-MM-DD HH:mm"
                );
              return "--";
            },
          },
          {
            title: t("time"),
            dataKey: "date",
            role: new Set([2, 3, 4, 5, 6, 7, 8, 9]),
            className: "white-space_no-wrap",
            render: (value, items) => {
              if (items.date)
                return dayjs(items.date).format("YYYY-MM-DD HH:mm");
              return "--";
            },
          },
          {
            title: t("Maxbus"),
            dataKey: "prisoner",
            role: new Set([1, 3, 4, 5]),
            className: "white-space_no-wrap",
            render: (value, items) => {
              const _ = items?.prisoner
                ? items?.prisoner.person?.firstName +
                  " " +
                  items?.prisoner.person?.sureName +
                  " " +
                  items?.prisoner.person?.middleName
                : "-";
              if (items?.prisoner.person) {
                return (
                  <Link
                    style={{ borderBottom: "2px solid dodgerblue" }}
                    to={`/prisoner/detail/${items?.prisoner.person.id}`}>
                    {_}
                  </Link>
                );
              } else return _;
            },
          },
          {
            title: t("region-name"),
            dataKey: "region",
            role: new Set([1, 2, 3, 4, 5, 6, 7, 8, 9]),
            className: "white-space_no-wrap",
            render: (value, items) => {
              const _ = items?.region ? items?.region.name : "-";
              return _;
            },
          },
          {
            title: t("VSX"),
            dataKey: "vsx",
            role: new Set([1, 2, 3, 4, 5, 6, 7, 8, 9]),
            className: "white-space_no-wrap",
            render: (value, items) => {
              const _ = items?.vsx ? items?.vsx.name : "-";
              return _;
            },
          },
          {
            title: t("Xona"),
            dataKey: "room",
            role: new Set([1, 2]),
            className: "white-space_no-wrap",
            render: (value, items) => {
              const _ = items?.room ? items?.room.name : "-";
              return _;
            },
          },
          {
            title: t("Yaratilgan xona"),
            dataKey: "createdRoom",
            role: new Set([2]),
            className: "white-space_no-wrap",
            render: (value, items) => {
              const _ = items?.createdRoom ? items?.createdRoom.name : "-";
              return _;
            },
          },
          {
            title: t("Xonadan"),
            dataKey: "fromRoom",
            role: new Set([5]),
            className: "white-space_no-wrap",
            render: (value, items) => {
              const _ = items?.fromRoom ? items?.fromRoom.name : "-";
              return _;
            },
          },
          {
            title: t("Xonaga"),
            dataKey: "toRoom",
            role: new Set([5]),
            className: "white-space_no-wrap",
            render: (value, items) => {
              const _ = items?.toRoom ? items?.toRoom.name : "-";
              return _;
            },
          },
          {
            title: t("Vsx tekshiruvi"),
            dataKey: "vsxCheck",
            role: new Set([6]),
            className: "white-space_no-wrap",
            render: (value, items) => {
              const _ = items?.vsxCheck ? items?.vsxCheck.result : "-";
              return _;
            },
          },
          {
            title: t("Amaliyot bajarilgan shaxs"),
            dataKey: "performedOfficer",
            role: new Set([7, 8, 9]),
            className: "white-space_no-wrap",
            render: (value, items) => {
              const _ = items?.performedOfficer
                ? items?.performedOfficer.firstName +
                  " " +
                  items?.performedOfficer.sureName +
                  " " +
                  items?.performedOfficer.middleName
                : "-";
              if (items?.performedOfficer) {
                return (
                  <Link
                    style={{ borderBottom: "2px solid dodgerblue" }}
                    to={`/employees/detail/${items?.performedOfficer?.id}`}>
                    {_}
                  </Link>
                );
              } else return _;
            },
          },
          {
            title: t("Holat"),
            dataKey: "performedOfficer",
            role: new Set([8]),
            className: "white-space_no-wrap",
            render: (value, items) => {
              const _ =
                items?.isOfficerBlocked === true
                  ? "Bloklandi"
                  : "Blokdan ochildi";

              return (
                <span
                  style={{
                    background:
                      items?.isOfficerBlocked === true ? "red" : "green",
                    color: "white",
                    padding: "5px 8px",
                    borderRadius: "8px",
                    fontSize: "12px",
                    cursor: "pointer",
                  }}
                  className="text-danger">
                  {_}
                </span>
              );
            },
          },
        ].filter((el) => el.role.has(Number(historyId)))}
        items={employeeMenuList?.data?.length ? employeeMenuList.data : []}
      />
      <Pagination
        currentPage={employeeMenuList?.meta?.pagination?.page}
        pageCount={employeeMenuList?.meta?.pagination?.pageCount}
        onPageChange={(newPage) => {
          setPage((old) => ({ ...old, page: newPage + 1 }));
          setSearchParams(createSearchParams({ page: newPage + 1 }));
        }}
      />
    </div>
  );
}

export default EmployeeHistoryTable;
