import { lazy } from "react";
import Sanitation from "./components/Sanitation";

const Sanitaion = lazy(() => import("../Sanitaion/page/Sanitaion"));

export const SanitainRoute = [
  {
    id: 87,
    path: "/sanitation",
    // element: <Outgo />,
    element: (
      <>
        <Sanitaion />
      </>
    ),
    roles: new Set(["Superadmin", "VsxManager"]),
  },
  {
    id: 68,
    path: "/archive/santable/:id",
    element: (
      <>
        <Sanitation />
      </>
    ),
    roles: new Set(["Superadmin"]),
  },
];
