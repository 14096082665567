import React, { useState } from "react";
import { EForms } from "../../Employees/components";
import { useFetchList, useNotification } from "../../../hooks";
import { useParams } from "react-router-dom";

export const Profile = (props) => {
  const { id } = useParams();
  const [page, setPage] = useState({
    page: 1,
    pageSize: 10,
  });
  const useNtion = useNotification();
  const userItem = useFetchList({
    url: `/users/${id}`,
    urlSearchParams: {
      populate: "*",
    },
  });
  return (
    <div>
      <EForms
        isData={false}
        isRoute={false}
        onSuccessValue={(data) => {
          userItem.refetch();
        }}
        values={{ ...userItem?.data }}
      />
    </div>
  );
};
