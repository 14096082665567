import React, { useState } from "react";
import { ErrorMessage, FastField, Field, FieldArray } from "formik";
import { useSelector } from "react-redux";
import { get, isFunction } from "lodash";
import { userSelector } from "store/selectors";
import { useFetchList, useGetLanguage, useOverlay } from "hooks";
import { constants, time, utils } from "services";
import { useNavigate } from "react-router-dom";
import Containers from "containers";
import { Fields, Button, AvatarUpload, AttachFile } from "components";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconDelete } from "../../../assets/icons/delete.svg";
import dayjs from "dayjs";
import {
  NarcoDispanserModal,
  PhscoDispanserModal,
} from "modules/Prisoners/components";
import { toast } from "react-toastify";
const FieldArrays = ({ products, t }) => {
  return (
    <FieldArray name={"items"}>
      {({ insert, remove, push }) => (
        <>
          {products?.map((product, index, arr) => (
            <div className="row" key={index}>
              <div
                className="col-2 col-md-2 col-sm-12 mt_20 d-flex align-items-center "
                key={index}>
                <div className="d-flex">
                  <div>
                    <Field
                      style={{ borderRight: "none" }}
                      name={`items.${index}.key`}
                      component={Fields.InputText}
                    />
                  </div>

                  <div style={{ width: 68 + "px" }}>
                    <Field
                      name={`items.${index}.value`}
                      component={Fields.InputText}
                      placeholder={"kg"}
                    />
                  </div>
                </div>
                <ErrorMessage
                  name={`items.${index}.name`}
                  component="span"
                  className="field-error"
                />
                <div className="ml_10">
                  {index + 1 === arr.length && index !== 0 && (
                    <Button
                      className="color_primary-red product__btn"
                      onClick={() => remove(index)}>
                      <IconDelete />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          ))}
          {/* <Button
            className="w_30 mt_20 "
            style={{ padding: 0, borderRadius: 5 + "px" }}
            design={"primary"}
            onClick={() => push({ key: "", value: "" })}>
            +
          </Button> */}
        </>
      )}
    </FieldArray>
  );
};
export const VForms = (props) => {
  const { values, onSuccessValue } =
    props;
  // console.log(values, '==>>')
  const { getLanguageValue } = useGetLanguage();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const filter = {
    isLeftVsx: false,
  };
  const filterU = {};
  if (userData.region) {
    filter["room"] = {
      vsx: {
        region: userData.region.id,
      },
    };
    filterU["region"] = userData.region.id;
  }
  if (userData.vsx) {
    filter["room"] = {
      vsx: userData.vsx.id,
    };
    filterU["vsx"] = userData.vsx.id;
  }
  const narcoModal = useOverlay({ uniqueName: "narcoModal" });
  const phocyModal = useOverlay({ uniqueName: "phocyModal" });
  const user = useSelector(userSelector);
  const navLink = useNavigate();
  const { t } = useTranslation();
  const hadleNarco = (data, has) => {
    if (has) {
      // narcoModal.handleOverlayOpen()
      toast.info(t("person-not-narco"), { autoClose: 2000 });
    } else if (data) {
      narcoModal.handleOverlayOpen();
      // console.log(data)
    } else {
      toast.info(t("search-not"), { autoClose: 1500 });
    }
  };

  const hadlePhcy = (data, has) => {
    if (has) {
      toast.info(t("person-not-phcy"), { autoClose: 2000 });
    } else if (data) {
      phocyModal.handleOverlayOpen();
    } else {
      toast.info(t("search-not"), { autoClose: 1500 });
    }
  };

  return (
    <>
      <div>
        <NarcoDispanserModal
          values={get(values, "narco")}
          isOpen={narcoModal.isOverlayOpen}
          handleOverlayClose={narcoModal.handleOverlayClose}
          // handleOverlayOpen={narcoModal.handleOverlayOpen}
          // onSuccess={narcoModal.refetch}
        />
        <PhscoDispanserModal
          valuesUpdate={get(values, "psyco")}
          isOpen={phocyModal.isOverlayOpen}
          handleOverlayOpen={phocyModal.handleOverlayOpen}
          handleOverlayClose={phocyModal.handleOverlayClose}
        />
      </div>
      <Containers.Form
        method={get(values, "id") ? "put" : "post"}
        url={
          get(values, "id")
            ? `/visitor/create-visitor-and-visit${get(values, "id")}`
            : "/visitor/create-visitor-and-visit"
        }
        onSuccess={(data) => {
          isFunction(onSuccessValue) && onSuccessValue(data);
          navLink(-1);
        }}
        fields={[
          {
            name: "image",
            value: get(values, "image") ? get(values, "image") : "",
            onSubmitValue: (value) => {
              if (!value) return null;
              return value;
            },
          },

          {
            name: "passport",
            validations: [{ type: "required" }],
            value: get(values, "passport"),
            onSubmitValue: (value) => {
              return value;
            },
          },
          {
            name: "pinfl",
            // validations: [{ type: "required" }],
            value: get(values, "pinfl"),
            onSubmitValue: (value) => {
              if (!value) return null;
              return value;
            },
          },
          {
            name: "items",
            validationType: "array",
            value: [
              {
                key: "",
                value: "",
              },
            ],
            lazy: (validator, yup) =>
              validator.of(
                yup.object().shape({
                  key: yup.string(),
                  value: yup.string(),
                })
              ),
            onSubmitValue: (value) => value.map((item) => item),
          },
          {
            name: "birthDate",
            validations: [{ type: "required" }],
            value: get(values, "birthDate"),
            onSubmitValue: (value) => {
              return time.timeFormater(value, "YYYY-MM-DD");
            },
          },
          {
            name: "date",
            value: get(values, "date"),
            onSubmitValue: () => {
              return dayjs();
            },
          },
          {
            name: "firstName",
            validations: [{ type: "required" }],
            value: get(values, "firstName"),
            onSubmitValue: (value) => value,
          },
          {
            name: "conviction",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "conviction.id")
              ? {
                  label: get(values, "conviction.name"),
                  value: get(values, "conviction.id"),
                }
              : "",
            onSubmitValue: (value) => {
              return value.value;
            },
          },
          {
            name: "sureName",
            validations: [{ type: "required" }],
            value: get(values, "sureName"),
            onSubmitValue: (value) => value,
          },
          {
            name: "middleName",
            validations: [{ type: "required" }],
            value: get(values, "middleName"),
            onSubmitValue: (value) => value,
          },
          {
            name: "birthAddress",
            validations: [{ type: "required" }],
            value: get(values, "birthAddress"),
            onSubmitValue: (value) => value,
          },
          {
            name: "address",
            validations: [{ type: "required" }],
            value: get(values, "address"),
            onSubmitValue: (value) => value,
          },
          {
            name: "isRejected",
            // validations: [{type: "required"}],
            value: `${get(values, "isRejected")}` ?? false,
            onSubmitValue: (value) => {
              // console.log(value)
              const _item = value === "Ha" ? true : false;
              return _item;
            },
          },
          {
            name: "rejectionType",
            validationType: "object",
            value: get(values, "rejectionType.id")
              ? {
                  label: get(values, "rejectionType.name"),
                  value: get(values, "rejectionType.id"),
                }
              : "",
            onSubmitValue: (value) => {
              return value.value;
            },
          },
          {
            name: "phone",
            validations: [{ type: "required" }],
            value: get(values, "phone"),
            onSubmitValue: (value) => value,
          },
          {
            name: "nationality",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "nationality")
              ? {
                  label: get(values, "nationality.name"),
                  value: get(values, "nationality.id"),
                }
              : "",
            onSubmitValue: (value) => value.value,
          },
          {
            name: "responsibleOfficer",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "id")
              ? {
                  label: get(values, "responsibleOfficer.username"),
                  value: get(values, "responsibleOfficer.id"),
                }
              : "",
            onSubmitValue: (value) => value.value,
          },
          {
            name: "prisoner",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "id")
              ? {
                  label: get(values, "prisoner.name"),
                  value: get(values, "prisoner.id"),
                }
              : "",
            onSubmitValue: (value) => value.value,
          },
          {
            name: "document",
            // validations: [{type: "required"}],
            value: get(values, "searchDocument"),
            onSubmitValue: (value) => {
              if (!value) return null;
              return value;
            },
          },
          {
            name: "citizenship",
            validations: [{ type: "required" }],
            validationType: "object",
            value: get(values, "citizenship")
              ? {
                  label: get(values, "citizenship.name"),
                  value: get(values, "citizenship.id"),
                }
              : "",
            onSubmitValue: (value) => value.value,
          },
          {
            name: "phsycoDispanser",
            // validations: [{type: "required"}],
            validationType: "object",
            value: !values?.psyco
              ? get(values, "psyco")
              : values?.psyco === "-"
              ? {
                  label: "Mavjud emas",
                  value: 2,
                }
              : { label: "Mavjud", value: 1 },
            onSubmitValue: (value) => {
              if (value) {
                if (value.value) return value.value;
              } else {
                return null;
              }
            },
          },
          {
            name: "narcoDispanser",
            // validations: [{type: "required"}],
            validationType: "object",
            value: !values?.narco
              ? get(values, "narco")
              : values?.narco === "-"
              ? {
                  label: "Mavjud emas",
                  value: 2,
                }
              : { label: "Mavjud", value: 1 },

            onSubmitValue: (value) => {
              if (value) {
                if (value.value) return value.value;
              } else {
                return null;
              }
            },
          },
        ]}>
        {({ isSubmitting, values, ...rest }) => {
          return (
            <>
              <div
                className="visitors-form-istablet"
                style={{ display: "flex" }}>
                <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4">
                  <FastField
                    name="image"
                    component={AvatarUpload}
                    className="avatar-upload"
                    disabled={get(values, "disabled")}
                    getImage={(image) => {
                      return BaseUrl + get(image, "0.url");
                    }}
                  />
                </div>
                <div>
                  <div className="row mb_25">
                    <div className="col-6 col-lg-6 col-sm-12 col-md-12">
                      <div className="">
                        <FastField
                          name="responsibleOfficer"
                          component={Fields.AsyncSelect}
                          loadOptionsUrl={"/users"}
                          filters={{
                            ...filterU,
                          }}
                          loadOptionsKey={(data) =>
                            data?.map((el) => ({
                              label: `${el.firstName} ${el.sureName}`,
                              value: el.id,
                            }))
                          }
                          label={t("responsible-person")}
                          placeholder={t("select")}
                        />
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 col-sm-12 col-md-12">
                      <div className="">
                        <FastField
                          name="prisoner"
                          component={Fields.AsyncSelect}
                          loadOptionsUrl={"/prisoners"}
                          isPagination
                          loadOptionsParams={(searchText) => {
                            return {
                              filters: searchText
                                ? {
                                    person: { sureName: searchText },
                                    ...filter,
                                  }
                                : { ...filter },
                              populate: "*",
                            };
                          }}
                          loadOptionsKey={(data) =>
                            data?.data?.map((el) => ({
                              label:
                                el?.person?.sureName +
                                " " +
                                el?.person?.firstName +
                                " " +
                                ` (${el.acceptedVisits ?? 0} ta yo'qlov / ${
                                  el.acceptedVisitWeight ?? 0
                                } kg)`,
                              value: el.id,
                            }))
                          }
                          label={t("responsible-prisoner")}
                          placeholder={t("select")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row g-4">
                    <div className="col-4 col-lg-4 col-sm-12 col-md-12">
                      <FastField
                        name="sureName"
                        isDisabled={get(values, "disabled")}
                        component={Fields.InputText}
                        label={t("sure-name")}
                        placeholder={t("sure-name")}
                        // placeholder="Фамилия"
                      />
                    </div>
                    {/*firstname*/}
                    <div className="col-4 col-lg-4 col-sm-12 col-md-12">
                      <FastField
                        name="firstName"
                        isDisabled={get(values, "disabled")}
                        component={Fields.InputText}
                        label={t("first-name")}
                        placeholder={t("first-name")}
                        // placeholder="Исми"
                      />
                    </div>
                    {/*middlename*/}
                    <div className="col-4 col-lg-4 col-sm-12 col-md-12">
                      <FastField
                        name="middleName"
                        isDisabled={get(values, "disabled")}
                        component={Fields.InputText}
                        label={t("middle-name")}
                        placeholder={t("middle-name")}
                        // placeholder="Отасининг исми"
                      />
                    </div>
                    {/*birthdate*/}
                    <div className="col-4 col-lg-4 col-sm-12 col-md-12">
                      <FastField
                        name="birthDate"
                        isDisabled={get(values, "disabled")}
                        component={Fields.DatePicker}
                        label={t("birthdate")}
                        format="YYYY-MM-DD"
                        prepend=""
                        placeholder={t("birthdate")}
                      />
                    </div>
                    {/*passport*/}
                    <div className="col-4 col-lg-4 col-sm-12 col-md-12">
                      <FastField
                        name="passport"
                        isDisabled={get(values, "disabled")}
                        component={Fields.InputText}
                        label={t("passport")}
                        placeholder={t("passport")}
                      />
                    </div>{" "}
                    <div className="col-4 col-lg-4 col-sm-12 col-md-12">
                      <FastField
                        name="pinfl"
                        component={Fields.InputText}
                        label={t("pinfl-name")}
                        placeholder={t("pinfl-name")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "0px" }} className="row g-4">
                <div className="col-12">
                  <div className="row g-4">
                    <div className="col-4 col-lg-4 col-sm-12 col-md-12">
                      <FastField
                        name="birthAddress"
                        component={Fields.InputText}
                        label={t("birthaddress")}
                        placeholder={t("birthaddress")}
                        // placeholder="Тугилган жойи"
                      />
                    </div>
                    {/*address*/}
                    <div className="col-4 col-lg-4 col-sm-12 col-md-12">
                      <FastField
                        name="address"
                        component={Fields.InputText}
                        label={t("address")}
                        placeholder={t("address")}
                      />
                    </div>
                    {/*nationality*/}
                    <div className="col-4 col-lg-4 col-sm-12 col-md-12">
                      <FastField
                        name="nationality"
                        component={Fields.AsyncSelect}
                        loadOptionsUrl={"/nationalities"}
                        isDisabled={get(values, "disabled")}
                        loadOptionsKey={(data) =>
                          data?.data?.map((el) => ({
                            label: el.name,
                            value: el.id,
                          }))
                        }
                        label={t("nationality")}
                        placeholder={t("select")}
                      />
                    </div>
                    <div className="col-4 col-lg-4 col-sm-12 col-md-12">
                      <FastField
                        name="citizenship"
                        component={Fields.AsyncSelect}
                        loadOptionsUrl={"/citizenships"}
                        isDisabled={get(values, "disabled")}
                        loadOptionsKey={(data) =>
                          data?.data?.map((el) => ({
                            label: el.name,
                            value: el.id,
                          }))
                        }
                        label={t("citizenship") + "*"}
                        placeholder={t("select")}
                      />
                    </div>
                    <div className="col-4 col-lg-4 col-sm-12 col-md-12">
                      <FastField
                        name="conviction"
                        component={Fields.AsyncSelect}
                        loadOptionsUrl={"/convictions"}
                        loadOptionsKey={(data) =>
                          data?.data?.map((el) => ({
                            label: el.name,
                            value: el.id,
                          }))
                        }
                        label={t("conviction")}
                        placeholder={t("select")}
                      />
                    </div>

                    <div className="col-4 col-lg-4 col-sm-12 col-md-12">
                      <FastField
                        name="phone"
                        component={Fields.InputMask}
                        label={t("phone")}
                        placeholder={t("phone")}
                        // placeholder="Доимий рўйхатга олинган манзил"
                      />
                    </div>
                    <div className="col-4 col-lg-4 col-sm-12 col-md-12">
                      <h2
                        style={{ color: "var(--text-color)" }}
                        className="search_label mb_10">
                        {t("aplication")}
                      </h2>
                      <div className="file-upload">
                        <FastField
                          name="searchDocument"
                          title={t("file")}
                          component={AttachFile}
                          label={t("aplication")}
                          placeholder={t("aplication")}
                        />
                      </div>
                    </div>
                    <br />
                    <div className="col-4 col-lg-4 col-sm-12 col-md-12 cursor_not">
                      <div className="row">
                        <div
                          className={
                            values?.disabled
                              ? "hideDiv col-8 col-lg-8 col-md-10 col-sm-10"
                              : "col-8 col-lg-8 col-md-10 col-sm-10"
                          }>
                          <div className={values?.disabled ? "cursor_not" : ""}>
                            <FastField
                              name="narcoDispanser"
                              component={Fields.AsyncSelect}
                              loadOptionsUrl={"/prisoner-narco-selecteds"}
                              loadOptionsKey={(data) =>
                                data?.data?.map((el) => ({
                                  label: el.name,
                                  value: el.id,
                                }))
                              }
                              label={t("narcoDispanser")}
                              isDisabled={get(values, "disabled")}
                              placeholder={t("select")}
                            />
                          </div>
                        </div>
                        <div className="col-2 mt_25">
                          <button
                            onClick={(event) => {
                              event.preventDefault();
                              hadleNarco(
                                get(values, "narco"),
                                get(values, "narco") === null
                              );
                            }}>
                            <svg
                              width="40px"
                              height="40px"
                              viewBox="0 0 48 48"
                              version="1"
                              enableBackground="new 0 0 48 48">
                              <circle fill="#2196F3" cx="24" cy="24" r="21" />
                              <rect
                                x="22"
                                y="22"
                                fill="var(--white)"
                                width="4"
                                height="11"
                              />
                              <circle
                                fill="var(--white)"
                                cx="24"
                                cy="16.5"
                                r="2.5"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 col-lg-4 col-sm-12 col-md-12">
                      <div className="row">
                        <div
                          className={
                            values?.disabled
                              ? "hideDiv col-8 col-lg-8 col-sm-10 col-md-10"
                              : "col-8 col-lg-8 col-md-10 col-sm-10"
                          }>
                          <div className={values?.disabled ? "cursor_not" : ""}>
                            <FastField
                              name="phsycoDispanser"
                              component={Fields.AsyncSelect}
                              isDisabled={get(values, "disabled")}
                              loadOptionsUrl={"/prisoner-psycho-selecteds"}
                              loadOptionsKey={(data) =>
                                data?.data?.map((el) => ({
                                  label: el.name,
                                  value: el.id,
                                }))
                              }
                              label={t("phsycoDispanser")}
                              placeholder={t("select")}
                            />
                          </div>
                        </div>
                        <div className="col-2 mt_25">
                          <button
                            onClick={(event) => {
                              event.preventDefault();
                              hadlePhcy(
                                get(values, "psyco"),
                                get(values, "psyco") === null
                              );
                            }}>
                            <svg
                              width="40px"
                              height="40px"
                              viewBox="0 0 48 48"
                              version="1"
                              enableBackground="new 0 0 48 48">
                              <circle fill="#2196F3" cx="24" cy="24" r="21" />
                              <rect
                                x="22"
                                y="22"
                                fill="var(--white)"
                                width="4"
                                height="11"
                              />
                              <circle
                                fill="var(--white)"
                                cx="24"
                                cy="16.5"
                                r="2.5"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex row align-items-center">
                      <div className="col-6 col-lg-6 col-sm-12 col-md-12">
                        <h3 style={{ color: "var(--text-color)" }}>
                          {t("get-out-thing")}
                        </h3>
                        <FieldArrays
                          t={t}
                          products={
                            rest?.values?.items?.length
                              ? rest?.values.items
                              : [{ key: "", value: "" }]
                          }
                        />
                      </div>
                      <div className="col-6 col-lg-6 col-sm-12 col-md-12">
                        <h3
                          style={{ color: "var(--text-color)" }}
                          className="visitor-name">
                          {t("Rad etish")}
                        </h3>
                        <div className="d-flex align-items-center mt_20">
                          <div className="col-6 col-lg-6 col-md-2 d-flex align-items-center ">
                            <span>
                              <FastField
                                name="isRejected"
                                component={Fields.RadioButton}
                                label={t("yes")}
                                value={t("yes")}
                              />
                            </span>
                            <span>
                              {" "}
                              <FastField
                                name="isRejected"
                                component={Fields.RadioButton}
                                label={t("no")}
                                value={t("no")}
                              />
                            </span>
                          </div>
                          {values.isRejected === t("yes") && (
                            <div className="col-10 col-lg-6 col-sm-12 col-md-10">
                              <FastField
                                name="rejectionType"
                                component={Fields.AsyncSelect}
                                loadOptionsUrl={"/visit-rejection-types"}
                                loadOptionsKey={(data) =>
                                  data?.data?.map((el) => ({
                                    label: el.name,
                                    value: el.id,
                                  }))
                                }
                                label={t("out-see-one")}
                                placeholder={t("select")}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Button
                design="primary"
                type="submit"
                className="modal-btn-end  fz_16 btn mt_40 visitors-button-bottom"
                text={t("save")}
                isLoading={isSubmitting}
              />
            </>
          );
        }}
      </Containers.Form>
    </>
  );
};
